import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../components/layout/layout';
import Header from '../components/policy-pages/header/header';
import RelatedLinksContainer from '../components/shared/related-links-container';

export default function TermsOfUse() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <div style={{ background: 'white' }}>
        <Header title="Terms of Use" />
        <RelatedLinksContainer title="Terms of Use">
          <p>Please read these terms of use carefully before using the website of Sterling Foundation Management ("Sterling"), <a href="//www.sterlingfoundations.com" target="_blank" rel="noreferrer">www.SterlingFoundations.com</a>.</p>

          <h4>Conditions of use</h4>

          <p>By using this website, you certify that you have read and reviewed this Agreement and that you agree to comply with its terms. If you do not want to be bound by the terms of this Agreement, you are advised to leave the website accordingly. Sterling only grants use and access of this website, its products, and its services to those who have accepted its terms.</p>

          <h4>Privacy policy</h4>

          <p>Before you continue using our website, we advise you to read our <Link to="/privacy">privacy policy</Link> regarding our user data collection. It will help you better understand our practices.</p>

          <h4>Intellectual property</h4>

          <p>You agree that all materials, products, and services provided on this website are the property of Sterling, its affiliates, directors, officers, employees, agents, suppliers, or licensors including all copyrights, trade secrets, trademarks, patents, and other intellectual property. You also agree that you will not reproduce or redistribute Sterling's intellectual property in any way, including electronic, digital, or new trademark registrations.</p>

          <h4>User accounts</h4>

          <p>As a user of this website, you may be asked to register with us and provide private information. You are responsible for ensuring the accuracy of this information, and you are responsible for maintaining the safety and security of your identifying information. You are also responsible for all activities that occur under your account or password.</p>

          <p>If you think there are any possible issues regarding the security of your account on the website, inform us immediately so we may address it accordingly.</p>

          <p>We reserve all rights to terminate accounts, edit or remove content.</p>

          <h4>Applicable law</h4>

          <p>By visiting this website, you agree that the laws of the State of Virginia, without regard to principles of conflict laws, will govern these terms and conditions, or any dispute of any sort that might come between Sterling and you, or its business partners and associates.</p>

          <p>Disputes</p>

          <p>Any dispute related in any way to your visit to this website or to products you purchase from us shall be arbitrated by state or federal court and you consent to exclusive jurisdiction and venue of such courts.</p>

          <p>Indemnification</p>

          <p>You agree to indemnify Sterling and its affiliates and hold Sterling harmless against legal claims and demands that may arise from your use or misuse of our services. We reserve the right to select our own legal counsel.</p>

          <h4>Limitation on liability</h4>

          <p>Sterling is not liable for any damages that may occur to you as a result of your misuse of our website.</p>

          <p>Sterling reserves the right to edit, modify, and change this Agreement any time. We shall let our users know of these changes through electronic mail. This Agreement is an understanding between Sterling and the user, and this supersedes and replaces all prior agreements regarding the use of this website</p>
        </RelatedLinksContainer>
      </div>
    </Layout>
  );
}
