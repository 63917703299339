import React from 'react';
import { Box } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';


export const Unauthorized = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" bgcolor="white" p={2} py={6} minHeight={500}>
      <Box style={{ width: '100%', maxWidth: 800 }}>
      <Alert severity="warning" style={{ width: '100%' }}>
        <AlertTitle>Unauthorized</AlertTitle>
        You do not have access to this page
      </Alert>
      </Box>
    </Box>
  );
};
