import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Navbar from './navbar';
import Footer from './footer';
import FullScreenLoader from '../shared/full-screen-loader';


const Layout = ({children}) => {
  const hasData = useSelector(state => !!state.assets.data && !!state.client.data && !!state.contributions.data && !!state.disbursements.data); //  && !!state.files.data
  const isLoading = useSelector(state => state.app.loading || state.client.loading || state.assets.loading || state.contributions.loading || state.disbursements.loading || state.files.loading);

  return (
    <Fragment>
      <Navbar />
      <main>
        <FullScreenLoader
          hideLoader={hasData && !isLoading}>
          {children}
        </FullScreenLoader>
      </main>
      <Footer />
    </Fragment>
  );
};

export default Layout;
