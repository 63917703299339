import React from 'react';
import PropTypes from 'prop-types';
import TitleDivider from './title-divider';
import { Grid } from '@material-ui/core';
import ForwardIcon from '@material-ui/icons/PlayArrow';
import { Link } from 'react-router-dom';
import { portalForms } from '../../utils/constants';
import TriplePointForm from './triple-point-form';

function StyledLink({ title, href, isForm = false }) {
  return (
    <div className="rlc-link-container">
      <div className="flex-ac">
        <ForwardIcon className="rlc-icon" />
        { isForm ? (
          <TriplePointForm title={title} url={href}><span className="faux-link rlc-link">{title}</span></TriplePointForm>
        ) : (
          <Link to={href} className="rlc-link">
            {title}
          </Link>
        )}
      </div>
    </div>
  );
}
StyledLink.propTypes = {
  title: PropTypes.string,
  href: PropTypes.string
};

function RelatedLinks() {
  const links = [
    {
      title: 'Privacy Policy',
      href: '/privacy'
    },
    {
      title: 'Terms of Use',
      href: '/terms-of-use'
    },
    {
      title: 'Disclosures',
      href: '/disclosures'
    },
    {
      title: portalForms.contactUs.title,
      href: portalForms.contactUs.url,
      isForm: true
    }
  ];
  return (
    <div className="default-container">
      <div className="rlc-title-divider">
        <TitleDivider title="Related Links" />
      </div>
      <div>
        {links.map((link, index) => (
          <StyledLink key={index} {...link} />
        ))}
      </div>
    </div>
  );
}

export default function RelatedLinksContainer({ title, children }) {
  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="stretch"
    >
      <Grid item md={9} xs={12}>
        <div className="rlc-text-container default-container">
          <div className="rlc-title-divider">
            <TitleDivider title={title} />
          </div>
          {children}
        </div>
      </Grid>
      <Grid item md={3} xs={12} className="rl-container">
        <RelatedLinks />
      </Grid>
    </Grid>
  );
}
RelatedLinksContainer.propTypes = {
  title: PropTypes.string,
  text: PropTypes.element,
  children: PropTypes.node,
};
