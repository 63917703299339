import React from 'react';
import { formatMoney, formatDate } from '../../utils/misc';
import { getArrow } from './get-arrow';

const RecentData = (props) => {
  const { amountChange, amountChangePercent, total, date, changeType } = props;

  return (
    <div>
      <div>
        <span>
          <span className="rg-text f-700 header-text-for-dark">
            {formatMoney(total, 0)}
          </span>{' '}
          <i style={{ fontSize: '16px', color: '#FFFFFFA5' }}>
            as of {date ? formatDate(date) : 'NOT_DEFINED'}
          </i>
        </span>
      </div>

      <div
        className="flex-ac"
        style={{
          marginTop: '15px',
          color: 'white'
        }}
      >
        {getArrow(amountChange)}{' '}
        <i>
          {formatMoney(amountChange < 0 ? amountChange * -1 : amountChange, 0)}{' '}
          {amountChangePercent ? (
            <span className="sm-text">
              ({amountChangePercent}%) {changeType}
            </span>
          ) : null}
        </i>
      </div>
    </div>
  );
};

export default RecentData;
