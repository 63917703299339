import React, { useCallback, useEffect, useState } from 'react';
import { Button, makeStyles, TextField, Grid } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import axios from 'axios';
import { getUrl, getConfig } from '../../redux/redux-helpers';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,

    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
}));

export const CreateUser = () => {
  const classes = useStyles();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [clientId, setClient] = useState('');
  const [email, setEmail] = useState('');

  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('User created');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const validateFields = useCallback(() => {
    const newMessages = [];

    if (clientId.trim().length === 0) {
      newMessages.push('Client ID is required.');
    } else {
      const clientIdValues = clientId.trim().split(',');
      let clientIdHasNaN = false;
      clientIdValues.forEach(char => {
        if (isNaN(char.trim()))  clientIdHasNaN = true;
      });
      if (clientIdHasNaN) newMessages.push('Client ID must be a number or list of numbers separated by commas.');
    };

    if (firstName.trim().length === 0) {
      newMessages.push('First Name is required.');
    };

    if (lastName.trim().length === 0) {
      newMessages.push('Last Name is required.');
    };

    if (email.trim().length === 0) {
      newMessages.push('Email is required.');
    } else if (!validateEmail(email.trim())) {
      newMessages.push('Email must be a valid email address.');
    };

    setErrorMessages(newMessages);
    if (wasSubmitted) setShowSuccess(false);

    return newMessages.length > 0;
  }, [clientId, email, firstName, lastName, wasSubmitted]);

  useEffect(() => {
    validateFields();
  }, [clientId, firstName, lastName, email, validateFields]);

  const handleFieldChange = (field, event) => {
    const value = event.target.value;

    switch (field) {
      case 'clientId':
        setClient(value);
        break;

      case 'email':
        setEmail(value);
        break;

      case 'firstName':
        setFirstName(value);
        break;

      case 'lastName':
        setLastName(value);
        break;

      default:
        break;
    }
  };

  let hasErrors = errorMessages.length > 0;
  const showErrors = hasErrors && wasSubmitted;

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // console.log('');
    // console.log('%c handleSubmit()', 'color:chartreuse');

    const clientIdArray = clientId.split(',').map(char => char.trim()).filter(char => char.length > 0).map(char => +char);

    const formData = {
      email,
      firstName,
      lastName,
      clientId: clientIdArray,
    };

    // console.log('%c formData', 'color:chartreuse', formData);

    setWasSubmitted(true);

    if (hasErrors) return;

    setIsSubmitting(true);

    const config = {
      ...getConfig(),
      headers: {
        ...getConfig().headers,
        'Content-Type': 'application/json',
      }
    };

    axios.post(getUrl('admin/users'), formData, config).then(response => {
      // console.log('');
      // console.log('%c POST()', 'color:lime');
      // console.log('%c response', 'color:lime', response);

      setWasSubmitted(false);
      setSuccessMessage(`User created for ${email}`);
      setShowSuccess(true);
      setFirstName('');
      setLastName('');
      setEmail('');
      setIsSubmitting(false);
    }).catch((error) => {
      console.log('');
      console.log('%c error', 'color:orangered', error);

      setErrorMessages(['An error occurred while submitting the User.']);
      setShowSuccess(false);
      setIsSubmitting(false);
    });
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Grid container spacing={3}>

        {showErrors && (
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={12}>
              <Alert severity="error" style={{ width: '100%' }}>
                <AlertTitle>Please correct the following issues:</AlertTitle>
                {errorMessages.map(message => (
                  <li key={message}>{message}</li>
                ))}
              </Alert>
            </Grid>
          </Grid>
        )}

        {showSuccess && (
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={12}>
              <Alert severity="success" style={{ width: '100%' }}>
                <AlertTitle>{successMessage}</AlertTitle>
              </Alert>
            </Grid>
          </Grid>
        )}

        <Grid container item xs={12}>
          <Grid item xs={12} sm={6}>
            <TextField id="clientId" label="Client ID*" variant="outlined" value={clientId} onChange={(e) => handleFieldChange('clientId', e)} />
          </Grid>
        </Grid>

        <Grid container item xs={12}>
          <Grid item xs={12} sm={6}>
            <TextField id="firstName" label="First Name*" variant="outlined" value={firstName} onChange={(e) => handleFieldChange('firstName', e)} />
          </Grid>
        </Grid>

        <Grid container item xs={12}>
          <Grid item xs={12} sm={6}>
            <TextField id="lastName" label="Last Name*" variant="outlined" value={lastName} onChange={(e) => handleFieldChange('lastName', e)} />
          </Grid>
        </Grid>

        <Grid container item xs={12}>
          <Grid item xs={12} sm={6}>
            <TextField id="email" label="Email*" variant="outlined" value={email} onChange={(e) => handleFieldChange('email', e)} />
          </Grid>
        </Grid>

        <Grid container item xs={12}>
          <Grid item xs>
            <Button type="submit" variant="contained" disabled={isSubmitting || showErrors} onClick={handleSubmit}>Submit</Button>
            {/* <Button variant="text" style={{ marginLeft: '1rem' }}>View List</Button> */}
          </Grid>
        </Grid>

      </Grid>
    </form>
  );
};

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};
