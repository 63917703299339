import React from 'react';
import CloseIcon from '@material-ui/icons/Close';


const styles = {
  container: {
    display: 'flex',
    backgroundColor: 'white',
    height: '100%'
  },
  innerContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '640px',
    minWidth: '400px',
    padding: '4em',
    paddingTop: '2em',
    width: '100%'
  },
  closeContainer: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'rgba(255, 125, 135, .5)',
    color: '#8b9095',
    cursor: 'pointer',
    fontWeight: 100,
    fontFamily: 'system-ui',
    fontSize: '3em',
    height: '2em',
    width: '2em',
  },
  closeIcon: {
    height: '74px',
    width: '74px',
    color: 'inherit'
  }
};

const FormFauxLayout = ({children, onClose}) => {
  // console.log('');
  // console.log('%c FormFauxLayout()', 'color:yellow');

  return (
    <div className="form-faux" style={styles.container}>
      <div style={styles.innerContainer}>
        <div style={styles.closeContainer}>
          <CloseIcon
            onClick={onClose}
            style={styles.closeIcon}
          />
        </div>
        {children}
      </div>
    </div>
  );
};

export default FormFauxLayout;
