import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles({
  root: {
    flexGrow: 0,
    flexShrink: 0,
    height: '60px',
    lineHeight: '1.3rem',
    width: '100%',
  },
});

function BlockButton(props) {
  // console.log('');
  // console.log('%c BlockButton()', 'color:magenta');
  // console.log('%c props', 'color:magenta', props);
  // console.log('%c useStyles', 'color:magenta', useStyles);

  const { color = 'secondary', variant = 'contained', ...other } = props;
  const classes = useStyles(props);
  // console.log('%c classes', 'color:magenta', classes);

  return <Button className={classes.root} color={color} variant={variant} {...other} />;
}

export default BlockButton;
