import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { parseStateChartData } from '../../../redux/services/contribution-service';
import ChartInfo from '../../shared/big-chart-info';
import MapChart from '../../shared/us-map';
import StateSideChart from '../../shared/state-side-chart';
import { elementStyles } from '../../../styles/mainStyle';


const StateGridRow = () => {
  // console.log('');
  // console.log('%c contributions/StateGridRow()', 'color:violet');

  const contributions = useSelector(state => state.contributions.data.contributions);
  const [filterValue, setFilterValue] = useState('months:6');
  const chartData = parseStateChartData(contributions, filterValue);

  if (!chartData) return null;

  // console.log('%c contributions', 'color:violet', JSON.parse(JSON.stringify(contributions)));
  // console.log('%c filterValue', 'color:violet', filterValue);
  // console.log('%c chartData', 'color:violet', JSON.parse(JSON.stringify(chartData)));
  // console.log('%c chartData.buckets', 'color:violet', JSON.parse(JSON.stringify(chartData.buckets)));

  return (
    <Grid container direction="row" justify="space-between" alignItems="stretch">
      <Grid item md={9} xs={12} style={elementStyles.column}>
        <ChartInfo
          title="Contributions by Location"
          subtitle={`(${chartData.filter.title})`}
          total={chartData.summary.count}
          changeValue={chartData.summary.changeCount}
          changePercent={chartData.summary.changeCountPercent}
          changeTitle={chartData.summary.changeTitle}
          filterOptions={chartData.filterOptions}
          onFilterChange={setFilterValue}
          dataType={'Contributions'}
          format='round' />
        <MapChart
          data={chartData.data}
          buckets={chartData.buckets}
          type="Contributions" />
      </Grid>
      <Grid item md={3} xs={12} style={elementStyles.sideColumn}>
        <StateSideChart
          sectionTitle={'Contributions by State'}
          data={chartData.data}
          filter={chartData.filter} />
      </Grid>
    </Grid>
  );
};

export default StateGridRow;
