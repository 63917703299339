import React, { createContext, useCallback, useContext, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { tokenStorage } from './tokenStorage';


const AuthContext = createContext({});

const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ audience, children }) => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState('');
  const [, setStoredClientId] = useState(localStorage.getItem('clientId'));

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const token = await getAccessTokenSilently({ audience });
        setAccessToken(token);
        tokenStorage.setToken(token);
      } catch (e) {
        console.log('');
        console.log('%c FAIL()', 'color:orangered');
        console.log('%c e', 'color:orangered', e);
        console.log('%c e.message', 'color:orangered', e.message);
      }
    };

    if (user?.sub) {
      getAccessToken();
    } else {
      setAccessToken('');
      tokenStorage.clearToken();
    }
  }, [audience, getAccessTokenSilently, user?.sub]);

  const selectClientId = useCallback((value) => {
    if (value === null) {
      localStorage.removeItem('clientId');
    } else {
      localStorage.setItem('clientId', value);
    }
    setStoredClientId(value);
  }, []);

  let clientId = null;
  let clientList = [];
  let allowClientChange = false;
  let isSuper = false;

  if (isAuthenticated) {
    clientId = localStorage.getItem('clientId') !== null ? +localStorage.getItem('clientId') : null;
    clientList = user?.sterling_client_id === undefined ? [] : Array.isArray(user.sterling_client_id) ? user.sterling_client_id : [user.sterling_client_id];
    isSuper = user?.sterling_isSuper === true;

    if (isNaN(clientId)) {
      clientId = null;
      localStorage.removeItem('clientId');
    }

    if (clientId !== null && !clientList.includes(clientId) && !isSuper) {
      clientId = null;
      localStorage.removeItem('clientId');
    }

    if (clientId === null && clientList.length === 1 && !isSuper) {
      clientId = clientList[0];
      localStorage.setItem('clientId', clientId);
    }

    if (clientList.length > 1 || isSuper) allowClientChange = true;
  }

  const isReady = !isLoading && ((isAuthenticated && Boolean(accessToken) && clientId !== null) || !isAuthenticated);

  // console.log('');
  // console.log('%c AuthProvider()[RENDER]', 'color:deeppink');
  // console.log('%c clientId', 'color:deeppink', clientId);
  // console.log('%c clientList', 'color:deeppink', clientList);
  // console.log('%c allowClientChange', 'color:deeppink', allowClientChange);
  // console.log('%c user', 'color:deeppink', user);
  // console.log('%c has(accessToken)', 'color:deeppink', Boolean(accessToken));
  // console.log('%c isAuthenticated', 'color:deeppink', isAuthenticated);
  // console.log('%c isLoading', 'color:deeppink', isLoading);
  // console.log('%c isReady', 'color:deeppink', isReady);
  // console.log('%c isSuper', 'color:deeppink', isSuper);

  return (
    <AuthContext.Provider value={{
      accessToken,
      clientId,
      clientList,
      allowClientChange,
      user,
      isAuthenticated,
      isLoading,
      isReady,
      isSuper,
      hasToken: Boolean(accessToken),
      selectClientId,
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, useAuth };
