import React from 'react';
import { useAuth } from '../auth';
import Layout from '../components/layout/layout';
import { Box } from '@material-ui/core';
import { UsersLanding, Unauthorized } from '../components/users';



const Users = () => {
  // console.log('');
  // console.log('%c views/Users()', 'color:darkcyan');

  const { isSuper } = useAuth();

  return (
    <Layout>
      <Box display="flex" justifyContent="center" bgcolor="white" p={2} py={6} minHeight={500}>
        {isSuper ? (
          <UsersLanding />
        ): (
          <Unauthorized />
        )}
      </Box>
    </Layout>
  );
};

export default Users;
