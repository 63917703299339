import React, { useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { parseChartData } from '../../../redux/services/contribution-service';
import { elementStyles } from '../../../styles/mainStyle';
import MonthChart from './month-chart-apex';
import MonthSideChart from './month-side-chart';
import ChartInfo from '../../shared/big-chart-info';
import { useSelector } from 'react-redux';


const MonthGridRow = () => {
  // console.log('');
  // console.log('%c contributions/MonthGridRow()', 'color:yellow');

  const contributions = useSelector(state => state.contributions.data.contributions);
  const [filterValue, setFilterValue] = useState('months:6');
  const chartData = parseChartData(contributions, filterValue);

  if (!chartData) return (
    <Box minHeight={450} p={3} style={{ backgroundColor: 'white'}}>
      <em>No Data Available</em>
    </Box>
  );

  return (
    <Grid container direction="row" justify="space-between" alignItems="stretch">
      <Grid item md={9} xs={12} style={elementStyles.column}>
        <ChartInfo
          title="Contributions"
          subtitle={`(${chartData.filter.title})`}
          total={chartData.summary.total}
          changeValue={chartData.summary.change}
          changePercent={chartData.summary.changePercent}
          changeTitle={chartData.summary.changeTitle}
          filterOptions={chartData.filterOptions}
          onFilterChange={setFilterValue} />
        <MonthChart
          data={chartData} />
      </Grid>
      <Grid item md={3} xs={12} style={elementStyles.sideColumn}>
        <MonthSideChart
          data={chartData.recipients}
          filter={chartData.filter} />
      </Grid>
    </Grid>
  );
};

export default MonthGridRow;
