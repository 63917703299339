import React from 'react';
import mainStyle from '../../styles/mainStyle';
import { Grid, Divider, Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { formatDate } from '../../utils/misc';

const styles = {
  root: {
    height: 'auto',
    overflow: 'hidden',
    backgroundColor: mainStyle.headerColor,
    display: 'flex',
    alignItems: 'center'
  }
};

const InputComponent = ({ title, ...props }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'flex-start', width: '50%' }}>
      <Box display={{ xs: 'none', md: 'block' }} style={{color: 'red', marginRight: '30px'}}>
        <Divider
          orientation="vertical"
          style={{ height: '170px', opacity: '.2', backgroundColor: 'white' }} />
      </Box>
      <div style={props.noPadding ? null : { paddingTop: '15px' }}>
        <h3
          className="rg-text"
          style={{
            color: 'white',
            fontWeight: 400,
            lineHeight: '1em',
            margin: '0px',
            marginBottom: '1em'
          }}
        >
          <i>{title}</i>
        </h3>
        <Divider
          style={{
            width: '40px',
            height: '2px',
            backgroundColor: '#FFFFFFA5',
            marginBottom: '14px'
          }}
        />
        <div>{props.children}</div>
      </div>
    </div>
  );
};

function HeaderWrapper(props) {
  const { title, date, data, noDate, noPadding, showAccount = true } = props;
  const account = useSelector((state) => state.client.data);

  return (
    <div style={styles.root} className="add-padding">
      <Grid
        container
        direction="row"
        alignItems="center"
        className="header-grid-row"
        style={{ height: '100%' }}>
        <Grid item sm={12} md={6} className="header-grid-title">
          <div className='default-container' style={{ height: '100%' }}>
            <div>
              <h1 className="header-text-for-dark">{showAccount ? account?.name : 'Sterling Foundation Management'}</h1>
              <h2 className="md-text" style={{ color: 'white', lineHeight: '0px', textTransform: 'uppercase' }}>
                <i>{title}</i>
              </h2>
              {
                (date && !noDate) ? (
                  <h2 className="md-text f-300" style={{ color: 'white' }}>
                    <i>as of {formatDate(date, 'mm dd, yr')}</i>
                  </h2>
                ) : (
                  <h2 className="md-text f-300">&nbsp;</h2>
                )
              }
            </div>
          </div>
        </Grid>
        {data && (
          <Grid item sm={12} md={6} container className="add-padding-inner-components">
            {data.map((info, idx) => (
              <InputComponent key={idx} noPadding={noPadding} title={info.title}>
                {info.component}
              </InputComponent>
            ))}
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default HeaderWrapper;
