import { Divider } from '@material-ui/core';
import React, { Fragment } from 'react';
import { elementStyles } from '../../styles/mainStyle';
import ListIconButton from './list-icon-button';
import Accordion from './accordion';

const FileList = ({ folders }) => {
  // console.log('');
  // console.log('%c shared/FileList()', 'color:gold');
  // console.log('%c folders', 'color:gold', folders);

  const rootFolder = folders?.find(v => v.isRoot);
  // console.log('%c rootFolder', 'color:gold', rootFolder);

  const subfolderList = folders?.filter(v => !v.isRoot).map((folder) => {
    return {
      title: folder.filteredPath,
      data: folder.files?.map((file, index) => {
        return (
          <div key={`list-${index}`} className="doc-pb">
            <ListIconButton
              title={file.title}
              href={file.meta.uri}
              at={file.meta.updatedAt}
              leadingIcon={<img src={file.meta.icon} alt="" />}
              />
          </div>
        )
      })
    };
  });
  // console.log('%c subfolderList', 'color:gold', subfolderList);

  return (
    <Fragment>
      {
        rootFolder && (
          <div>
            <div className="blue-s1 rg-text f-700">
              {rootFolder.filteredPath}
            </div>
            <Divider
              style={elementStyles.divider} />
          </div>
        )
      }
      {
        rootFolder?.files?.map((file, index) => {
          return (
            <div key={`root-${index}`} className='forms-button-container'>
              <ListIconButton
                title={file.title}
                href={file.id === 'webform' ? file.name : file.meta.uri}
                at={file.meta.updatedAt}
                leadingIcon={<img src={file.meta.icon} alt="" />}
                isWebForm={file.id === 'webform'}
              />
            </div>
          );
        })
      }
      {
        subfolderList?.length > 0 && (
          <Accordion list={subfolderList} />
        )
      }
    </Fragment>
  );
};

export default FileList;
