import React, { useEffect, useState } from 'react';
import { VictoryLabel, VictoryPie, } from 'victory';
import mainStyle from '../../../styles/mainStyle';


const defaultSegments = [
  {x: 1, y: 25, label: ' ', color: mainStyle.greyShade3},
  {x: 2, y: 8, label: '33%', color: mainStyle.greyShade3},
  {x: 3, y: 17, label: ' ', color: mainStyle.greyShade3},
  {x: 4, y: 25, label: ' ', color: mainStyle.greyShade3},
  {x: 5, y: 25, label: ' ', color: mainStyle.greyShade3},
];

function getSegments(currentPercent, requiredPercent) {
  const segments = [];
  let runningTotal = 0;

  // console.log('%c currentPercent', 'color:yellow', currentPercent);
  // console.log('%c requiredPercent', 'color:yellow', requiredPercent);

  defaultSegments.forEach(segment => {
    // console.log('');
    // console.log('%c segment', 'color:yellow', segment);
    // console.log('%c runningTotal', 'color:yellow', runningTotal);

    const postTotal = runningTotal + segment.y;
    // console.log('%c postTotal', 'color:yellow', postTotal);

    if (currentPercent > runningTotal && currentPercent <= postTotal) {
      // console.log('%c CHUNK_IT_UP', 'color:yellow');
      if (currentPercent === postTotal) {
        segments.push({
          ...segment,
          color: mainStyle.blueShadeAlt,
        });
      } else {
        segments.push({
          ...segment,
          y: currentPercent - runningTotal,
          label: ` `, // ${currentPercent}%
          color: mainStyle.blueShadeAlt,
        });
        segments.push({
          ...segment,
          y: segment.y - (currentPercent - runningTotal),
          color: (postTotal <= requiredPercent) ? mainStyle.orangeShade4 : mainStyle.greyShade3,
        });
      }
    } else if (currentPercent > postTotal) {
      // console.log('%c COMPLETED', 'color:yellow');
      segments.push({
        ...segment,
        color: mainStyle.blueShadeAlt,
      });
    } else if (postTotal <= requiredPercent) {
      // console.log('%c LESS_THAN_REQ', 'color:yellow');
      segments.push({
        ...segment,
        color: mainStyle.orangeShade4,
      });
    } else {
      // console.log('%c REMAINING', 'color:yellow');
      segments.push(segment);
    }

    runningTotal = postTotal;
  });

  return segments;
}

function PublicSupportChart(props) {
  // console.log('');
  // console.log('%c PublicSupportChart()', 'color:deeppink');

  const { currentPercent, requiredPercent } = props;
  // const currentPercent = 27;
  // const requiredPercent = 33;
  const [segments, setSegments] = useState([]);

  useEffect(() => {
    setSegments(getSegments(currentPercent, requiredPercent));
  }, [currentPercent, requiredPercent]);

  // console.log('%c currentPercent', 'color:deeppink', currentPercent);
  // console.log('%c requiredPercent', 'color:deeppink', requiredPercent);
  // console.log('%c segments', 'color:hotpink', segments);

  const colorScale = segments.map(item => item.color);
  // console.log('%c colorScale', 'color:hotpink', colorScale);

  return (
    <svg viewBox="0 -50 375 375">
      <VictoryPie
        colorScale={colorScale}
        standalone={false}
        padAngle={1}
        startAngle={-90}
        endAngle={90}
        width={375}
        height={375}
        data={segments}
        innerRadius={90}
        labelRadius={150}
        labelPosition="endAngle"
        labelPlacement="vertical"
        style={{
          labels: {
            fontSize: 16,
            fontWeight: 'bold',
            // fill: "grey",
          },
        }}
      />
      <VictoryLabel
        textAnchor="middle"
        style={{
          fontSize: 36,
          fontFamily: "Roboto",
          fontWeight: 800,
          fill: mainStyle.blueShade1,
          lineHeight: "1000px",
        }}
        x={375 / 2}
        y={350 / 2}
        text={`${Math.min(currentPercent, 100)}%`}
      />
      {/* <VictoryLabel
        textAnchor="middle"
        style={{
          fontSize: 14,
          fontFamily: "Roboto",
          fontWeight: 800,
          fill: mainStyle.blueShade1,
          lineHeight: "1000px",
        }}
        x={375 / 2}
        y={350 / 2}
        text={`of ${requiredPercent}%`}
      /> */}
    </svg>
  );
}

export default PublicSupportChart;
