import React from 'react';
import { SideChartData } from '../../shared/side-chart-data';
import SideInfoWrapper from '../../shared/side-info-wrapper';
import mainStyle from '../../../styles/mainStyle';
import { formatMoney } from '../../../utils/misc';


const MonthSideChart = ({ data, filter, listLimit = 5 }) => {
  // console.log('');
  // console.log('%c contributions/MonthSideChart()', 'color:orange');
  // console.log('%c data', 'color:orange', data);
  // console.log('%c filter', 'color:orange', filter);
  // console.log('%c listLimit', 'color:orange', listLimit);

  // const values = useSelector(state => state.contributions.data.values);
  const sectionTitle = `Contribution Breakdown`;
  const limit = Math.min(data.length, listLimit);
  const dataTitle = limit ? `Top ${limit} Contributions (${filter.title})` : `Contributions (${filter.title})`;

  const dataItems = data.slice(0, limit).map((item, idx) => (
    <SideChartData
      idx={idx}
      key={idx}
      title={item.name}
      amount={`${formatMoney(item.total, 0)}`}
      value={item.percent}
      color={mainStyle.blueShade4} />
  ));

  return (
    <SideInfoWrapper
      title={sectionTitle}
      dataTitle={dataTitle}
      data={dataItems} />
  );
};

export default MonthSideChart;
