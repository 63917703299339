import React from 'react';
import SideInfoWrapper from './side-info-wrapper';
import mainStyle from '../../styles/mainStyle';
import { SideChartData } from './side-chart-data';
import { formatMoney } from '../../utils/misc';

const StateSideChart = ({ data, filter, sectionTitle = 'State Totals', listLimit = 5 }) => {
  // console.log('');
  // console.log('%c contributions/StateSideChart()', 'color:red');
  // console.log('%c data', 'color:red', data);
  // console.log('%c filter', 'color:red', filter);
  // console.log('%c sectionTitle', 'color:red', sectionTitle);
  // console.log('%c listLimit', 'color:red', listLimit);

  const limit = Math.min(data?.length, listLimit);
  const dataTitle = limit ? `Top ${limit} State Recipients (${filter?.title})` : `State Recipients (${filter?.title})`;

  // console.log('%c limit', 'color:red', limit);
  // console.log('%c dataTitle', 'color:red', dataTitle);

  const dataItems = data?.length ? (
    [...data].sort((a, b) => b.total - a.total).slice(0, limit).map((item, idx) => (
      <SideChartData
        idx={idx}
        key={idx}
        title={item.stateTitle}
        amount={`${formatMoney(item.total, 0)} (${item.count})`}
        value={item.percent}
        color={mainStyle.blueShade4} />
    ))
  ) : (
    <div style={{ fontStyle: 'italic', textAlign: 'center' }}>No data available for current selection.</div>
  );
  // console.log('%c dataItems', 'color:red', dataItems);

  return (
    <SideInfoWrapper
      title={sectionTitle}
      dataTitle={dataTitle}
      data={dataItems} />
  );
};

export default StateSideChart;
