import React, { Fragment, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { chartColors, downloadCsv } from '../../../redux/services/disbursements-service';
import { formatMoney } from '../../../utils/misc';
import CardButton from '../../shared/button-card';
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@material-ui/core';


const chartOptions = {
  chart: {
    animations: {
      enabled: false
    },
    selection: {
      enabled: false,
    },
    stacked: true,
    toolbar: {
      show: false
    }
  },
  colors: chartColors,
  dataLabels: {
    enabled: false
  },
  fill: {
    opacity: 1
  },
  grid: {
    yaxis: {
      lines: {
        show: true
      }
    },
    xaxis: {
      lines: {
        show: false
      }
    },
  },
  legend: {
    show: false
  },
  plotOptions: {
    bar: {
      columnWidth: '60%',
      borderRadius: 0,
    },
  },
  stroke: {
    width: 1,
    colors: ['#fff']
  },
  yaxis: {
    labels: {
      formatter: (value) => (value === 0) ? 0 : `${formatMoney(value, 0)}`
    },
  },
  tooltip: {
    followCursor: true,
    custom: ({ series, seriesIndex, dataPointIndex, w }) => {
      return `<div><span class="asset-chart-tt-amount">${formatMoney(series[seriesIndex][dataPointIndex], 0)}</span> <span class="asset-chart-tt-label">${w.globals.seriesNames[seriesIndex]}</span></div>`;
    }
  },
  xaxis: {
    axisTicks: {
      show: false,
    },
    // categories: '',
    type: 'month'
  },
};

const applyChartOptions = (series, categories, options = chartOptions) => {
  if (series.length) {
    return { ...options, xaxis: { ...options.xaxis, categories: categories }};
  } else {
    return { ...options, xaxis: { ...options.xaxis, categories: categories }, yaxis: { ...options.yaxis, max: 1000 }};
  }
};

const MonthChart = ({ data }) => {
  // console.log('');
  // console.log('%c assets/MonthChartApex()', 'color:yellow');
  // console.log('%c data', 'color:yellow', data);

  // console.log('%c data.series', 'color:gold', data.series);
  // console.log('%c data.seriesCategories', 'color:gold', data.seriesCategories);

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const values = useSelector(state => state.disbursements.data.values);
  // console.log('%c values', 'color:yellow', values);

  const [options, setOptions] = useState(applyChartOptions(data.series, data.seriesCategories));

  useEffect(() => {
    setOptions(applyChartOptions(data.series, data.seriesCategories));
  }, [data.series, data.seriesCategories]);

  return (
    <Fragment>
      <Chart
        options={options}
        series={data.series}
        type="bar"
        height={350}
        width="100%"
        style={{marginTop: '2em'}} />

      <Legend
        legend={data.legend}
        colors={chartColors} />

      <div className="flex-c-ac" style={{ paddingTop: '1em' }}>
        <CardButton
          style={{ flexGrow: 1, maxWidth: '500px' }}
          onClick={() => {
            handleClickOpen();
            downloadCsv(values, data.filter, 'disbursements');
          }}>
          Download List ({data.filter.title})
        </CardButton>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Download Request</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your file is processing and will download when complete.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CardButton onClick={handleClose} size="small" autoFocus>
            Okay
          </CardButton>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const LegendItem = ({ item, color }) => {
  return (
    <div className="flex-ac" style={{ fontSize: '18px', fontStyle: 'italic', margin: '0 .5em' }}>
      {/* <div style={{ height: '.75em', width: '.75em', borderRadius: '50%', backgroundColor: color, marginRight: '.5em' }}></div> */}
      {item.title}:
      <strong style={{ color: color, marginLeft: '.5em' }}>
        { item.format === 'money' ? formatMoney(item.amount, 0) : item.amount }
      </strong>
    </div>
  );
};

const Legend = ({ legend, colors }) => {
  return (
    <div className="flex-c-ac" style={{ flexWrap: 'wrap' }}>
      {legend && legend.map((item, idx) => {
        return (
          <LegendItem
            color={colors[idx % colors.length]}
            item={item}
            key={idx} />
        );
      })}
    </div>
  );
};

export default MonthChart;
