import React, { Fragment } from 'react';
import MainRow from './main-row';
import MapRow from './map-row';


function Charts() {
  // console.log('');
  // console.log('%c disbursements/Charts()', 'color:lightblue');

  return (
    <Fragment>
      <MainRow />
      <MapRow />
    </Fragment>
  );
}

export default Charts;
