import React from 'react';
import { useSelector } from 'react-redux';
import HeaderWrapper from '../../shared/header-wrapper';
import RecentData from '../../shared/recent-data';


function Header() {
  // console.log('');
  // console.log('%c assets/Header()', 'color:lightblue');

  const summary = useSelector(state => state.assets.data?.summary);
  // console.log('%c summary', 'color:lightblue', summary);

  const data = [
    {
      title: 'Asset Value',
      component: (
        <RecentData
          total={summary?.total}
          date={summary?.asOf}
          amountChange={summary?.changeOverYearTotal}
          amountChangePercent={summary?.changeOverYearTotalPercent}
          changeType="YoY Change" />
      )
    }
  ];

  return (
    <HeaderWrapper
      title="ASSET VALUE"
      date={summary?.asOf}
      data={data} />
  );
}

export default Header;
