import axios from 'axios';
import { getUrl, getConfig } from '../redux-helpers';
import { parseAssets } from '../services/assets-service';

export const assetsTypes = {
  GET_ASSET_PAGE: 'GET_ASSET_PAGE',
  ASSET_PAGE_LOADING: 'ASSET_PAGE_LOADING',
  ASSETS_ERROR: 'ASSETS_ERROR',
};

export const getAssetPage = () => (dispatch) => {
  dispatch(setAssetPageLoading());

  axios
    .get(getUrl('accounts'), getConfig())
    .then((res) => {
      dispatch({
        type: assetsTypes.GET_ASSET_PAGE,
        payload: parseAssets(res.data),
        status: res.status
      });
    })
    .catch((error) => {
      console.log('');
      console.log('%c ERROR()', 'color:orangered');
      console.log('%c error', 'color:orangered', error);
      dispatch({
        type: assetsTypes.ASSETS_ERROR,
        message: error.response?.data.message,
        status: error.response?.status,
      });
    });
};

export const clearAssetPage = () => (dispatch) => {
  dispatch({
    type: assetsTypes.GET_ASSET_PAGE,
    payload: null,
  });
};

export const setAssetPageLoading = () => ({
  type: assetsTypes.ASSET_PAGE_LOADING,
});
