import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';
import logo from '../images/logo.svg';
import { AccountSelect } from './AccountSelect';
import { useAuth } from './AuthContext';


export const AuthLoading = () => {
  const { clientId, isAuthenticated, isReady } = useAuth();

  const showAccountSelect = clientId === null && isAuthenticated && !isReady;

  return (
    <Box position="static" display="flex" alignItems="center" justifyContent="center" height="100vh" width="100vw">
      <Box paddingTop={4} paddingRight={6} paddingBottom={4} paddingLeft={6} textAlign="center">
        <div>
          <img src={logo} alt="Sterling Foundations" width="300" />
        </div>
        {showAccountSelect && (
          <AccountSelect />
        )}
        {!showAccountSelect && (
          <CircularProgress color="primary" />
        )}
      </Box>
    </Box>
  );
};
