import { contributionTypes } from '../actions/contribution-actions';

const initialState = {
  data: null,
  loading: true,
  message: '',
  status: null
};

const ContributionReducer = (state = initialState, action) => {
  switch (action.type) {
    case contributionTypes.GET_CONTRIBUTIONS_PAGE:
      // console.log('');
      // console.log('%c contributionsReducer():GET_CONTRIBUTIONS_PAGE', 'color:hotpink');
      // console.log('%c action.payload', 'color:hotpink', action.payload);
      // console.log('%c state', 'color:hotpink', state);

      return {
        ...state,
        data: action.payload,
        loading: false,
        status: action.status
      };

    case contributionTypes.CONTRIBUTION_ERROR:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status
      };

    case contributionTypes.CONTRIBUTIONS_PAGE_LOADING:
      return {
        ...state,
        loading: true,
        status: null
      };

    default:
      return state;
  }
}

export default ContributionReducer;
