import React, { useEffect } from 'react';
import Layout from '../components/layout/layout';
import Header from '../components/policy-pages/header/header';
import RelatedLinksContainer from '../components/shared/related-links-container';

export default function Disclosures() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <div style={{ background: 'white' }}>
        <Header title="Disclosures" />
        <RelatedLinksContainer title="Disclosures">
          <p>Please read this disclaimer carefully before using the website of Sterling Foundation Management ("Sterling"), <a href="//www.sterlingfoundations.com" target="_blank" rel="noreferrer">www.SterlingFoundations.com</a>.</p>

          <p>The content displayed on the website is the intellectual property  of the Sterling. You may not reuse, republish, or reprint such content without our written consent. All information posted is merely for educational and informational purposes. It is not intended as a substitute for professional advice. Should you decide to act upon any information on this website, you do so at your own risk. While the information on this website has been verified to the best of our abilities, we cannot guarantee that there are no mistakes or errors. We reserve the right to change this policy at any given time.  If you want to make sure that you are up to date with the latest changes, we advise you to frequently visit this page.</p>
        </RelatedLinksContainer>
      </div>
    </Layout>
  );
}
