export const parseClientData = (data) => {
  if (!data) {
    return null;
  }

  const distributionRequirements = {
    amount: +data.mdr_amount,
    remaining: +data.mdr_remaining,
    current: +data.mdr_amount - (+data.mdr_remaining),
    year: data.mdr_year,
  }

  return {
    name: data.client_name,
    type: data.client_type,
    isPublic: data.client_type.toLowerCase() === 'public',
    distributionRequirements
  };
};
