import React, { Fragment } from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";


export default function ColorButton(props) {

  const { color, textColor, textHoverColor, hoverColor, noHoverChange, contained, noRadius, ...rest } = props;

  const ContainedButton = withStyles((theme) => ({
    root: {
      color: textColor
        ? textColor
        : theme.palette.getContrastText(color),
      borderRadius: noRadius ? "0px" : "5px",
      backgroundColor: color,
      "&:hover": {
        color: textHoverColor
          ? textHoverColor
          : textColor
          ? textColor
          : theme.palette.getContrastText(color),
        backgroundColor: hoverColor,
      },
    },
  }))(Button);

  const OutlinedButton = withStyles((theme) => ({
    root: {
      color: textColor
        ? textColor
        : theme.palette.getContrastText(color),
      borderColor: color,
      borderRadius: noRadius ? "0px" : "5px",
      "&:hover": {
        color: textHoverColor
          ? textHoverColor
          : theme.palette.getContrastText(color),
        borderColor: noHoverChange ? color : hoverColor,
        backgroundColor: noHoverChange ? "" : hoverColor,
      },
    },
  }))(Button);

  return (
    <Fragment>
      {contained ? (
        <ContainedButton {...rest} variant="contained" color="primary">
          {props.children}
        </ContainedButton>
      ) : (
        <OutlinedButton {...rest} variant="outlined" color="primary">
          {props.children}
        </OutlinedButton>
      )}
    </Fragment>
  );
}

ColorButton.propTypes = {
  color: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  textHoverColor: PropTypes.string,
  hoverColor: PropTypes.string.isRequired,
  noHoverChange: PropTypes.bool,
  contained: PropTypes.bool,
  noRadius: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func,
  href: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
};
