import React from 'react';
import Layout from '../components/layout/layout';
import Header from '../components/disbursements/header/header';
import Charts from '../components/disbursements/charts';


const Disbursements = () => {
  // console.log('');
  // console.log('%c Disbursements()', 'color:skyblue');

  // const isLoading = useSelector(state => state.disbursements.loading);
  // const disbursements = useSelector(state => state.disbursements.data?.disbursements);
  // const summary = useSelector(state => state.disbursements.data?.summary);

  return (
    <Layout>
      <Header />
      <Charts />
    </Layout>
  );
};

export default Disbursements;
