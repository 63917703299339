import React, { Fragment } from 'react';
import { formatMoney } from '../../../utils/misc';
import mainStyle from '../../../styles/mainStyle';
import StopIcon from '@material-ui/icons/Stop';
import { VictoryChart, VictoryBar, VictoryAxis } from 'victory';
import { shortMonths } from '../../../utils/constants';


function ContributionsChart({ summary }) {
  // console.log('');
  // console.log('%c summary/charts/contributionsChart()', 'color:yellow');
  // console.log('%c summary', 'color:yellow', summary);

  if (!Boolean(summary) || !Boolean(summary.summaryAt)) return ( <div>Loading...</div> );

  const chartMonth = shortMonths[summary.summaryAt.getMonth()];

  const ytdYear = summary.year ?? summary.summaryAt.getFullYear();
  const ytdCount = summary.ytdCount ?? 0;
  const ytdTotal = summary.ytdTotal ?? 0;

  const previousYear = summary.previousYear ?? ytdYear - 1;
  const previousYtdCount = summary.previousYtdCount ?? 0;
  const previousYtdTotal = summary.previousYtdTotal ?? 0;

  const barData = [
    { x: `${chartMonth}\n${previousYear}`, y: previousYtdTotal },
    { x: `${chartMonth}\n${ytdYear}`, y: ytdTotal },
  ];

  return (
    <Fragment>
      <VictoryChart height={375} domainPadding={{ x: 80, y: 50 }} padding={70}>
        <VictoryAxis
          style={{
            axis: { stroke: 'grey', strokeDasharray: 5 },
            tickLabels: {
              fontSize: '24px',
              fill: mainStyle.blueShade2,
              padding: 10,
              fontStyle: 'italic'
            }
          }}
        />
        <VictoryBar
          horizontal
          barWidth={60}
          style={{
            data: {
              fill: ({ index }) =>
                index === 0 ? mainStyle.blueShade2 : mainStyle.blueShadeAlt
            },
            labels: {
              fill: mainStyle.blueShade1,
              fontSize: '20px',
              fontWeight: 700,
              fontFamily: 'Roboto'
            }
          }}
          labels={({ datum }) => `${formatMoney(datum.y, 0)}`}
          data={barData}
        />
      </VictoryChart>
      <div className="legend mb-2">
        <div className="flex-c-ac blue-s1">
          <StopIcon style={{ color: mainStyle.blueShadeAlt }} />{' '}
          <i>
            <strong>{ytdCount} Contributions</strong>
          </i>
        </div>
        <div className="flex-c-ac blue-s1">
          <StopIcon style={{ color: mainStyle.blueShade2 }} />{' '}
          <i>
            <strong>{previousYtdCount} Contributions</strong>
          </i>
        </div>
      </div>
    </Fragment>
  );
}

export default ContributionsChart;
