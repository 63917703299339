import React from 'react';
import { Auth0Login } from '../auth';


const Login = () => {
  return (
    <Auth0Login />
  );
};

export default Login;
