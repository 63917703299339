import UpIcon from "@material-ui/icons/ArrowDropUp";
import DownIcon from "@material-ui/icons/ArrowDropDown";
import React from "react";
import mainStyle from "../../styles/mainStyle";

export const getArrow = (amount) => {
  return parseInt(amount) > 0 ? (
    <UpIcon style={{ color: mainStyle.headerTextColorForDark }} />
  ) : (
    <DownIcon style={{ color: mainStyle.orangeShade4 }} />
  );
};
