import React from 'react';
import { Divider } from '@material-ui/core';
import { formatMoney } from '../../../utils/misc';

const SummaryChartInfo = (props) => {
  const { title, subtitle, totalAmount, amountChange, amountChangePercent, amountChangeTitle, isAlt, minHeight = true, paddingBottom = true, paddingTop = true } = props;

  const hasSubtitle = !!subtitle?.length;
  const hasTotalAmount = typeof totalAmount !== 'undefined';
  const hasAmountChange = typeof amountChange !== 'undefined';
  const hasAmountChangeTitle = typeof amountChangeTitle !== undefined;

  return (
    <div
      id={props.id}
      style={{
        flexGrow: 0,
        minHeight: minHeight ? '165px' : '0px',
        paddingTop: paddingTop ? '40px' : '0px',
        paddingBottom: hasSubtitle || hasTotalAmount || !paddingBottom ? '0px' : '40px',
        textAlign: 'center',
      }}
    >
      <div style={{ display: 'inline-block' }}>
        <div className="rg-text f-700 blue-s1">{title}</div>
        <Divider
          style={{
            width: '40px',
            height: '2px',
            opacity: '.2',
            backgroundColor: 'gray',
            marginRight: 'auto',
            marginLeft: 'auto',
            marginTop: '15px',
            marginBottom: '15px'
          }}
        />
      </div>
      {hasSubtitle && (
        <div className={`xl-text f-700 ${isAlt ? 'header-text-for-light' : 'header-text-for-light'}`}>
          {subtitle}
        </div>
      )}
      {hasTotalAmount && (
        <div className={`xl-text f-700 ${isAlt ? 'header-text-for-light' : 'header-text-for-light'}`}>
          {formatMoney(totalAmount, 0)}
        </div>
      )}
      {hasAmountChange && (
        <div style={{ marginTop: '15px' }} className={`flex-c-ac ${amountChange < 0 && 'text-alert'}`}>
          <i>
            {formatMoney(amountChange, 0)}{' '}
            {amountChangePercent !== 0 && (
              <span>({amountChangePercent}%)</span>
            )}
          </i>
        </div>
      )}
      {hasAmountChangeTitle && (
        <div style={{ marginTop: '8px' }} className="sm-text">
          <i>{amountChangeTitle}</i>
        </div>
      )}
    </div>
  );
};

export default SummaryChartInfo;
