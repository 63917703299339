import React from "react";
import PropTypes from "prop-types";
import KeyboardTabIcon from "@material-ui/icons/KeyboardTab";
import CreateIcon from "@material-ui/icons/Create";
import '../../styles/list-icon-button.scss'
import { formatDate } from "../../utils/misc";
import TriplePointForm from "./triple-point-form";

const handleWebFormOnClick = (e) => {
  e.target.blur();
  e.preventDefault();
};

export default function ListIconButton({ leadingIcon, trailingIcon, title, onClick, download, href, at, isWebForm }) {
  // console.log('');
  // console.log('%c ListIconButton()', 'color:deeppink');
  // console.log('%c isWebForm', 'color:deeppink', isWebForm);

  if (isWebForm) {
    return (
      <TriplePointForm title={title} url={href}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="" className="list-icon-btn flex-sb-ac" onClick={handleWebFormOnClick} style={{ cursor: 'pointer' }}>
          <div className="flex-ac">
            <div>{leadingIcon || <CreateIcon />}</div>
            <div className="flex-col" style={{ marginLeft: '16px' }}>
              <span style={{ fontWeight: 700 }}>{title}</span>
            </div>
          </div>
        </a>
      </TriplePointForm>
    );
  }

  return (
    <a
      onClick={onClick}
      className="list-icon-btn flex-sb-ac"
      download={download}
      target="_blank"
      rel="noreferrer noopener"
      href={href}
    >
      <div className="flex-ac">
        <div>{leadingIcon || <CreateIcon />}</div>
        <div className="flex-col" style={{ marginLeft: '16px' }}>
          <span style={{ fontWeight: 700 }}>{title}</span>
          {at && (
            <small><em>Updated {formatDate(at, 'mm dd, yr')}</em></small>
          )}
        </div>
      </div>
      <div className="list-icon-btn-ti">
        {trailingIcon || <KeyboardTabIcon />}
      </div>
    </a>
  );
}

ListIconButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  leadingIcon: PropTypes.element,
  trailingIcon: PropTypes.element,
  download: PropTypes.bool
};
