import React from "react";
import { Link } from "react-router-dom";
import { Divider } from "@material-ui/core";
import TriplePointForm from "../shared/triple-point-form";
import mainStyle from "../../styles/mainStyle";
import { portalForms } from '../../utils/constants';
import { getYear } from "../../utils/misc";

const styles = {
  root: {
    width: "100%",
    backgroundColor: mainStyle.headerColor,
    height: "120px",
    display: "flex",
    alignItems: "center",
    position: 'relative',
    zIndex: 500
  },
  container: {
    textAlign: "center",
    width: "100%",
    fontSize: 18,
    color: "#FFFFFF9A",
  },
  links: {
    textDecoration: "none",
    color: "white",
    cursor: 'pointer'
  },
  divider: {
    height: "18px",
    backgroundColor: "white",
    marginLeft: "12px",
    marginRight: "12px",
  },
};

const links = [
  {
    label: "Privacy",
    href: "/privacy",
  },
  {
    label: "Terms of Use",
    href: "/terms-of-use",
  },
  {
    label: "Disclosures",
    href: "/disclosures",
  },
];

function Footer() {
  return (
    <footer style={styles.root}>
      <div style={styles.container}>
        <div style={{ marginBottom: "24px" }}>
          Copyright &copy; {getYear()} Sterling Foundation Management LLC. All Rights
          Reserved.
        </div>
        <div className="flex-c-ac">
          {links.map((link, idx) => (
              <React.Fragment key={idx}>
                <Link
                  className="hover-underline"
                  to={link.href}
                  style={styles.links}
                >
                  {link.label}
                </Link>
                <Divider orientation="vertical" style={styles.divider} />
              </React.Fragment>
          ))}
          <TriplePointForm title={portalForms.contactUs.title} url={portalForms.contactUs.url}>
            <span className="hover-underline" style={styles.links}>{portalForms.contactUs.title}</span>
          </TriplePointForm>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
