import React, { Fragment } from 'react';
import Chart from 'react-apexcharts';
import { chartColors } from '../../../redux/services/assets-service';
import { formatMoney } from '../../../utils/misc';


const MainChart = ({ data }) => {
  // console.log('');
  // console.log('%c assets/MainChart()', 'color:red');
  // console.log('%c data', 'color:red', data);

  return (
    <Fragment>
      <Chart
        options={chartOptions}
        series={data.series}
        type="line"
        height="500"
        width="100%"
        style={{marginTop: '2em'}} />
      <Legend
        chartLegend={data.legend}
        colors={chartColors} />
    </Fragment>
  );
};

const chartOptions = {
  chart: {
    // type: 'line',
    responsive: [
      {
        // breakpoint: undefined,
        options: {}
      }
    ],
    animations: {
      enabled: false
    },
    brush: {
      enabled: false
    },
    zoom: {
      enabled: false
    },
    sparkline: {
      enabled: false
    },
    toolbar: {
      show: false
    }
  },
  dataLabels: {
    enabled: false
  },
  legend: {
    show: false
  },
  stroke: {
    curve: 'smooth',
    width: 5
  },
  grid: {
    yaxis: {
      lines: {
        show: true
      }
    },
    xaxis: {
      lines: {
        show: true
      }
    }
  },
  yaxis: {
    lines: {
      show: true
    },
    labels: {
      formatter: (value) => (value === 0) ? 0 : `${formatMoney(value, 0)}`
    }
  },
  xaxis: {
    lines: {
      show: true
    },
    type: 'month'
  },
  // fill: {
  //   type: 'gradient',
  //   gradient: {
  //     shade: 'light',
  //     type: 'vertical',
  //     shadeIntensity: 0.3,
  //     gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
  //     inverseColors: true,
  //     opacityFrom: 1,
  //     opacityTo: 0,
  //     // stops: [0, 50],
  //     colorStops: []
  //   }
  // },
  tooltip: {
    followCursor: true,
    custom: ({ series, seriesIndex, dataPointIndex, w }) => {
      return `<div><span class="asset-chart-tt-amount">${formatMoney(series[seriesIndex][dataPointIndex], 0)}</span> <span class="asset-chart-tt-label">(${w.globals.seriesNames[seriesIndex]} Value)</span></div>`;
    }
  },
  colors: chartColors,
  markers: {
    size: 4,
    colors: '#fff',
    strokeColors: chartColors,
    strokeWidth: 2,
    strokeOpacity: 0.9,
    strokeDashArray: 0,
    fillOpacity: 0,
    discrete: [],
    shape: 'circle',
    radius: 3,
    offsetX: 0,
    offsetY: 0,
    onClick: undefined,
    onDblClick: undefined,
    showNullDataPoints: true,
    hover: {
      size: undefined,
      sizeOffset: 3
    }
  }
};

const CircleColor = ({ color }) => {
  return (
    <div
      style={{
        height: '15px',
        width: '15px',
        borderRadius: '50%',
        backgroundColor: color
      }}></div>
  );
};

const LegendItem = ({ color, title, amount }) => {
  return (
    <div className="flex-ac">
      <div style={{ marginRight: '8px' }}>
        <CircleColor color={color} />
      </div>
      <div style={{ marginRight: '8px', fontWeight: 700, fontSize: '18px' }}>
        {title}:
      </div>
      <div>
        <i>{formatMoney(amount, 0)}</i>
      </div>
    </div>
  );
};

const Legend = ({ chartLegend, colors }) => {
  return (
    <div className="flex-c-ac" style={{ flexWrap: 'wrap' }}>
      {chartLegend && chartLegend.map((item, idx) => {
        if (idx === chartLegend.length - 1) {
          return (
            <LegendItem
              color={colors[idx % colors.length]}
              title={item.title}
              amount={item.amount}
              key={idx}
            />
          );
        }
        return (
          <div key={idx} style={{ marginRight: '20px' }}>
            <LegendItem
              color={colors[idx % colors.length]}
              title={item.title}
              amount={item.amount}
            />
          </div>
        );
      })}
    </div>
  );
};

export default MainChart;
