import React, { useRef } from 'react';
import ShowHide from './show-hide';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import '../../styles/my-select.scss';

export default function MySelect({
  children,
  label,
  onChange,
  onMouseDown,
  ...rest
}) {
  const selectRef = useRef();

  const size1 = () => {
    selectRef.current.size = 1;
  };
  const handleSize = (e) => {
    if (selectRef.current?.options.length > 5) {
      selectRef.current.size = 5;
    } else {
      selectRef.current.size = selectRef.current?.options.length || 3;
    }

    onMouseDown && onMouseDown(e);
  };
  const handleChange = (e) => {
    size1();
    onChange && onChange(e);
  };

  return (
    <div className="my-select-root">
      <div>
        <label className="flex">
          {label}
          <ShowHide show={!!rest.required}>
            <span className="orange-s4">*</span>
          </ShowHide>
        </label>
      </div>
      <ClickAwayListener onClickAway={size1}>
        <select
          ref={selectRef}
          onMouseDown={handleSize}
          onChange={handleChange}
          className="my-select wbg"
          {...rest}
        >
          {children}
        </select>
      </ClickAwayListener>
    </div>
  );
}
MySelect.Option = ({ children, ...rest }) => (
  <option className="my-select-option" {...rest}>
    {children}
  </option>
);
