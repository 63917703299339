import { portalForms } from "../../utils/constants";
import { getFileUrl } from "../redux-helpers"

export const parseFilesData = (data) => {
  // console.log('');
  // console.log('%c parseDataFiles()', 'color:fuchsia');
  // console.log('%c data', 'color:fuchsia', data);

  if (!data || !data.length) {
    return null;
  }

  let filesTree = new Tree();

  data.forEach((file) => {
    filesTree = updateFilesTree(filesTree, file);
  });
  // console.log('%c filesTree', 'color:fuchsia', filesTree);

  const gdrKey = 'Grant Disbursement Request';
  filesTree = updateFilesTree(filesTree, {
    file_created_at: null,
    file_description: `${portalForms.grantDisbursementRequest.title}`,
    file_id: 'webform',
    file_name: portalForms.grantDisbursementRequest.url,
    file_size: 1234,
    file_updated_at: null,
    icon_link: 'https://drive-thirdparty.googleusercontent.com/16/type/application/form',
    is_starred: false,
    level: 3,
    mime_type: 'application/form',
    path: `CLIENTS/CLIENT/FORMS_PAGE/${gdrKey}`,
    section_name: 'FORMS_PAGE',
  });

  filesTree.list.sort((a, b) => a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1);
  filesTree.list.sort((a, b) => b.meta.updatedAt - a.meta.updatedAt);
  // console.log('%c filesTree', 'color:fuchsia', filesTree);
  // console.log('%c filesTree.list', 'color:fuchsia', filesTree.list);

  const disbursementHeaderFiles = filesTree.get('DISBURSEMENTS_PAGE_HEADER')?.children.filter(f => f.type === 'file').slice(0, 2) || [];
  const documentHeaderFiles = filesTree.list.filter(f => f.meta.section?.toLowerCase() === 'documents_page').slice(0, 3);
  const formHeaderFiles = filesTree.get('FORMS_PAGE_HEADER')?.children.filter(f => f.type === 'file').slice(0, 3) || [];

  disbursementHeaderFiles.push({
    title: portalForms.grantDisbursementRequest.title,
    route: portalForms.grantDisbursementRequest.route,
    url: portalForms.grantDisbursementRequest.url,
    isWebForm: true
  });

  // console.log('%c disbursementHeaderFiles', 'color:fuchsia', disbursementHeaderFiles);
  // console.log('%c documentHeaderFiles', 'color:fuchsia', documentHeaderFiles);
  // console.log('%c formHeaderFiles', 'color:fuchsia', formHeaderFiles);

  return {
    filesTree,
    disbursementHeaderFiles,
    documentHeaderFiles,
    formHeaderFiles
  };
};

export const NODETYPE_FILE = 'file';
export const NODETYPE_FOLDER = 'folder';

const Node = class {
  constructor(type, id, name, title, meta = {}) {
    /** @type {string} */
    this.type = type;
    /** @type {string} */
    this.id = id;
    /** @type {string} */
    this.name = name;
    /** @type {string} */
    this.title = title;
    /** @type {Object} */
    this.meta = meta;
    /** @type {Node[]} */
    this.children = [];
  }

  /**
   * @param {Node} node
   */
  add(node) {
    this.children.push(node);
  }
}

const Tree = class {
  constructor() {
    /** @type {Node[]} */
    this.nodes = [];
    /** @type {Node} folders.* */
    this.folders = {};
    this.list = [];
    this.sortTitles = ['creation documents', 'governance documents', 'irs documents', 'board documents', 'program documents', 'audited financial statements', 'tax returns', 'state filings', 'reference materials', 'private foundation creation application', 'public charity creation application', 'grant disbursement request', 'expense reimbursement requests/reports', 'expense reimbursement requests & reports', 'expense reimbursement requests and reports', 'irs form w-9'];
  }

  get(path) {
    const folder = this.folders[path] ?? null;
    if (folder) this.sortRootFolders(folder);
    return folder;
  }

  sortRootFolders(folder) {
    folder.children.sort((a, b) => {
      const indexOfA = this.sortTitles.indexOf(a.title.toLowerCase());
      const indexOfB = this.sortTitles.indexOf(b.title.toLowerCase());

      if (indexOfA === -1 && indexOfB === -1) {
        return 0;
      } else if (indexOfA === -1 || (indexOfB !== -1 && indexOfB < indexOfA)) {
        return 1;
      } else {
        return -1;
      }
    });
  }

  filterFolders(path) {
    // console.log('');
    // console.log('%c filterFolders()', 'color:fuchsia');
    // console.log('%c path', 'color:fuchsia', path);
    // console.log('%c this.folders', 'color:fuchsia', this.folders);

    const result = [];

    for (const key in this.folders) {
      // console.log('');
      // console.log('%c key', 'color:fuchsia', key);
      // console.log('%c key.toLowerCase().indexOf(path.toLowerCase())', 'color:fuchsia', key.toLowerCase().indexOf(path.toLowerCase()) === 0);
      if (key.toLowerCase().indexOf(path.toLowerCase()) === 0) {
        // console.log('%c key', 'color:fuchsia', key);
        const currentNode = this.folders[key];
        // console.log('%c currentNode', 'color:fuchsia', currentNode);
        const isRoot = key.toLowerCase() === path.toLowerCase();
        const filteredPath = isRoot ? currentNode.name : key.split('/').slice(path.split('/').length).join('/');
        const files = currentNode.children.filter(v => v.type === 'file')
        files.sort((a, b) => a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1);
        result.push({
          ...currentNode,
          filteredPath,
          files,
          isRoot
        })
      };
    }
    result.sort((a, b) => a.meta.path.toLowerCase() > b.meta.path.toLowerCase() ? 1 : -1);
    // console.log('%c result', 'color:fuchsia', result);

    return result;
  }

  /**
   * @param {Object} file
   * @param {string} file.file_created_at
   * @param {string} file.file_description
   * @param {string} file.file_id
   * @param {string} file.file_name
   * @param {string} file.file_size
   * @param {string} file.file_updated_at
   * @param {string} file.icon_link
   * @param {string} file.is_starred
   * @param {string} file.level
   * @param {string} file.mime_type
   * @param {string} file.path
   * @param {string} file.section_name
   */
  add(file) {
    // console.log('');
    // console.log('%c file', 'color:cyan', file);
    const path = this.cleanFolder(file.path);
    const title = file.file_description?.length > 0 ? file.file_description : file.file_name;
    const fileNode = new Node(NODETYPE_FILE, file.file_id, file.file_name, title, {
      createdAt: new Date(file.file_created_at),
      updatedAt: new Date(file.file_updated_at),
      size: file.file_size,
      icon: file.icon_link,
      mimeType: file.mime_type,
      description: file.file_description,
      path: path,
      section: file.section_name,
      level: file.level,
      uri: getFileUrl(file.file_id),
    });
    if (path.length) {
      const folder = this.addFolder(path);
      folder.add(fileNode);
    } else {
      this.nodes.push(fileNode);
    }
    this.list.push(fileNode);
    return this;
  }

  /**
   * @param {string} folder
   * @returns {Node}
   */
  addFolder(folder) {
    if (!this.folders.hasOwnProperty(folder)) {
      /** @type {string[]} folders */
      const folders = this.splitPath(folder);
      const lastFolder = folders.pop();
      const folderNode = new Node(NODETYPE_FOLDER, null, lastFolder, lastFolder, {
        path: folder
      });
      this.folders[folder] = folderNode;
      if (folders.length) {
        const parentFolder = this.addFolder(this.joinPath(folders));
        parentFolder.add(folderNode);
      } else {
        this.nodes.push(folderNode);
      }
      return folderNode;
    } else {
      return this.folders[folder];
    }
  }

  /**
   * @param {string} folder
   */
  cleanFolder(folder) {
    const returnFolder = this.splitPath(folder);
    returnFolder.splice(0, 2);
    return this.joinPath(returnFolder);
  }

  /**
   * @param {string} path
   */
  splitPath(path) {
    return path.split('/');
  }

  /**
   * @param {string[]} path
   */
  joinPath(path) {
    return path.join('/');
  }
}

function updateFilesTree(filesTree, file) {
  return filesTree.add(file);
}

export const getFileExtIconLink = (filename, size = '16') => {
  // console.log('');
  // console.log('%c getFileExtIconLink()', 'color:cyan');
  // console.log('%c filename', 'color:cyan', filename);

  const ext = filename.toLowerCase().split('.').pop();
  // console.log('%c ext', 'color:cyan', ext);

  let path;

  switch (ext) {
    case 'pdf':
      path = 'application/pdf';
      break;
    case 'document':
      path = 'application/vnd.google-apps.document';
      break;
    case 'map':
      path = 'application/vnd.google-apps.map';
      break;
    case 'ppt':
      path = 'application/vnd.google-apps.presentation';
      break;
    case 'xls':
    case 'xlsx':
      path = 'application/vnd.google-apps.spreadsheet';
      break;
    case 'sheet':
      path = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      break;
    case 'doc':
    case 'docx':
      path = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      break;
    case '7z':
    case 'rar':
    case 'zip':
      path = 'application/x-zip-compressed';
      break;
    case 'mp3':
      path = 'audio/mp3';
      break;
    case 'wav':
      path = 'audio/';
      break;
    case 'gif':
      path = 'image/gif';
      break;
    case 'jpg':
    case 'jpeg':
      path = 'image/jpeg';
      break;
    case 'png':
      path = 'image/png';
      break;
    case 'img':
      path = 'image/';
      break;
    case 'csv':
      path = 'text/csv';
      break;
    default:
      path = 'document/';
      break;
  }

  return `https://drive-thirdparty.googleusercontent.com/${size}/type/${path}`;
}
