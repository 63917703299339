import React from 'react';
import ColorButton from '../components/shared/color-button';
import mainStyle from '../styles/mainStyle';

export default function Four04(props) {
  return (
    <div style={{ height: '100vh', background: 'white' }}>
      <div id="four04">
        <div>
          <h1>404</h1>
          <h2>
            <strong>Oops!</strong>
          </h2>
          <p>That page doesn't exist or is unavailable.</p>
          <ColorButton
            contained
            noRadius
            textColor={mainStyle.blueShade3}
            textHoverColor={"white"}
            hoverColor={mainStyle.blueShade3}
            color={mainStyle.greyShade3}
            onClick={() => {
              props.history.push('/');
            }}
          >
            Go Back to Home
          </ColorButton>
        </div>
      </div>
    </div>
  );
}
