import axios from 'axios';
import { getUrl, getConfig } from '../redux-helpers';
import { parseFilesData } from '../services/files-service';

export const filesTypes = {
  GET_FILES_PAGE: 'GET_FILES_PAGE',
  FILES_PAGE_LOADING: 'FILES_PAGE_LOADING',
  FILE_ERROR: 'FILE_ERROR'
};

export const getFilesPage = () => (dispatch) => {
  dispatch(setFilesPageLoading());

  axios
    .get(getUrl('files'), getConfig())
    .then((res) => {
      dispatch({
        type: filesTypes.GET_FILES_PAGE,
        payload: parseFilesData(res.data),
        status: res.status
      });
    })
    .catch((error) => {
      dispatch({
        type: filesTypes.FILE_ERROR,
        message: error.response?.data.message,
        status: error.response?.status
      });
    });
};

export const clearFilesPage = () => (dispatch) => {
  dispatch({
    type: filesTypes.GET_FILES_PAGE,
    payload: null,
  });
};

export const setFilesPageLoading = () => ({
  type: filesTypes.FILES_PAGE_LOADING
});
