import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { useAuth } from './AuthContext';
import axios from 'axios';
import { getUrl, getConfig } from '../redux/redux-helpers';


export const AccountSelect = () => {
  const { clientId, hasToken, isAuthenticated, selectClientId } = useAuth();
  const [clients, setClients] = useState([]);
  const [value, setValue] = React.useState(clientId ?? '');

  useEffect(() => {
    if (!isAuthenticated || !hasToken) return;

    const fetchAccounts = () => {
      return axios.get(getUrl(`clients`), getConfig());
    };

    fetchAccounts().then(response => {
      const newClients = response.data?.map(item => ({
        name: item.client_name,
        value: item.client_id,
      })) ?? [];
      newClients.sort((a, b) => a.name?.localeCompare(b.name));
      setClients(newClients);
    }).catch((error) => {
      console.log('');
      console.log('%c error', 'color:orangered', error);
    });

  }, [isAuthenticated, hasToken]);

  const handleChange = (event) => {
    setValue(event.target.value);
    selectClientId(event.target.value !== '' ? event.target.value : null);
  };

  return (
    <StyledContainer>
      <label htmlFor="account-select" style={labelStyle}>Accounts:</label>
      <select id="account-select"
        value={value}
        style={selectStyle}
        onChange={handleChange}>
        <option value={''} disabled>-- Select an Account --</option>
        {clients.map(client => (
          <option key={client.value} value={client.value}>{client.name}</option>
        ))}
      </select>
    </StyledContainer>
  );
};


const StyledContainer = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',

    border: '1px solid white',
    borderRadius: '.5em',
    color: 'white',
    fontSize: 18,
    marginTop: theme.spacing(1.5),
    padding: '.75em 1em',
    textAlign: 'left',
  },
}))(Box);

const labelStyle = {
  fontSize: 18,
  fontWeight: 500,
  marginBottom: '.5em',
};

const selectStyle = {
  borderRadius: '.25em',
  fontSize: 'inherit',
  width: 360,
  padding: '.33em',
  '&:hover': {
    color: 'red',
    outline: '3px solid red',
  },
  '&:focusVisible': {
    outline: '0px solid white',
  }
};
