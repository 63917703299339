import React, { useEffect, useState } from 'react';
import { Avatar, Box, Chip, makeStyles } from '@material-ui/core';
import axios from 'axios';
import { getUrl, getConfig } from '../../redux/redux-helpers';
import moment from 'moment';
import SortIcon from '@material-ui/icons/Sort';


const useStyles = makeStyles((theme) => ({
  table: {
    // backgroundColor: 'rgb(202, 202, 202)',
    width: '100%',

    '& th': {
      backgroundColor: 'rgb(222, 222, 222)',
      color: 'rgb(44, 44, 44)',
      cursor: 'pointer',
      fontSize: 16,
      padding: '.5rem 1rem',
      textAlign: 'left',
    },

    '& td': {
      backgroundColor: 'white',
      borderBottom: '1px dashed rgb(202, 202, 202)',
      color: 'rgb(22, 22, 22)',
      fontSize: 16,
      padding: '.5rem 1rem',

      '& span': {
        display: 'block',
        minHeight: 40,
      },
    },
  },
  avatar: {
    '& .MuiAvatar-img': {
      // height: 30,
      // width: 30,
    },
  },
}));

const fetchUsers = (page = 0, limit = 50) => {
  return axios.get(getUrl(`admin/users?page=${page}&limit=${limit}`), getConfig());
};

export const ListUsers = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState('created_at');
  const [total, setTotal] = useState(0);
  const [users, setUsers] = useState([]);
  const [sortedUsers, setSortedUsers] = useState([]);

  useEffect(() => {
    // console.log('');
    // console.log('%c useEffect()', 'color:yellow', '[page]');
    // console.log('%c page', 'color:yellow', page);

    fetchUsers(page).then(response => {
      // console.log('');
      // console.log('%c GET()', 'color:lime');
      // console.log('%c response', 'color:lime', response);

      setTotal(response.data.total);
      setUsers(prevState => {
        const newState = [ ...prevState ];
        response.data.users?.forEach(user => {
          const found = newState.find(item => item.user_id === user.user_id);
          if (!found) newState.push({
            ...user,
            client_id: Array.isArray(user.app_metadata.client_id) ? user.app_metadata.client_id.join( ', ') : user.app_metadata.client_id,
            created_at: new Date(user.created_at),
            created_at_formatted: moment(user.created_at).format('lll'),
            last_login: user.last_login ? new Date(user.last_login) : null,
            last_login_formatted: user.last_login ? moment(user.last_login).format('lll') : null,
            updated_at: new Date(user.updated_at),
            updated_at_formatted: moment(user.updated_at).format('lll'),
          });
        });

        // newState.sort((a, b) => b.created_at - a.created_at);

        return newState;
      });
    }).catch((error) => {
      console.log('');
      console.log('%c error', 'color:orangered', error);
    });
  }, [page])

  // useEffect(() => {
  //   console.log('');
  //   console.log('%c useEffect()', 'color:lime', '[users]');
  //   console.log('%c users', 'color:lime', users);
  // }, [users]);

  useEffect(() => {
    // console.log('');
    // console.log('%c useEffect()', 'color:yellow', '[total, users]');
    // console.log('%c total', 'color:yellow', total);
    // console.log('%c users', 'color:yellow', users);

    if (users.length < total) setPage(prevState => prevState + 1);
  }, [total, users]);

  useEffect(() => {
    // console.log('');
    // console.log('%c useEffect()', 'color:lime', '[sortBy]');
    // console.log('%c sortBy', 'color:lime', sortBy);

    const newUsers = [ ...users ];

    switch (sortBy) {
      case 'client_id':
        newUsers.sort((a, b) => a.client_id - b.client_id);
        break;

      case 'family_name':
        newUsers.sort((a, b) => {
          const A = a.given_name ?? 'ZZZZZ';
          const B = b.given_name ?? 'ZZZZZ';
          return A?.localeCompare(B);
        });
        newUsers.sort((a, b) => {
          const A = a.family_name ?? 'ZZZZZ';
          const B = b.family_name ?? 'ZZZZZ';
          return A?.localeCompare(B);
        });
        break;

        case 'given_name':
        newUsers.sort((a, b) => {
          const A = a.family_name ?? 'ZZZZZ';
          const B = b.family_name ?? 'ZZZZZ';
          return A?.localeCompare(B);
        });
        newUsers.sort((a, b) => {
          const A = a.given_name ?? 'ZZZZZ';
          const B = b.given_name ?? 'ZZZZZ';
          return A?.localeCompare(B);
        });
        break;

      case 'email':
        newUsers.sort((a, b) => a.email?.localeCompare(b.email));
        break;

      case 'last_login':
        newUsers.sort((a, b) => b.last_login - a.last_login);
        break;

      case 'created_at':
        newUsers.sort((a, b) => b.created_at - a.created_at);
        break;

      default:
        break;
    }

    setSortedUsers(newUsers);
  }, [sortBy, users]);

  // useEffect(() => {
  //   console.log('');
  //   console.log('%c useEffect()', 'color:lime', '[sortedUsers]');
  //   console.log('%c sortedUsers', 'color:lime', sortedUsers);
  // }, [sortedUsers]);

  const handleSortClick = (sort) => {
    // console.log('');
    // console.log('%c handleSortClick()', 'color:yellow');
    // console.log('%c sort', 'color:yellow', sort);
    setSortBy(sort);
  };

  const blankRows = [];
  blankRows.length = Math.max(10 - users.length, 0);
  blankRows.fill('');

  return (
    <Box>
      <table border={0} cellPadding={0} cellSpacing={0} className={classes.table}>
        <thead>
          <tr>
            <th width={50}></th>
            <th width="15%" onClick={() => handleSortClick('client_id')} style={{ maxWidth: '15%' }}>
              <SortHeader label="Client ID" active={sortBy === 'client_id'} />
            </th>
            <th width="15%" onClick={() => handleSortClick('given_name')}>
              <SortHeader label="First Name" active={sortBy === 'given_name'} />
            </th>
            <th width="15%" onClick={() => handleSortClick('family_name')}>
              <SortHeader label="Last Name" active={sortBy === 'family_name'} />
            </th>
            <th onClick={() => handleSortClick('email')}>
              <SortHeader label="Email" active={sortBy === 'email'} />
            </th>
            <th width="15%" onClick={() => handleSortClick('last_login')}>
              <SortHeader label="Last Login" active={sortBy === 'last_login'} />
            </th>
            <th width="15%" onClick={() => handleSortClick('created_at')}>
              <SortHeader label="Created" active={sortBy === 'created_at'} />
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedUsers?.map((user) => (
            <tr key={user.user_id}>
              <td><Avatar src={user.picture} alt="gravatar" className={classes.avatar} /></td>
              <td>{user.client_id}</td>
              <td>{user.given_name}</td>
              <td>{user.family_name}</td>
              <td>{user.email}</td>
              <td>{user.last_login_formatted}</td>
              <td>{user.created_at_formatted}</td>
            </tr>
          ))}
          {blankRows?.map((row, index) => (
            <tr key={`blank-${index}`}>
              <td colSpan={7}><span>&nbsp;</span></td>
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
};


const SortHeader = ({ label = 'Hi', active}) => {
  return (
    <Box display="flex" justifyContent="flex-start" alignItems="center" style={{ whiteSpace: 'nowrap' }}>
      {label}
      {active && (
        <Chip icon={<SortIcon />} />
      )}
      {active && (
        <Chip />
      )}
    </Box>
  );
};
