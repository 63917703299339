import React from 'react';
import { fixedNum } from '../../../redux/services/assets-service';
import SideInfoWrapper from '../../shared/side-info-wrapper';
import { SideChartData } from '../../shared/side-chart-data';
import { formatMoney } from '../../../utils/misc';
import mainStyle from '../../../styles/mainStyle';


const SideChart = ({ data, filter, listLimit = 0 }) => {
  // console.log('');
  // console.log('%c assets/SideChart()', 'color:red');
  // console.log('%c data', 'color:red', data);

  const sectionTitle = `Investment Portfolio`;

  // if (!data || !filter) return ( <div>Loading...</div> );

  const limit = listLimit ? Math.min(data.length, listLimit) : data.length;
  const dataTitle = listLimit && limit ? `Top ${limit} Accounts (${filter.title})` : `Accounts (${filter.title})`;

  const dataItems = data.slice(0, limit).map((item, idx) => (
    <SideChartData
      idx={idx}
      key={idx}
      title={item.name}
      amount={`${formatMoney(item.total, 0)} (${item.percent ? fixedNum(item.percent, 0) : 0}%)`}
      value={item.percent}
      color={mainStyle.blueShade4} />
  ));

  return (
    <SideInfoWrapper
      title={sectionTitle}
      dataTitle={dataTitle}
      data={dataItems}
    />
  );
}

export default SideChart;
