import React from "react";
import ColorLinearProgress from "./color-linear-progress";
import mainStyle from "../../styles/mainStyle";

export const SideChartData = (props) => {
  // console.log('');
  // console.log('%c SideChartData()', 'color:cyan');
  // console.log('%c props', 'color:cyan', props);

  const { title, amount, value, color, idx } = props;

  return (
    <div key={idx} style={{ marginBottom: "35px"}}>
      <div
        className="flex-sb-ab"
        style={{ fontSize: "16px", color: mainStyle.blueShade1, marginBottom: "3px", }}>
        <div>{title}</div>
        <div style={{whiteSpace: 'nowrap'}}>{amount}</div>
      </div>
      <div>
        <ColorLinearProgress
          barColor={color}
          value={+value}
          backgroundColor="#cdcfd3"
          height={6} />
      </div>
    </div>
  );
};
