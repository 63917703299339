import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles({
  root: {
    borderRadius: '2em',
    // padding: '.5em 1.5em',
    whiteSpace: 'nowrap',
  },
});

function RoundButton(props) {
  // console.log('');
  // console.log('%c RoundButton()', 'color:magenta');
  // console.log('%c props', 'color:magenta', props);
  // console.log('%c useStyles', 'color:magenta', useStyles);

  const { variant = 'contained', ...other } = props;
  const classes = useStyles(props);
  // console.log('%c classes', 'color:magenta', classes);

  return <Button className={classes.root} variant={variant} {...other} />;
}

export default RoundButton;
