import React, { useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { parseChartData } from '../../../redux/services/assets-service';
import MainChart from './chart-main';
import SideChart from './chart-side';
import ChartInfo from '../../shared/big-chart-info';
import { elementStyles } from '../../../styles/mainStyle';
import { useSelector } from 'react-redux';


const MainChartRow = () => {
  // console.log('');
  // console.log('%c assets/MainChartRow()', 'color:violet');

  const assets = useSelector(state => state.assets.data.assets);
  const [filterValue, setFilterValue] = useState('months:6');
  const chartData = parseChartData(assets, filterValue);
  // const chartData = useMemo(() => parseChartData(assets, filterValue), [assets, filterValue]); // going to fire * because of assets?

  if (!chartData) return (
    <Box minHeight={450} p={3} style={{ backgroundColor: 'white'}}>
      <em>No Data Available</em>
    </Box>
  );

  // console.log('%c assets', 'color:violet', JSON.parse(JSON.stringify(assets)));
  // console.log('%c filterValue', 'color:violet', filterValue);
  // console.log('%c chartData', 'color:violet', JSON.parse(JSON.stringify(chartData)));

  return (
    <Grid container direction="row" justify="space-between" alignItems="stretch">
      <Grid item md={9} xs={12} style={elementStyles.column} id="asset-wrapper">
        <ChartInfo
          title={`Asset Value`}
          subtitle={`(${chartData.filter.title})`}
          total={chartData.summary.total}
          changeValue={chartData.summary.change}
          changePercent={chartData.summary.changePercent}
          changeTitle={chartData.summary.changeTitle}
          filterOptions={chartData.filterOptions}
          filterValue={filterValue}
          onFilterChange={setFilterValue} />
        <MainChart
          data={chartData} />
      </Grid>
      <Grid item md={3} xs={12} style={elementStyles.sideColumn}>
        <SideChart
          data={chartData.portfolio}
          filter={chartData.filter} />
      </Grid>
    </Grid>
  );
};

export default MainChartRow;
