import React, { useEffect } from 'react';
import Layout from '../components/layout/layout';
import Header from '../components/policy-pages/header/header';
import RelatedLinksContainer from '../components/shared/related-links-container';
import TriplePointForm from '../components/shared/triple-point-form';
import { portalForms } from '../utils/constants';

export default function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <div style={{ background: 'white' }}>
        <Header title="Privacy Policy" />
        <RelatedLinksContainer title="Privacy Policy">
          <p>This privacy policy discloses the privacy practices for Sterling Foundation Managment (<a href="//www.sterlingfoundations.com" target="_blank" rel="noreferrer">www.sterlingfoundations.com</a>) and applies solely to information collected by this web site. </p>

          <h4>Information Collection, Use, and Sharing</h4>
          <p>Sterling Foundation Management is the sole owner of information collected on this site. We only have access to/collect information that you voluntarily give us via email or other direct contact from you. We will not sell or rent this information to anyone.</p>

          <p>We will use your information to respond to you, regarding the reason you contacted us. We will not share your information with any third party outside of our organization, other than as necessary to fulfill your request or as indicated by you.</p>

          <p>Unless you ask us not to, we may contact you via email from time to time to update you about Sterling and its services.</p>

          <h4>Your Access to and Control Over Information</h4>

          <p>You may opt out of any future contacts from us at any time. You can do the following at any time by contacting us via the email address or phone number given on our website:</p>

          <ul>
            <li>See what data we have about you, if any.</li>
            <li>Change/correct any data we have about you.</li>
            <li>Have us delete any data we have about you.</li>
            <li>Express any concern you have about our use of your data.</li>
          </ul>

          <h4>Security</h4>

          <p>We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline.</p>

          <p>Wherever we collect sensitive information, that information is transmitted to us in a secure way. We also protect your information offline. Only employees who need the information to perform a specific job are granted access to personally identifiable information. The computers/servers in which we store personally identifiable information are kept in a secure environment.</p>

          <h4>Updates</h4>

          <p>Our Privacy Policy may change from time to time and all updates will be posted on this page. Please <TriplePointForm title={portalForms.contactUs.title} url={portalForms.contactUs.url}><span className="faux-link">Contact Us</span></TriplePointForm> if you have any questions or would like additional information.</p>
        </RelatedLinksContainer>
      </div>
    </Layout>
  );
}
