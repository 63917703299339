import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function useGetFiles(name) {
  // console.log('');
  // console.log('%c useGetFiles()', 'color:lime');

  const isLoading = useSelector(state => state.files.loading);
  const storeData = useSelector((state) => state.files.data);

  // const defaulFiles = () => {
  //   if (!storeData) return null;
  //   console.log('%c CALL_GET_IN_DEFAULT', 'color:lime');
  //   const filesTree = storeData.filesTree.get(name);
  //   return filesTree;
  // };
  // const [files, setFiles] = useState(defaulFiles());
  const [files, setFiles] = useState(null);

  useEffect(() => {
    // console.log('');
    // console.log(`%c useGetFiles()EFFECT`, 'color:lime');
    // console.log('%c name', 'color:lime', name);
    // console.log('%c storeData', 'color:lime', storeData);
    if (!storeData) return;
    const filesTree = storeData.filesTree.get(name);
    setFiles(filesTree);
  }, [name, storeData]);

  return [ files, isLoading ];
}

export default useGetFiles;
