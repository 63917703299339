import React from 'react';
import Layout from '../components/layout/layout';
import Header from '../components/contributions/header/header';
import Charts from '../components/contributions/charts';


const Contributions = () => {
  // console.log('');
  // console.log('%c Contributions()', 'color:skyblue');

  return (
    <Layout>
      <Header />
      <Charts />
    </Layout>
  );
};

export default Contributions;
