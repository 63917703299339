import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';


const ENV_LOGOUT_URI = process.env.REACT_APP_AUTH0_LOGOUT_URI;

const Logout = ({className}) => {
  const { logout } = useAuth0();

  return (
    <span className={className} onClick={() => logout({ returnTo: ENV_LOGOUT_URI })}>
      Log out
    </span>
  );
};

export default Logout;
