import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ShowHide from './show-hide';
import logo from '../../images/logo.svg';
import '../../styles/full-screen-loader.scss';
import { CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';


const FullScreenLoader = ({ hideLoader, children }) => {

  const hasData = useSelector(state => !!state.assets.data && !!state.client.data && !!state.contributions.data && !!state.disbursements.data && !!state.files.data);
  const isLoading = useSelector(state => state.app.loading || state.client.loading || state.assets.loading || state.contributions.loading || state.disbursements.loading || state.files.loading);

  const LoadingScreen = (
    <div className={`full-screen-loader`}>
      <div>
        <img src={logo} alt="Sterling Foundations logo" width="300" />
      </div>
      <CircularProgress style={{ color: hasData || isLoading ? '#2BE3EA' : '#f74a21' }} />
      <div style={{ color: 'white', fontSize: 16, height: 78, paddingTop: 16 }}>
        {!isLoading && !hasData && (
          <em>No data found for this account.</em>
        )}
      </div>
    </div>
  );

  return (
    <Fragment>
      <ShowHide show={hideLoader} placeholder={LoadingScreen}>
        <Fragment>
          {children}
        </Fragment>
      </ShowHide>
    </Fragment>
  );
}

export default FullScreenLoader;

FullScreenLoader.propTypes = {
  hideLoader: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};
