import React, { Fragment } from 'react';
import { pSBC, formatMoney } from '../../../utils/misc';
import mainStyle from '../../../styles/mainStyle';
import { VictoryPie, VictoryLabel, VictoryTooltip } from 'victory';


const DisbursementsChart = ({ summary }) => {
  const hasData = !!summary;
  if (!hasData) return ( <div>Loading...</div> );

  const {ytdAverage, ytdMaxValue} = summary;
  // console.log('%c summary.ytdValues', 'color:hotpink', summary.ytdValues);

  const disbursements = Array.isArray(summary.ytdValues) ? summary.ytdValues.map(v => ({ amount: v.amount, recipient: v.recipient.name })) : [];
  // console.log('%c disbursements', 'color:hotpink', disbursements);

  const getColorScale = () => {
    return disbursements.map((g, i) => {
      return pSBC(
        ((100 / disbursements.length) * (i + 1)) / 100,
        mainStyle.blueShade1,
        mainStyle.blueShadeAlt
      );
    });
  };
  const colorScale = getColorScale();

  return (
    <Fragment>
      <svg viewBox="0 0 350 350">
        <VictoryPie
          standalone={false}
          colorScale={colorScale}
          width={350}
          height={350}
          data={disbursements.map((disbursement, i) => ({
            x: i + 1,
            y: disbursement.amount,
            label: disbursement.recipient + '\n$' + disbursement.amount,
            fill: colorScale[i],
          }))}
          innerRadius={75}
          labelComponent={<CustomLabel />}
          style={{
            labels: { display: 'none' },
            data: {
              fillOpacity: 0.9,
              stroke: '#fff',
              strokeWidth: 1
            }
          }}
        />
        <VictoryLabel
          textAnchor="middle"
          style={{
            fontSize: 22,
            fontWeight: 700,
            fill: mainStyle.blueShade1,
            lineHeight: '1000px'
          }}
          x={350 / 2}
          y={350 / 2}
          text={`\n${disbursements.length}\n\n\n`}
        />
        <VictoryLabel
          textAnchor="middle"
          style={{ fontSize: 15, fontWeight: 800, fill: mainStyle.blueShade1 }}
          x={350 / 2}
          y={350 / 2}
          text={`\nGrants\nAwarded`}
        />
      </svg>
      <div className="legend mb-2">
        <div className="blue-s1 t-center">
          Largest Grant Amount: <b>{formatMoney(ytdMaxValue, 0)}</b>
        </div>
        <div className="blue-s1 t-center">
          Average Grant Amount: <b>{formatMoney(ytdAverage, 0)}</b>
        </div>
      </div>
    </Fragment>
  );
};

const CustomLabel = (props) => {
  return (
    <VictoryTooltip
      {...props}
      constrainToVisibleArea
      style={{ fill: mainStyle.white, fontSize: 16 }}
      flyoutStyle={{ fill: props.datum.fill, stroke: props.datum.fill }}
      flyoutPadding={10}
      renderInPortal={false}
    />
  );
}
CustomLabel.defaultEvents = VictoryTooltip.defaultEvents;

export default DisbursementsChart;
