import React, { Fragment } from 'react';
import { formatMoney } from '../../../utils/misc';
import mainStyle from '../../../styles/mainStyle';
import { VictoryPie } from 'victory';
import StopIcon from '@material-ui/icons/Stop';

const AssetsChart = (props) => {
  const { total, cash, investments } = props;

  return (
    <Fragment>
      <VictoryPie
        height={375}
        colorScale={[mainStyle.blueShadeAlt, mainStyle.blueShade2]}
        innerRadius={3}
        style={{
          labels: {
            fill: 'white',
            fontSize: '18px',
            textAlign: 'center',
            fontWeight: 800
          }
        }}
        labelRadius={45}
        labels={({ datum }) => {
          const val = Math.round((parseInt(datum.y) / total) * 100);
          return val > 9 ? `${val.toString()}%` : '';
        }}
        data={[
          { x: 1, y: cash },
          { x: 2, y: investments }
        ]}
      />
      <div className="legend mb-2">
        <div className="flex-c-ac f-700 blue-s1">
          <StopIcon style={{ color: mainStyle.blueShade2 }} />{' '}
          <i>Investments {formatMoney(investments, 0)}</i>
        </div>
        <div className="flex-c-ac f-700 blue-s1">
          <StopIcon style={{ color: mainStyle.blueShadeAlt }} />{' '}
          <i>Cash {formatMoney(cash, 0)}</i>
        </div>
      </div>
    </Fragment>
  );
};

export default AssetsChart;
