import { clearAssetPage } from './assets-actions';
import { clearContributionsPage } from './contribution-actions';
import { clearClient } from './client-actions';
import { clearDisbursementsPage } from './disbursement-actions';
import { clearFilesPage } from './files-actions';


export const clearData = () => dispatch => {
  dispatch(clearAssetPage());
  dispatch(clearClient());
  dispatch(clearContributionsPage());
  dispatch(clearFilesPage());
  dispatch(clearDisbursementsPage());
}

export default clearData;
