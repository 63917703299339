import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import ForwardIcon from '@material-ui/icons/PlayArrow';
import HeaderWrapper from '../../shared/header-wrapper';
import RoundButton from '../../shared/round-button';
import TriplePointForm from '../../shared/triple-point-form';
import { portalForms } from '../../../utils/constants';


const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    fontStyle: 'italic',
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '1.2em',
    padding: '.25rem .5rem',
    textAlign: 'left',
    textTransform: 'none',
    textDecoration: 'none'
  },
});

function Header() {
  const documents = useSelector((state) => state.files.data);
  const updatedDocuments = documents?.documentHeaderFiles || [];
  const classes = useStyles();

  const data = [
    {
      title: 'Missing Documents?',
      component: (
        <TriplePointForm title={portalForms.contactUs.title} url={portalForms.contactUs.url}>
          <RoundButton color="primary">{portalForms.contactUs.title}</RoundButton>
        </TriplePointForm>
      )
    }
  ];

  if (updatedDocuments.length) {
    data.push({
      title: 'Recently Updated Documents',
      component: (
        <Fragment>
          {updatedDocuments.map((file, index) => (
            <a key={index} href={file.meta.uri} className={classes.root} target="_blank" rel="noreferrer noopener">
              <ForwardIcon style={{ fill: 'white', height: '1rem', marginRight: '.5rem', width: '1rem', }} />
              {file.title}
            </a>
          ))}
        </Fragment>
      )
    });
  }

  return <HeaderWrapper title="Documents" noDate data={data} />;
}

export default Header;
