import React from 'react';
import HeaderWrapper from '../../shared/header-wrapper';
import TriplePointForm from '../../shared/triple-point-form';
import { portalForms } from '../../../utils/constants';
import RoundButton from '../../shared/round-button';

const data = [
  {
    title: 'Need More Info?',
    component: (
      <TriplePointForm title={portalForms.contactUs.title} url={portalForms.contactUs.url}>
        <RoundButton color="primary">{portalForms.contactUs.title}</RoundButton>
      </TriplePointForm>
    )
  }
];

function Header({ asOf }) {
  // console.log('');
  // console.log('%c summary/Header()', 'color:cyan');
  // console.log('%c asOf', 'color:cyan', asOf);

  return (
    <HeaderWrapper
      noPadding
      title="SUMMARY"
      data={data} />
  );
}

export default Header;
