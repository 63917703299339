import axios from 'axios';
import { getUrl, getConfig } from '../redux-helpers';
import { parseClientData } from '../services/client-service';

export const clientTypes = {
  GET_CLIENT: 'GET_CLIENT',
  CLIENT_LOADING: 'CLIENT_LOADING',
  CLIENT_ERROR: 'CLIENT_ERROR'
};

export const getClient = () => (dispatch) => {
  dispatch(setClientLoading());

  axios
    .get(getUrl('client'), getConfig())
    .then((res) => {
      dispatch({
        type: clientTypes.GET_CLIENT,
        payload: parseClientData(res.data),
        status: res.status
      });
    })
    .catch((error) => {
      dispatch({
        type: clientTypes.CLIENT_ERROR,
        message: error.response?.data.message,
        status: error.response?.status
      });
    });
};

export const clearClient = () => (dispatch) => {
  dispatch({
    type: clientTypes.GET_CLIENT,
    payload: null,
  });
};

export const setClientLoading = () => ({
  type: clientTypes.CLIENT_LOADING
});
