
import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Fade } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { AuthLoading } from './AuthLoading';
import { useLocation } from 'react-router-dom';


export const Auth0Login = () => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const location = useLocation();

  // console.log('');
  // console.log('%c LoginButton()', 'color:yellow');
  // console.log('%c location.pathname', 'color:yellow', location.pathname);
  // console.log('%c isAuthenticated', 'color:yellow', isAuthenticated);
  // console.log('%c isLoading', 'color:yellow', isLoading);

  const auto = location.pathname === '/login';
  // console.log('%c auto', 'color:orange', auto);

  if (auto && !isAuthenticated && !isLoading) {
    loginWithRedirect();
  }

  if (isAuthenticated) {
    return (
      <Redirect to="/" />
    )
  }

  if (isLoading) {
    return (
      <AuthLoading />
    )
  }

  return (
    <LoginButtons fade={auto} />
  );
};

const LoginButtons = ({ fade = true }) => {
  const { loginWithRedirect } = useAuth0();
  const [displayLogin, setDisplayLogin] = useState(!fade);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayLogin(true);
    }, 1111);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box display="flex" alignItems="center" justifyContent="center" height="100vh" width="100vw">
      <Fade in={displayLogin} timeout={1111}>
        <Box display="inline-flex" flexDirection="column" alignItems="center" justifyContent="center" paddingTop={4} paddingRight={6} paddingBottom={4} paddingLeft={6}>
          <img src="https://www.sterlingfoundations.com/vendor/_accounts/sterling_foundation/images/logo.svg" alt="Sterling Foundations" width="300" />
          <Button color="primary" variant="contained" onClick={() => loginWithRedirect()} style={{ borderRadius: '2rem', marginTop: '1rem' }}>Log In</Button>
        </Box>
      </Fade>
    </Box>
  );
};
