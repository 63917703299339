import { CsvBuilder } from 'filefy';
import mainStyle from '../../styles/mainStyle';
import stateHash from './json/state-hash.json';
import { getBuckets, getFilter, getFilteredData, getFilteredValues, getFilterOptions, fixedNum, parseAsMonthYearSummaries } from './service-helpers';
import { shortMonths } from '../../utils/constants';
import { formatFilename } from '../../utils/misc';


export const parseContributions = (data) => {
  // console.log('');
  // console.log('%c parseContributions()', 'color:fuchsia');
  // console.log('%c data', 'color:fuchsia', data);

  if (data.length === 0) {
    return {
      contributions: [],
      summary: {
        asOf: new Date(),
        summaryAt: new Date(),

        changeOverYearCount: 0,
        changeOverYearCountPercent: 0,
        changeOverYearTotal: 0,
        changeOverYearTotalPercent: 0,

        changeOverYtdCount: 0,
        changeOverYtdCountPercent: 0,
        changeOverYtdTotal: 0,
        changeOverYtdTotalPercent: 0,

        year: new Date().getFullYear(),
        yearAverage: 0,
        yearCount: 0,
        yearTotal: 0,

        ytdAverage: 0,
        ytdCount: 0,
        ytdTotal: 0,
        ytdMaxValue: 0,
        ytdMinValue: 0,

        previousYear: (new Date().getFullYear() - 1),
        previousYearAverage: 0,
        previousYearCount: 0,
        previousYearTotal: 0,

        previousYtdAverage: 0,
        previousYtdCount: 0,
        previousYtdTotal: 0
      },
      values: []
    };
  }

  const values = data?.map(v => {
    const date = new Date(v.donation_date);
    return {
      // ...v,
      amount: +v.amount,
      donationType: v.donation_type,
      donorType: v.donor_type,
      name: v.donor_name,
      country: v.donor_country,
      address: v.donor_address,
      city: v.donor_city,
      zip: v.donor_zip,
      stateAbbr: stateHash[v.donor_state] ? v.donor_state : null,
      stateTitle: stateHash[v.donor_state] || 'Other',
      at: date,
      month: date.getMonth(),
      year: date.getFullYear(),
      purpose: v.purpose
    };
  }).sort((a, b) => b.month - a.month).sort((a, b) => b.year - a.year);
  // console.log('%c values', 'color:fuchsia', values);

  const contributions = parseAsMonthYearSummaries(values, 'contributions');
  // console.log('%c contributions', 'color:fuchsia', contributions);

  const summary = parseSummary(values, contributions);
  // console.log('%c summary', 'color:fuchsia', summary);

  return {
    contributions,
    summary,
    values
  };
};

export const parseChartData = (data, filterValue) => {
  // console.log('');
  // console.log('%c parseChartData()', 'color:hotpink');
  // console.log('%c data', 'color:hotpink', data);
  // console.log('%c filterValue', 'color:hotpink', filterValue);

  if (!data?.length) return;

  const filterOptions = getFilterOptions(data);
  const filter = filterOptions.find(v => v.value === filterValue);
  const filteredData = getFilteredData(data, filter);

  // console.log('%c filterOptions', 'color:hotpink', filterOptions);
  // console.log('%c filter', 'color:hotpink', filter);
  // console.log('%c filteredData', 'color:hotpink', JSON.parse(JSON.stringify(filteredData)));

  const result = filteredData.map(v => ({ id: `${shortMonths[v.month]} ${v.year}`, label: `${shortMonths[v.month]}`, total: v.total, count: v.count, month: v.month, year: v.year })).reverse();
  // console.log('%c result', 'color:cyan', result);

  const summary = {
    total: filteredData.reduce((acc, item) => fixedNum(acc + item.total), 0),
    count: filteredData.reduce((acc, item) => fixedNum(acc + item.count), 0),
    change: filter.value === 'months:6' ? filteredData[0].changeOverSixMonthsTotal : filteredData[0].changeOverYearTotal,
    changePercent: filter.value === 'months:6' ? filteredData[0].changeOverSixMonthsTotalPercent : filteredData[0].changeOverYearTotalPercent,
    changeCount: filter.value === 'months:6' ? filteredData[0].changeOverSixMonthsCount : filteredData[0].changeOverYearCount,
    changeCountPercent: filter.value === 'months:6' ? filteredData[0].changeOverSixMonthsCountPercent : filteredData[0].changeOverYearCountPercent,
    changeTitle: filter.value === 'months:6' ? `Versus Previous 6 Months` : filter.type === 'months' ? `${filter.count} Month Change` : filter.type === 'year' ? 'YoY Change' : null
  }
  summary.average = fixedNum(summary.total / summary.count);

  // console.log('%c result', 'color:hotpink', result);
  // console.log('%c summary', 'color:hotpink', summary);

  const labelsCategories = ['Contributions'];
  const legend = [
    { title: 'Total Contributions', amount: summary.count },
    { title: 'Average Contribution', amount: summary.average, format: 'money' }
  ];
  const series = [
    { name: labelsCategories[0], data: filteredData.map(v => ({ x: shortMonths[v.month], y: v.total, z: v.count })).reverse() }
  ];
  // console.log('%c legend', 'color:hotpink', legend);
  // console.log('%c series', 'color:hotpink', series);

  const recipients = parseRecipients(filteredData);
  // console.log('%c recipients', 'color:hotpink', recipients);

  return {
    data: result,
    filter,
    filterOptions,
    legend,
    recipients,
    series,
    summary
  };
};

export const parseStateChartData = (data, filterValue) => {
  // console.log('');
  // console.log('%c parseStateChartData()', 'color:hotpink');
  // console.log('%c data', 'color:hotpink', data);
  // console.log('%c filterValue', 'color:hotpink', filterValue);

  if (!data?.length) return;

  const filterOptions = getFilterOptions(data);
  const filter = filterOptions.find(v => v.value === filterValue);
  const filteredData = getFilteredData(data, filter);

  // console.log('%c filterOptions', 'color:hotpink', filterOptions);
  // console.log('%c filter', 'color:hotpink', filter);
  // console.log('%c filteredData', 'color:hotpink', filteredData);

  const result = [];

  for (const range of filteredData) {
    for (const item of range.values) {
      // country is not accurate: && v.country === item.country
      const existing = result.find(v => v.stateTitle === item.stateTitle);
      if (existing) {
        existing.total = fixedNum(existing.total + item.amount);
        existing.count += 1;
      } else {
        result.push({
          stateAbbr: item.stateAbbr,
          stateTitle: item.stateTitle,
          country: item.country,
          total: item.amount,
          count: 1
        });
      }
    }
  }

  const rangeTotal = fixedNum(result.reduce((accumulator, item) => accumulator + item.total, 0));
  for (const item of result) {
    item.percent = fixedNum((item.total / rangeTotal) * 100);
  }
  result.sort((a, b) => a.stateTitle.localeCompare(b.stateTitle));
  // console.log('%c result', 'color:hotpink', result);

  const summary = {
    total: result.reduce((acc, item) => fixedNum(acc + item.total), 0),
    count: result.reduce((acc, item) => fixedNum(acc + item.count), 0),
    change: filter.value === 'months:6' ? filteredData[0].changeOverSixMonthsTotal : filteredData[0].changeOverYearTotal,
    changePercent: filter.value === 'months:6' ? filteredData[0].changeOverSixMonthsTotalPercent : filteredData[0].changeOverYearTotalPercent,
    changeCount: filter.value === 'months:6' ? filteredData[0].changeOverSixMonthsCount : filteredData[0].changeOverYearCount,
    changeCountPercent: filter.value === 'months:6' ? filteredData[0].changeOverSixMonthsCountPercent : filteredData[0].changeOverYearCountPercent,
    changeTitle: filter.value === 'months:6' ? `Versus Previous 6 Months` : filter.type === 'months' ? `${filter.count} Month Change` : filter.type === 'year' ? 'YoY Change' : null
  }
  summary.average = fixedNum(summary.total / summary.count);
  // console.log('%c summary', 'color:hotpink', summary);

  const buckets = getBuckets(result, 'count');
  // console.log('%c buckets', 'color:hotpink', buckets);

  return {
    data: result,
    filter,
    filterOptions,
    summary,
    buckets
  };
};

const parseRecipients = (data) => {
  const result = [];

  // TODO: include donationType of Grant?

  for (const range of data) {
    for (const item of range.values) {
      const existing = result.find(v => v.name === item.name && v.donationType === item.donationType && v.donorType === item.donorType && v.stateTitle === item.stateTitle);
      if (existing) {
        existing.total = fixedNum(existing.total + item.amount);
        existing.count += 1;
      } else {
        result.push({
          name: item.name,
          total: item.amount,
          donationType: item.donationType,
          donorType: item.donorType,
          stateAbbr: item.stateAbbr,
          stateTitle: item.stateTitle,
          count: 1
        });
      }
    }
  }
  result.sort((a, b) => b.total - a.total);

  const rangeTotal = fixedNum(result.reduce((accumulator, item) => accumulator + item.total, 0));
  for (const recipient of result) {
    recipient.percent = fixedNum((recipient.total / rangeTotal) * 100);
  }

  return result;
};

export const parseSummary = (values, contributions) => {
  // console.log('');
  // console.log('%c contributionService:parseSummary()', 'color:hotpink');
  // console.log('%c values', 'color:hotpink', values);
  // console.log('%c contributions', 'color:hotpink', contributions);

  const maxDate = new Date(Math.max(...values.map(v => v.at)));
  // console.log('%c maxDate', 'color:hotpink', maxDate);

  const currentDate = new Date();
  // console.log('%c currentDate', 'color:hotpink', currentDate);

  const currentData = contributions.find(v => v.year === currentDate.getFullYear() && v.month === currentDate.getMonth());
  // console.log('%c currentData', 'color:hotpink', currentData);

  return {
    asOf: maxDate,
    summaryAt: currentDate,

    changeOverYearCount: currentData.changeOverYearCount,
    changeOverYearCountPercent: currentData.changeOverYearCountPercent,
    changeOverYearTotal: currentData.changeOverYearTotal,
    changeOverYearTotalPercent: currentData.changeOverYearTotalPercent,

    changeOverYtdCount: currentData.changeOverYtdCount,
    changeOverYtdCountPercent: currentData.changeOverYtdCountPercent,
    changeOverYtdTotal: currentData.changeOverYtdTotal,
    changeOverYtdTotalPercent: currentData.changeOverYtdTotalPercent,

    year: currentData.year,
    yearAverage: currentData.yearAverage,
    yearCount: currentData.yearCount,
    yearTotal: currentData.yearTotal,

    ytdAverage: currentData.ytdAverage,
    ytdCount: currentData.ytdCount,
    ytdTotal: currentData.ytdTotal,
    ytdMaxValue: currentData.ytdMaxValue,
    ytdMinValue: currentData.ytdMinValue,

    previousYear: currentData.previousYear,
    previousYearAverage: currentData.previousYearAverage,
    previousYearCount: currentData.previousYearCount,
    previousYearTotal: currentData.previousYearTotal,

    previousYtdAverage: currentData.previousYtdAverage,
    previousYtdCount: currentData.previousYtdCount,
    previousYtdTotal: currentData.previousYtdTotal
  };
}

export const downloadCsv = (values, filter, prefix) => {
  // console.log('');
  // console.log('%c downloadCsv()', 'color:orangered');
  // console.log('%c values', 'color:orangered', values);
  // console.log('%c filter', 'color:orangered', filter);
  // console.log('%c prefix', 'color:orangered', prefix);

  const filename = formatFilename(`${prefix}_${filter === 'all' ? 'all' : filter.title}`, 'csv');
  // console.log('%c filename', 'color:orangered', filename);

  const filteredValues = filter === 'all' ? [...values ] : getFilteredValues(values, filter);
  // console.log('%c filteredValues', 'color:orangered', filteredValues);

  const transformedValues = filteredValues.map(v => ({
    amount: v.amount,
    donation_date: v.at,
    donation_type: v.donationType,
    donor_type: v.donorType,
    donor_name: v.name,
    donor_address: v.address,
    donor_city: v.city,
    donor_state: v.stateTitle,
    donor_state_abbr: v.stateAbbr,
    donor_zip: v.zip,
    donor_country: v.country,
    purpose: v.purpose
  }));
  // console.log('%c transformedValues', 'color:orangered', transformedValues);

  // const columns = Object.keys(transformedValues[0]);
  const columns = ['amount', 'donation_date', 'donation_type', 'donor_type', 'donor_name', 'donor_address', 'donor_city', 'donor_state', 'donor_state_abbr', 'donor_zip', 'donor_country', 'purpose'];
  // console.log('%c columns', 'color:orangered', columns);

  const rows = transformedValues.map(v => Object.values(v));
  // console.log('%c rows', 'color:orangered', rows);

  const builder = new CsvBuilder(filename).setColumns(columns).addRows(rows);
  // console.log('%c builder', 'color:orangered', builder);

  builder.exportFile();
};

export const chartColors = [mainStyle.blueShadeAlt];

export { getFilter };
