import React from 'react';
import useStoreFiles from '../../hooks/useStoreFiles';
import FileList from './file-list';


const FileDirectory = ({name}) => {
  // console.log('');
  // console.log('%c FileDirectory()', 'color:yellow');
  // console.log('%c name', 'color:yellow', name);

  const [files] = useStoreFiles(name);
  // console.log('%c files', 'color:yellow', files);

  return (
    <FileList folders={files} />
  );
};

export default FileDirectory;
