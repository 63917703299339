import { disbursementTypes } from '../actions/disbursement-actions';

const initialState = {
  data: null,
  loading: true,
  message: '',
  status: null
};

const DisbursementReducer = (state = initialState, action) => {
  switch (action.type) {
    case disbursementTypes.GET_DISBURSEMENTS_PAGE:
      // console.log('');
      // console.log('%c disbursementTypes.GET_DISBURSEMENTS_PAGE', 'color:fuchsia');
      // console.log('%c action.payload', 'color:fuchsia', action.payload);
      // console.log('%c state', 'color:fuchsia', state);

      return {
        ...state,
        data: action.payload,
        loading: false,
        status: action.status
      };

    case disbursementTypes.DISBURSEMENT_ERROR:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status
      };

    case disbursementTypes.DISBURSEMENTS_PAGE_LOADING:
      return {
        ...state,
        loading: true,
        status: null
      };

    default:
      return state;
  }
}

export default DisbursementReducer;
