import React from 'react';
import Layout from '../components/layout/layout';
import Header from '../components/assets/header/header';
import Charts from '../components/assets/charts/';


const Assets = () => {
  // console.log('');
  // console.log('%c Assets()', 'color:skyblue');

  return (
    <Layout>
      <Header />
      <Charts />
    </Layout>
  );
};

export default Assets;
