import React from 'react';
import TriplePointForm from '../components/shared/triple-point-form';
import { portalForms } from '../utils/constants';


const ContactForm = () => {
  return (
    <TriplePointForm title={portalForms.contactUs.title} url={portalForms.contactUs.url} open={true}></TriplePointForm>
  );
}

export default ContactForm;
