import React from 'react';
import TriplePointForm from '../components/shared/triple-point-form';
import { portalForms } from '../utils/constants';


const GrantDistributionForm = () => {
  return (
    <TriplePointForm title={portalForms.grantDisbursementRequest.title} url={portalForms.grantDisbursementRequest.url} open={true}></TriplePointForm>
  );
}

export default GrantDistributionForm;
