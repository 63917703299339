import React, { useState } from 'react';
import { Button, ButtonGroup, Box, Typography } from '@material-ui/core';
import { CreateUser } from './CreateUser';
import { ListUsers } from './ListUsers';


export const UsersLanding = () => {
  const [page, setPage] = useState('list');

  const isCreate = page === 'create';
  const isList = page === 'list';

  const handleClick = (page) => {
    setPage(page);
  };

  return (
    <Box position="relative" style={{ width: '100%', maxWidth: 1400 }}>

      <Box position="absolute" top={0} right={0} display="flex" justifyContent="center" alignItems="center" height={42} px={1}>
        <a href="https://manage.auth0.com/dashboard/us/dev-xyeoqnfn/users" target="_blank" rel="noreferrer" style={{ fontSize: 16, fontWeight: 600, textDecoration: 'none' }}>Auth0 User Management</a>
      </Box>

      <Typography variant="h4" component="h1" gutterBottom style={{ borderBottom: '2px solid black'}}>
        {isCreate && (
          `Create User`
        )}
        {isList && (
          `List Users`
        )}
      </Typography>

      <ButtonGroup variant="outlined" color="secondary">
        <Button onClick={() => handleClick('list')}>List</Button>
        <Button onClick={() => handleClick('create')}>Create</Button>
      </ButtonGroup>

      <Box pt={4} pb={8}>
        {isCreate && (
          <CreateUser />
        )}

        {isList && (
          <ListUsers />
        )}
      </Box>

    </Box>
  );
};
