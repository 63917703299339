import { setAppLoading, setAppLoaded } from './app-actions';
import { getAssetPage } from './assets-actions';
import { getContributionsPage } from './contribution-actions';
import { getClient } from './client-actions';
import { getDisbursementsPage } from './disbursement-actions';
import { getFilesPage } from './files-actions';

export const initialLoad = () => dispatch => {
  dispatch(setAppLoading());
  dispatch(getAssetPage());
  dispatch(getClient());
  dispatch(getContributionsPage());
  dispatch(getFilesPage());
  dispatch(getDisbursementsPage());
  dispatch(setAppLoaded());
}

export default initialLoad;
