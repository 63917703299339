import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import mainStyle from '../../../styles/mainStyle';
import HeaderWrapper from '../../shared/header-wrapper';
import ForwardIcon from '@material-ui/icons/PlayArrow';
import { getArrow } from '../../shared/get-arrow';
import { makeStyles } from '@material-ui/core';
import TriplePointForm from '../../shared/triple-point-form';


const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    fontStyle: 'italic',
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '1.2em',
    padding: '.25rem .5rem',
    textAlign: 'left',
    textTransform: 'none',
    textDecoration: 'none'
  },
});

const RecentData = (props) => {
  const { total, amountChange } = props;

  return (
    <div>
      <div>
        <span>
          <span
            style={{
              fontSize: '20px',
              fontWeight: 600,
              color: mainStyle.headerTextColorForDark
            }}
          >
            {total}
          </span>{' '}
          <i style={{ fontSize: '16px', color: '#FFFFFFA5' }}>
            Grants disbursed YTD
          </i>
        </span>
      </div>
      <div className="flex-ac" style={{ marginTop: '8px' }}>
        {getArrow(amountChange)}
        <div>
          <i
            style={{
              fontSize: '18px',
              color: 'white',
              fontWeight: 300
            }}
          >
            ({amountChange})
          </i>{' '}
          <i style={{ fontSize: '13px', color: '#FFFFFFA5' }}>YoY Change</i>
        </div>
      </div>
    </div>
  );
};

function Header() {
  // console.log('');
  // console.log('%c disbursements/Header()', 'color:salmon');

  const classes = useStyles();
  const files = useSelector((state) => state.files.data);
  const summary = useSelector(state => state.disbursements.data?.summary);
  const headerFiles = files?.disbursementHeaderFiles || [];

  const data = [
    {
      title: 'Number of Grants',
      component: (
        <RecentData
          date={summary?.asOf}
          total={summary?.yearCount}
          amountChange={summary?.changeOverYearCount}
        />
      )
    },
  ];

  if (headerFiles.length) {
    data.push({
      title: 'Grant Resources',
      component: (
        <Fragment>
          {
            headerFiles.map((file, index) => (
              file.isWebForm ? (
                <TriplePointForm key={index} title={file.title} url={file.url}>
                  {/* eslint-disable-next-line */}
                  <a className={classes.root} style={{ cursor: 'pointer' }}>
                    <ForwardIcon style={{ fill: 'white', height: '1rem', marginRight: '.5rem', width: '1rem', }} />
                    {file.title}
                  </a>
                </TriplePointForm>
              ) : (
                <a key={index} href={file.meta.uri} className={classes.root} target="_blank" rel="noreferrer noopener">
                  <ForwardIcon style={{ fill: 'white', height: '1rem', marginRight: '.5rem', width: '1rem', }} />
                  {file.title}
                </a>
              )
            ))
          }
        </Fragment>
      ),
    });
  }

  return (
    <HeaderWrapper
      title="Grants"
      date={summary?.asOf}
      data={data} />
  );
}

export default Header;
