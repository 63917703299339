import React, { forwardRef, Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Box, Dialog, Slide } from '@material-ui/core';
import FormFauxLayout from './form-faux-layout';
import { useAuth } from '../../auth';
import { useAuth0 } from '@auth0/auth0-react';


const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TriplePointForm = ({title, url, children, open = false, allowGuest = false}) => {
  // console.log('');
  // console.log('%c TriplePointForm()', 'color:yellow');
  // console.log('%c title', 'color:yellow', title);
  // console.log('%c url', 'color:yellow', url);
  // console.log('%c children', 'color:yellow', children);
  // console.log('%c open', 'color:yellow', open);


  const { logout } = useAuth0();
  const { accessToken } = useAuth();
  const [openDialog, setOpenDialog] = useState(open);
  const history = useHistory();

  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  if (!accessToken && !allowGuest) logout();

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    if (children) {
      setOpenDialog(false);
    } else {
      history.push('/');
    }
  };

  const FormLayout = () => {
    const formUrl = allowGuest ? `${url}` : `${url}?auth0token=${accessToken}`;
    return (
      <FormFauxLayout title={title} onClose={handleDialogClose}>
        <Box width="100%" maxWidth={1080}>
          {title && (
            <h1>{title}</h1>
          )}
          <iframe src={formUrl} title={title} width="100%"></iframe>
        </Box>
        {/* <IframeResizer log src={formUrl} title={title} style={{ width: '1px', minWidth: '100%'}} checkOrigin={false}></IframeResizer> */}
      </FormFauxLayout>
    );
  };

  return (
    <Fragment>
      {
        children && (
          <span onClick={handleDialogOpen}>
            {children}
          </span>
        )
      }
      {
        children ? (
          <Dialog fullScreen open={openDialog} onClose={handleDialogClose} TransitionComponent={Transition}>
            <FormLayout />
          </Dialog>
        ) : (
          <FormLayout />
        )
      }
    </Fragment>
  );
};

export default TriplePointForm;
