import React, { Fragment } from 'react';
import { VictoryLabel, VictoryPie, } from 'victory';
import mainStyle from '../../../styles/mainStyle';
import { formatMoney } from '../../../utils/misc';


const gaugeChartSegments = [];
for (let index = 100; index >= 0; index--) {
  gaugeChartSegments.push({x: index, y: 1});
}

function DistributionChart(props) {
  // console.log('');
  // console.log('%c DistributionChart()', 'color:deeppink');
  // console.log('%c props', 'color:deeppink', props);
  // console.log('%c gaugeChartSegments', 'color:deeppink', gaugeChartSegments);

  const { currentAmount, recommendedAmount, requiredAmount } = props;
  const percentValue = requiredAmount > 0 ? Math.ceil((Math.abs(requiredAmount - recommendedAmount) / requiredAmount) * 100) : 100;

  // console.log('%c currentAmount', 'color:deeppink', currentAmount);
  // console.log('%c requiredAmount', 'color:deeppink', requiredAmount);
  // console.log('%c recommendedAmount', 'color:deeppink', recommendedAmount);
  // console.log('%c percentValue', 'color:deeppink', percentValue);

  return (
    <Fragment>
      <svg viewBox="0 -50 375 375">
        <VictoryPie
          colorScale={[mainStyle.blueShadeAlt, mainStyle.blueShadeAlt]}
          standalone={false}
          padAngle={0}
          startAngle={90}
          endAngle={-90}
          width={375}
          height={375}
          data={gaugeChartSegments}
          innerRadius={90}
          labelRadius={9999}
          labelPosition="centroid"
          labelPlacement="vertical"
          style={{
            data: {
              stroke: ({ datum }) => {
                return datum.x <= percentValue ? mainStyle.blueShadeAlt : mainStyle.greyShade3;
              },
              fill: ({ datum }) => {
                return datum.x <= percentValue ? mainStyle.blueShadeAlt : mainStyle.greyShade3;
              },
            },
            labels: {
              fontSize: 10,
              fill: "grey",
            },
          }}
          labels={({ datum }) => (datum.x % 10 === 0 ? `${datum.x}%` : "")}
        />
        <VictoryLabel
          textAnchor="middle"
          style={{
            fontSize: 24,
            fontFamily: "Roboto",
            fontWeight: 800,
            fill: mainStyle.blueShade1,
            lineHeight: "1000px",
          }}
          x={375 / 2}
          y={300 / 2}
          text={`${formatMoney(currentAmount, 0)}`}
        />
        <VictoryLabel
          textAnchor="middle"
          style={{
            fontSize: 14,
            fontFamily: "Roboto",
            fontWeight: 800,
            fill: mainStyle.blueShade1,
            lineHeight: "1000px",
          }}
          x={375 / 2}
          y={350 / 2}
          text={`of ${formatMoney(requiredAmount, 0)}`}
        />
        <VictoryLabel
          textAnchor="middle"
          style={{
            fontSize: 14,
            // fontWeight: 800,
            fill: mainStyle.blueShade1,
            fontStyle: "italic",
          }}
          x={375 / 2}
          y={420 / 2}
          text={`Qualified Distributions\nto date`}
        />
      </svg>
      <div className="legend mb-2 px-1" style={{ fontSize: 12, textAlign: 'center' }}>
        <div>
          <i><span className='orange-s4'><b>*</b></span> This figure includes all qualifying disbursements to date and is an estimate of what must be disbursed by year end. Please contact Sterling for more detailed information.</i>
        </div>
      </div>
    </Fragment>
  );
}

export default DistributionChart;
