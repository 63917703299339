import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import SummaryChartInfo from './summary-chart-info';
import AssetsChart from './assets-chart';
import ContributionsChart from './contributions-chart';
import DisbursementsChart from './disbursements-chart';
import BlockButton from '../../shared/button-block';
import mainStyle from '../../../styles/mainStyle';
import DistributionRequirements from '../../disbursements/charts/distribution-requirements';
import { shortMonths } from '../../../utils/constants';


const formatWithMonth = (date) => {
  return date ? `${shortMonths[date.getMonth()]} ${date.getFullYear()}` : '';
}

const Charts = ({ account, assets, contributions, disbursements }) => {
  // console.log('');
  // console.log('%c Summary()', 'color:yellow');

  // console.log('%c account', 'color:yellow', account);
  // console.log('%c assets', 'color:yellow', assets);
  // console.log('%c contributions', 'color:yellow', contributions);
  // console.log('%c disbursements', 'color:yellow', disbursements);

  const history = useHistory();
  const assetsSummary = assets?.summary;
  const contributionsSummary = contributions?.summary;
  const disbursementsSummary = disbursements?.summary;

  // console.log('%c assetsSummary', 'color:darkcyan', assetsSummary);
  // console.log('%c contributionsSummary', 'color:darkcyan', contributionsSummary);
  // console.log('%c disbursementsSummary', 'color:darkcyan', disbursementsSummary);

  return (
    <div className="summary-row" style={{ overflowX: 'hidden' }}>
      <Grid
        container
        direction="row">

        <Grid item lg={3} sm={6} xs={12}>
          <div className="charts">
            {assetsSummary && (
              <>
                <SummaryChartInfo
                  title="Current Asset Value"
                  totalAmount={assetsSummary.total}
                  amountChange={assetsSummary.changeOverMonthTotal}
                  amountChangePercent={assetsSummary.changeOverMonthTotalPercent}
                  amountChangeTitle={'30 Day Change'} />

                <AssetsChart
                  cash={assetsSummary.cash}
                  investments={assetsSummary.investments}
                  total={assetsSummary.total} />

                <BlockButton onClick={() => history.push('/asset-value')}>
                  View all Assets
                </BlockButton>
              </>
            )}
          </div>
        </Grid>

        <Grid item lg={3} sm={6} xs={12} className='bl-md-up bt-xs-dn'>
          <div className="charts" style={{ backgroundColor: mainStyle.lightGreyBg }}>
            {contributionsSummary && (
              <>
                <SummaryChartInfo
                  title={'Contributions YTD'}
                  totalAmount={contributionsSummary.ytdTotal}
                  amountChange={contributionsSummary.changeOverYtdTotal}
                  amountChangePercent={contributionsSummary.changeOverYtdTotalPercent}
                  amountChangeTitle={`YTD change as of ${formatWithMonth(disbursementsSummary.summaryAt)}`} />

                <ContributionsChart
                  summary={contributionsSummary} />

                <BlockButton onClick={() => history.push('/contributions')}>
                  View all Contributions
                </BlockButton>
              </>
            )}
          </div>
        </Grid>

        <Grid item lg={3} sm={6} xs={12} className='bt-lg-dn bl-lg'>
          <div className="charts">
            {disbursementsSummary && (
              <>
                <SummaryChartInfo
                  isAlt
                  title={'Grants YTD'}
                  totalAmount={disbursementsSummary.ytdTotal}
                  amountChange={disbursementsSummary.changeOverYtdTotal}
                  amountChangePercent={disbursementsSummary.changeOverYtdTotalPercent}
                  amountChangeTitle={`YTD change as of ${formatWithMonth(disbursementsSummary.summaryAt)}`} />

                <DisbursementsChart
                  summary={disbursementsSummary} />

                <BlockButton onClick={() => history.push('/grants')}>
                  View all Grants
                </BlockButton>
              </>
            )}
          </div>
        </Grid>

        <Grid item lg={3} sm={6} xs={12} className='bt-lg-dn bl-md-up'>
          <div className="charts buttonless" style={{ backgroundColor: mainStyle.lightGreyBg }}>
            {account && (
              <DistributionRequirements padTitle={true} />
            )}
          </div>
        </Grid>

      </Grid>
    </div>
  );
}

export default Charts;
