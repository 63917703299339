import { filesTypes } from '../actions/files-actions';

const initialState = {
  data: null,
  loading: true,
  message: '',
  status: null
};

const FilesReducer = (state = initialState, action) => {
  switch (action.type) {
    case filesTypes.GET_FILES_PAGE:
      // console.log('');
      // console.log('%c filesTypes.GET_FILES_PAGE', 'color:fuchsia');
      // console.log('%c action.payload', 'color:fuchsia', action.payload);
      // console.log('%c state', 'color:fuchsia', state);

      return {
        ...state,
        data: action.payload,
        loading: false,
        status: action.status
      };

    case filesTypes.FILE_ERROR:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status
      };

    case filesTypes.FILES_PAGE_LOADING:
      return {
        ...state,
        loading: true,
        status: null
      };

    default:
      return state;
  }
}

export default FilesReducer;
