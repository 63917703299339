const colors = {
  black: "#000",
  white: "#FFF",
  blueShadeAlt: '#1A80FF',
  blueShade1: '#0D2340',
  blueShade2: '#1B3B64',
  blueShade3: '#226495',
  blueShade4: '#2C82CA',
  blueShade5: '#3DA7FB',
  blueShade6: '#8FB3D9',
  greyShade1: '#545b61',
  greyShade2: '#8b9095',
  greyShade3: '#DFE1E5',
  greenShade1: '#1DB8BE',
  greenShade2: '#23D3D9',
  greenShade3: '#2BE3EA',
  greenShade4: '#71F3F8',
  greenShade5: '#B7FCFF',
  orangeShade1: '#F8D824',
  orangeShade2: '#F2A831',
  orangeShade3: '#F77F00',
  orangeShade4: '#F74A21',

  primaryColor: "#2BE3EA",
  primaryColorContrast: "#0D2340",
  secondaryColor: "#1B3B64",
  secondaryColorContrast: "#fff",
  errorColor: "#F74A21",
  errorColorContrast: "#000",
  alertColor: "#F74A21",
  borderColor: "#CCC",
  headerColor: "#091C36",
  navColor: "#1E4576",
  defaultGray: '#9F9F9F',
  lightGreyBg: "#F2F2F7",

  buttonColor: '#0D2340',
  buttonBackgroundColor: '#DFE1E5',

  buttonSecondaryColor: '#0D2340',
  buttonSecondaryBackgroundColor: '#DFE1E5',
  buttonSecondaryHoverColor: '#0D2340',
  buttonSecondaryHoverBackgroundColor: '#1A80FF',

  headerTextColorForDark: '#3DA7FB',
  headerTextColorForLight: '#1A80FF',
  // headerTextColorForDark: 'fuchsia',
  // headerTextColorForLight: 'deeppink'

  mapDefaultGray: '#D3D6D9',
};

export const colorSetCounts = [colors.mapDefaultGray, colors.orangeShade1, colors.orangeShade2, colors.greenShade2, colors.blueShade4];
export const colorSetMaps = [colors.mapDefaultGray, colors.blueShade6, colors.blueShade4, colors.blueShade3, colors.blueShade1];
export const colorSetMultiple = [colors.blueShade3, colors.blueShade4, colors.blueShade5, colors.greenShade2, colors.orangeShade2];
export const colorSetBlue = [colors.blueShadeAlt];
export const colorSetBlues = [colors.blueShade4, colors.blueShade5, colors.blueShade6, colors.blueShade1, colors.blueShade2, colors.blueShade3];
export const colorSetGreens = [colors.greenShade1, colors.greenShade2, colors.greenShade3, colors.greenShade4, colors.greenShade5];

export const elementStyles = {
  root: {
    backgroundColor: colors.blueShade1
  },
  column: {
    position: 'relative',
    background: 'white',
    minHeight: '444px',
    minWidth: 0,
    padding: '30px 20px'
  },
  insideColumn: {
    position: 'relative',
    backgroundColor: colors.lightGreyBg,
    borderLeft: `1px solid ${colors.borderColor}`,
    flex: 1,
    minWidth: 0,
    padding: '30px 20px'
  },
  sideColumn: {
    position: 'relative',
    backgroundColor: colors.lightGreyBg,
    borderLeft: `1px solid ${colors.borderColor}`,
    // cursor: 'pointer',
    flex: 1,
    minWidth: 0,
    padding: '30px 20px'
  },
  button: {
    width: '100%',
    fontSize: 16,
    height: '60px'
  },
  buttonRound: {
    fontSize: 16,
    // height: '50px',
    borderRadius: '40px',
    fontWeight: 700,
    whiteSpace: 'nowrap'
    // width: '165px',
    // marginTop: '5px'
  },
  divider: {
    width: '40px',
    height: '2px',
    opacity: '.5',
    backgroundColor: colors.defaultGray,
    marginTop: '10px',
    marginBottom: '10px'
  }
};

export default colors;
