import React, {Fragment, useEffect, useState} from 'react';
import axios from 'axios';
import {useDropzone} from 'react-dropzone';
import { Button, Dialog, Divider, Slide } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { getUrl, getConfig } from '../../redux/redux-helpers';
import FormFauxLayout from './form-faux-layout';
import ListIconButton from './list-icon-button';
import { getFileExtIconLink } from '../../redux/services/files-service';
import mainStyle, { elementStyles } from '../../styles/mainStyle';
import { portalForms } from '../../utils/constants';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuth } from '../../auth';


const styles = {
  dropzone: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#fafafa',
    border: `2px dashed #eeeeee`,
    color: mainStyle.greyShade2,
    cursor: 'pointer',
    fontFamily: 'sans-serif',
    outline: 'none',
    padding: '2rem',
    transition: 'border .24s ease-in-out'
  },
  fileMeta: {
    display: 'block',
    color: mainStyle.greyShade2,
    fontSize: '.95rem',
    fontStyle: 'italic',
    fontWeight: 400,
    listStyle: 'none',
    padding: '.5em 0px'
  },
  listHeader: {
    margin: '1em 0px'
  },
  listItem: {
    margin: '.5em 0px'
  },
  button: {
    fontSize: 16,
    fontStyle: 'italic',
    fontWeight: 700,
    padding: '.5em 3em'
  },
  colorButton: {
    textColor: mainStyle.white,
    textHoverColor: mainStyle.white,
    color: mainStyle.primaryColor,
    hoverColor: mainStyle.blueShade2,
  }
}

const humanFileSize = (bytes, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const fileTypeList = '.csv,text/csv,.jpg,.jpeg,image/jpeg,.png,image/png,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msexcel,application/x-msexcel,application/x-ms-excel,application/x-excel,application/x-dos_ms_excel,application/xls,application/x-xls,.pdf,application/pdf,.rtf,application/rtf,.txt,text/plain';
const maxCount = 5;

function UploadFiles({children}) {
  const { logout } = useAuth0();
  const { accessToken } = useAuth();
  const [files, setFiles] = useState([]);
  const [result, setResult] = useState(null);
  const [isEnabled, setIsEnabled] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {getRootProps, getInputProps, acceptedFiles, fileRejections, open} = useDropzone({
    accept: fileTypeList,
    maxFiles: maxCount,
    maxSize: 10485760,
    onDrop: (acceptedFiles, fileRejections) => {
      // console.log('');
      // console.log('%c UploadFiles():onDrop', 'color:pink');
      // console.log('%c acceptedFiles', 'color:pink', acceptedFiles);
      // console.log('%c fileRejections', 'color:pink', fileRejections);

      setResult(null);
      setIsSubmitted(false);
      setFiles(acceptedFiles.map(file => Object.assign(file, { preview: URL.createObjectURL(file) })));
    }
  });

  useEffect(() => {
    // console.log('');
    // console.log('%c useEffect()[files]', 'color:fuchsia');
    // console.log('%c files', 'color:fuchsia', files);

    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  useEffect(() => {
    // console.log('');
    // console.log('%c useEffect()[files, isSubmitting]', 'color:fuchsia');
    // console.log('%c files', 'color:fuchsia', files);
    // console.log('%c isSubmitting', 'color:fuchsia', isSubmitting);

    setIsEnabled(!isSubmitting && files.length > 0);
  }, [files, isSubmitting]);

  const acceptedFileItems = acceptedFiles.map(file => (
    <div key={file.path} className="mt-1">
      <ListIconButton title={file.name} leadingIcon={<img src={getFileExtIconLink(file.name)} alt="" />}></ListIconButton>
    </div>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path} style={styles.listItem}>
      {file.path}
      {errors.map(e => (
        <div key={e.code} style={styles.fileMeta}>
          {e.code === 'file-invalid-type' ? ' File format not accepted.' : e.code === 'too-many-files' ? ' File limit reached.' : `${e.message}`}
        </div>
      ))}
    </li>
  ));

  const handleSubmit = (event) => {
    // console.log('');
    // console.log('%c handleSubmit()', 'color:yellow');
    // console.log('%c files', 'color:yellow', files);

    event.preventDefault();
    event.stopPropagation();

    if (!files.length > 0) return;

    setIsSubmitting(true);

    const formData = new FormData();
    for (const item of files) {
      formData.append('file', item);
    }

    axios.post(getUrl('files/send'), formData, getConfig()).then(res => {
      sendFileResult(files, res.data);
      setResult({ type: 'success', message: 'Upload complete.'});
      setFiles([]);
      setIsSubmitting(false);
      setIsSubmitted(true);
    }).catch((error) => {
      console.log('');
      console.log('%c error', 'color:orangered', error);
      setResult({ type: 'error', message: 'An error occurred during upload.'});
      setFiles([]);
      setIsSubmitting(false);
    });
  };

  const sendFileResult = (files, result) => {
    // console.log('');
    // console.log('%c sendFileResult()', 'color:gold');
    // console.log('%c files', 'color:gold', files);
    // console.log('%c result', 'color:gold', result);

    if (!accessToken) logout();

    const formDefinition = portalForms.sendFileResult;
    // console.log('%c formDefinition', 'color:gold', formDefinition);

    let fileList = `${result.files.length} file(s) uploaded.

    Folder:
    ${result.folder}

    Files:`;
    // console.log('%c fileList', 'color:gold', fileList);

    result.files.forEach(file => {
      fileList += `
      ${file.filename} (${humanFileSize(file.size)})
      ${file.uri}
      `;
    });
    // console.log('%c fileList', 'color:gold', fileList);

    const formData = new FormData();
    formData.append('IsPosted', 'true');
    formData.append('Form_id', formDefinition.formId);
    formData.append('FormInstance_id', formDefinition.formInstanceId);
    formData.append(formDefinition.fields.files, fileList);

    axios.post(`${formDefinition.url}?auth0token=${accessToken}`, formData).then(res => {
      // console.log('');
      // console.log('%c sendFileResult():RESULT', 'color:goldenrod');
      // console.log('%c res', 'color:goldenrod', res);
    }).catch((error) => {
      console.log('%c Error occurred while requesting file result notification', 'color:orangered');
      console.error(error);
    });
  }

  const handleBubbleClick = (e) => {
    // console.log('');
    // console.log('%c handleBubbleClick()', 'color:orange');
    if (!isEnabled) open();
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <Fragment>
      <span onClick={handleDialogOpen}>
        {children}
      </span>
      <Dialog fullScreen open={openDialog} onClose={handleDialogClose} TransitionComponent={Transition}>
        <FormFauxLayout onClose={handleDialogClose}>
          <section style={{margin: '0px auto', maxWidth: '800px'}}>
            <form onSubmit={handleSubmit}>
              {result && (
                <Alert severity={result.type} className="mb-2">{result.message}</Alert>
              )}

              <div {...getRootProps({className: 'dropzone'})} style={styles.dropzone}>
                <input {...getInputProps()} />
                <p className="m-0 mb-1">Drag and drop files here or click to select files.</p>
                <small><em>(CSV, Excel, JPG, PDF, PNG and Word files. 10MB file size and limit of {maxCount} files.)</em></small>
              </div>

              {
                acceptedFiles.length > 0 && (
                  <Fragment>
                    <div className="blue-s1 rg-text f-700 mt-2">
                      {isSubmitted ? 'Submitted Files:' : 'Selected Files:'}
                    </div>
                    <Divider style={elementStyles.divider} />
                    {acceptedFileItems}
                  </Fragment>
                )
              }
              {
                fileRejectionItems.length > 0 && (
                  <Fragment>
                    <div className="blue-s1 rg-text f-700 mt-2">
                      Rejected Files:
                    </div>
                    <Divider style={elementStyles.divider} />
                    <ul className='m-0 mt-1'>{fileRejectionItems}</ul>
                  </Fragment>
                )
              }
              <div className="mt-2" onClick={() => handleBubbleClick()}>
                <Button type="submit" variant="contained" color="primary" onClick={(e) => handleSubmit(e)} disabled={!isEnabled}>
                  {isSubmitting ? 'Uploading Files...' : isEnabled ? 'Upload Selected Files' : 'Upload Files'}
                </Button>
              </div>
            </form>
          </section>
        </FormFauxLayout>
      </Dialog>
    </Fragment>
  );
}

export default UploadFiles;
