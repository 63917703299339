import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid } from '@material-ui/core';
import { parseChartData } from '../../../redux/services/disbursements-service';
import MonthChart from './month-chart-apex-stacked';
import { elementStyles } from '../../../styles/mainStyle';
import ChartInfo from '../../shared/big-chart-info';
import DistributionRequirements from './distribution-requirements';
import CategoryPercentagesChart from './category-percentages';
import SideInfoWrapper from '../../shared/side-info-wrapper';


const MainRow = () => {
  // console.log('');
  // console.log('%c disbursements/MainRow()', 'color:violet');

  const disbursements = useSelector(state => state.disbursements.data.disbursements);
  const [filterValue, setFilterValue] = useState('months:6'); // debug with: year:2021
  // const [filterValue, setFilterValue] = useState('year:2019');
  const chartData = parseChartData(disbursements, filterValue);
  // const chartData = useMemo(() => parseChartData(disbursements, filterValue), [disbursements, filterValue]); // going to fire * because of disbursements?

  if (!chartData) return (
    <Box minHeight={450} p={3} style={{ backgroundColor: 'white'}}>
      <em>No Data Available</em>
    </Box>
  );

  // console.log('%c disbursements', 'color:violet', JSON.parse(JSON.stringify(disbursements)));
  // console.log('%c filterValue', 'color:violet', filterValue);
  // console.log('%c chartData', 'color:violet', JSON.parse(JSON.stringify(chartData)));

  // const account = useSelector(state => state.client.data);
  // const showDistributionRequirements = !!account.isPublic;

  // console.log('%c account', 'color:violet', JSON.parse(JSON.stringify(account)));
  // console.log('%c showDistributionRequirements', 'color:violet', showDistributionRequirements);

  return (
    <Grid container direction="row" justify="space-between" alignItems="stretch">
      <Grid item md={6} xs={12} style={{ ...elementStyles.column, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>

        <ChartInfo
          title="Grant Total"
          subtitle={`(${chartData.filter.title})`}
          total={chartData.summary.total}
          changeValue={chartData.summary.change}
          changePercent={chartData.summary.changePercent}
          changeTitle={chartData.summary.changeTitle}
          filterValue={filterValue}
          filterOptions={chartData.filterOptions}
          onFilterChange={setFilterValue} />

        <MonthChart data={chartData} />

      </Grid>
      <Grid item md={3} xs={12} style={elementStyles.sideColumn}>
        <CategoryPercentagesChart categories={chartData.categories}></CategoryPercentagesChart>
      </Grid>

      <Grid item md={3} xs={12} style={elementStyles.sideColumn}>
        <SideInfoWrapper noPadding>
          <div style={{maxWidth: '350px', margin: '0px auto'}}>
            <DistributionRequirements />
          </div>
        </SideInfoWrapper>
      </Grid>
    </Grid>
  );
};

export default MainRow;
