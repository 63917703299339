import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { List, ListItem } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import HamburgerMenu from './hamburger-menu';
import sterlingLogo from '../../images/logo.svg';
import UploadFiles from '../shared/upload-files';
import RoundButton from '../shared/round-button';
import Logout from '../shared/logout';
import { useAuth } from '../../auth';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SwapCallsIcon from '@material-ui/icons/SwapCalls';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import useMediaQuery from '@material-ui/core/useMediaQuery';


const ENV_LOGOUT_URI = process.env.REACT_APP_AUTH0_LOGOUT_URI;

const Navbar = () => {
  const { logout } = useAuth0();
  const { allowClientChange, isSuper, selectClientId } = useAuth();
  const matchesBp = useMediaQuery('(min-width:1380px)');
  const [open, setOpen] = useState(false);

  const nav = isSuper ? navLinksForSuper : navLinks;
  const mobileNav = isSuper ? mobileLinksForSuper : mobileLinks;

  return (
    <header>
      <div id="nav-spacing"></div>
      <div id="nav">
        {/* Desktop */}
        <div className="desktop-nav nav-container">
          <div className="flex-sb-ac desktop-nav-list">
            <List className="flex" style={{height: '90px', padding: '0px'}}>
              <ListItem className="logo">
                <Logo />
              </ListItem>
              {nav.map((link) => (
                <StyledLink
                  key={link.label}
                  href={link.href}
                  label={link.label}
                />
              ))}
            </List>

            <List className="flex-ac">
              <ListItem>
                <UploadFiles>
                  <RoundButton color="primary" size={matchesBp ? 'large' : 'small'} startIcon={<CloudUploadIcon />}>Upload Files</RoundButton>
                </UploadFiles>
              </ListItem>

              <ListItem>
                <ButtonGroup  variant="outlined">
                  {allowClientChange && (
                    <RoundButton variant="outlined"
                      color="primary"
                      size={matchesBp ? 'large' : 'small'}
                      startIcon={<SwapCallsIcon />}
                      onClick={() => selectClientId(null)}>
                      Account
                    </RoundButton>
                  )}
                  <RoundButton variant="outlined"
                    color="primary"
                    size={matchesBp ? 'large' : 'small'}
                    startIcon={<ExitToAppIcon />}
                    onClick={() => logout({ returnTo: ENV_LOGOUT_URI })}>
                    Log out
                  </RoundButton>
                </ButtonGroup>
              </ListItem>
            </List>
          </div>
        </div>
        {/* Mobile */}
        <div className="mobile-nav nav-container">
          <div className="flex-sb-ac w-100">
            <Logo />
            <HamburgerMenu.Toggle
              open={open}
              toggle={() => {
                setOpen((o) => !o);
              }}
            />
          </div>
        </div>
      </div>
      <div className="mobile-nav mobile-nav-menu rm-padding">
        <HamburgerMenu open={open} list={mobileNav} />
      </div>
    </header>
  );
};


const SwapAccount = () => {
  const { selectClientId } = useAuth();
  return (
    <div onClick={() => selectClientId(null)}>
      Swap Account
    </div>
  );
};

const navLinks = [
  {
    label: 'Summary',
    href: '/'
  },
  {
    label: 'Asset Value',
    href: '/asset-value'
  },
  {
    label: 'Contributions',
    href: '/contributions'
  },
  {
    label: 'Grants',
    href: '/grants'
  },
  {
    label: 'Documents',
    href: '/documents'
  },
  {
    label: 'Forms',
    href: '/forms'
  }
];



const mobileLinks = [
  ...navLinks,
  // { type: 'divider', color: mainStyle.blueShade1 },
  {
    type: 'component',
    component: (
      <UploadFiles>
        Upload Files
      </UploadFiles>
    )
  },
  {
    type: 'component',
    component: (
      <span>
        <SwapAccount />
      </span>
    )
  },
  {
    type: 'component',
    component: (
      <span>
        <Logout />
      </span>
    )
  }
];

const navLinksForSuper = [
  ...navLinks,
  {
    label: 'Users',
    href: '/users'
  }
];
const mobileLinksForSuper = [
  ...navLinksForSuper,
  {
    type: 'component',
    component: (
      <UploadFiles>
        Upload Files
      </UploadFiles>
    )
  },
  {
    type: 'component',
    component: (
      <span>
        <SwapAccount />
      </span>
    )
  },
  {
    type: 'component',
    component: (
      <span>
        <Logout />
      </span>
    )
  }
];

// const menuList = [
//   {
//     title: 'Profile',
//     onClick: () => {
//       console.log('profile click');
//     }
//   },
//   {
//     title: 'Account',
//     onClick: () => {
//       console.log('profile click');
//     }
//   }
// ];

const StyledLink = ({ label, href }) => {
  return window.location.pathname === href ? (
    <ListItem>
      <Link to={href} className="nav-li-link is-active">
        {label}
        <div className="nav-bottom-point" />
      </Link>
    </ListItem>
  ) : (
    <ListItem>
      <Link to={href} className="nav-li-link">
        {label}
      </Link>
    </ListItem>
  );
};

const Logo = () => {
  return (
    <Link to={'/'} className="nav-li-logo">
      <img src={sterlingLogo} alt="Sterling logo" width={175} />
    </Link>
  );
};

export default Navbar;
