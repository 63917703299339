import React from 'react';
import MainChartRow from './main-chart-row';


const Charts = () => {
  // console.log('');
  // console.log('%c assets/Charts()', 'color:lightblue');

  return (
    <MainChartRow />
  );
}

export default Charts;
