import React from 'react';
import { Divider } from '@material-ui/core';
import { formatMoney } from '../../utils/misc';
import { elementStyles } from '../../styles/mainStyle';
import { getArrow } from './get-arrow';
import MySelect from './my-select';


const ChartInfo = (props) => {
  // console.log('');
  // console.log('%c shared/ChartInfo()', 'color:salmon');
  // console.log('%c props', 'color:salmon', props);

  const { title, subtitle, filterValue, filterOptions, onFilterChange, total, changeValue, changePercent, changeTitle, isAlt, dataType, format = 'money', includeArrow = true } = props;
  const hasChangeValue = changeValue !== null && changeValue !== undefined;
  const hasChangePercent = changePercent !== null && changePercent !== undefined;
  const hasFilter = filterOptions && onFilterChange !== null && onFilterChange !== undefined;

  return (
    <div id={props.id} className="flex-sb chart-info">
      <div>
        <div>
          <div className="blue-s1 rg-text f-700">
            {title}
            {subtitle &&
              <span style={{ fontSize: 16, fontWeight: 400, marginLeft: '.33em' }}>{subtitle}</span>
            }
          </div>
          <Divider
            style={elementStyles.divider}
          />
        </div>
        <div className="flex-ac">
          <div className={`xl-text f-700 ${total < 0 ? 'red-s1' : isAlt ? 'header-text-for-light' : 'header-text-for-light'}`}>
            {format === 'money' ? `${formatMoney(total, 0)}` : format === 'round' ? Math.round(total) : total}
          </div>
          {hasChangeValue &&
            <div style={{ display: 'flex', alignItems: 'flex-end', marginLeft: '10px' }}>
              {dataType &&
                <i>{dataType}</i>
              }
              {includeArrow &&
                getArrow(changeValue)
              }
              <i>
                {format === 'money' ? `${formatMoney(changeValue, 0)}` : format === 'round' ? Math.round(changeValue) : changeValue}
              </i>
              {hasChangePercent &&
                <div style={{ fontSize: 14, marginLeft: 3 }}>
                  <span>({changePercent}%)</span>
                  <i> {changeTitle}</i>
                </div>
              }
            </div>
          }
        </div>
      </div>
      {hasFilter &&
        <div className="my-select-container">
          <MySelect value={filterValue} onChange={(e) => onFilterChange(e.target.value)}>
            <MySelect.Option disabled>-- please select --</MySelect.Option>
            {
              filterOptions?.map(({ value, title }, index) => <MySelect.Option key={index} value={value} >{title}</MySelect.Option>)
            }
          </MySelect>
        </div>
      }
    </div>
  );
};

export default ChartInfo;
