import PropTypes from 'prop-types';


function ShowHide({ show, placeholder = null, children }) {
  // console.log('');
  // console.log('%c ShowHide()', 'color:pink');
  // console.log('%c show', 'color:pink', show);
  // console.log('%c placeholder', 'color:pink', placeholder);
  // console.log('%c children', 'color:pink', children);

  return show ? children : placeholder;
}

ShowHide.propTypes = {
  show: PropTypes.bool.isRequired,
  placeholder: PropTypes.node,
  children: PropTypes.node.isRequired
}

export default ShowHide;
