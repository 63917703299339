import Login from './login';
import Summary from './summary';
import Assets from './assets';
import ContactForm from './contact';
import Contributions from './contributions';
import Disbursements from './disbursements';
import Disclosures from './disclosures';
import Documents from './documents';
import Forms from './forms';
import GrantDistributionForm from './form-grant-distribution';
import Privacy from './privacy';
import Users from './users';
import TermsOfUse from './terms-of-use';


const Views = {
  Login,
  Summary,
  Assets,
  ContactForm,
  Contributions,
  Disbursements,
  Disclosures,
  Documents,
  Forms,
  GrantDistributionForm,
  Privacy,
  TermsOfUse,
  Users,
};

export default Views;
