import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { parseStateChartData } from '../../../redux/services/disbursements-service';
import { elementStyles } from '../../../styles/mainStyle';
import MapChart from '../../shared/us-map';
import ChartInfo from '../../shared/big-chart-info';
import StateSideChart from '../../shared/state-side-chart';


const MapRow = () => {
  // console.log('');
  // console.log('%c disbursements/MapRow()', 'color:violet');

  const disbursements = useSelector(state => state.disbursements.data.disbursements);
  const [filterValue, setFilterValue] = useState('months:6');
  const chartData = parseStateChartData(disbursements, filterValue);
  // const chartData = useMemo(() => parseChartData(disbursements, filterValue), [disbursements, filterValue]); // going to fire * because of disbursements?

  if (!chartData) return null;

  // console.log('%c disbursements', 'color:violet', JSON.parse(JSON.stringify(disbursements)));
  // console.log('%c filterValue', 'color:violet', filterValue);
  // console.log('%c chartData', 'color:violet', JSON.parse(JSON.stringify(chartData)));

  return (
    <Grid container direction="row" justify="space-between" alignItems="stretch">
      <Grid item md={9} xs={12} style={elementStyles.column}>
        <ChartInfo
          title="Grants by Location"
          subtitle={`(${chartData.filter.title})`}
          total={chartData.summary.count}
          changeValue={chartData.summary.changeCount}
          changePercent={chartData.summary.changeCountPercent}
          changeTitle={chartData.summary.changeTitle}
          filterOptions={chartData.filterOptions}
          onFilterChange={setFilterValue}
          dataType={'Grants Disbursed'}
          format='round' />
        <MapChart
          data={chartData.data}
          buckets={chartData.buckets}
          type="Grants" />
      </Grid>
      <Grid item md={3} xs={12} style={elementStyles.sideColumn}>
        <StateSideChart
          sectionTitle={'State Grant Totals'}
          data={chartData.data}
          filter={chartData.filter} />
      </Grid>
    </Grid>
  );
};

export default MapRow;
