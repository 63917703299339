import { assetsTypes } from '../actions/assets-actions';

const initialState = {
  data: null,
  loading: true,
  message: '',
  status: null
};

const AssetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case assetsTypes.GET_ASSET_PAGE:
      return {
        ...state,
        data: action.payload,
        loading: false,
        status: action.status
      };

    case assetsTypes.ASSETS_ERROR:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status
      };

    case assetsTypes.ASSET_PAGE_LOADING:
      return {
        ...state,
        loading: true,
        status: null
      };

    default:
      return state;
  }
}

export default AssetsReducer;
