import axios from 'axios';
import { getUrl, getConfig } from '../redux-helpers';
import { parseContributions } from '../services/contribution-service';

export const contributionTypes = {
  GET_CONTRIBUTIONS_PAGE: 'GET_CONTRIBUTIONS_PAGE',
  CONTRIBUTIONS_PAGE_LOADING: 'CONTRIBUTIONS_PAGE_LOADING',
  CONTRIBUTION_ERROR: 'CONTRIBUTION_ERROR'
};

export const getContributionsPage = () => (dispatch) => {
  dispatch(setContributionsPageLoading());

  axios
    .get(getUrl('donations'), getConfig())
    .then((res) => {
      dispatch({
        type: contributionTypes.GET_CONTRIBUTIONS_PAGE,
        payload: parseContributions(res.data),
        status: res.status
      });
    })
    .catch((error) => {
      console.log('');
      console.log('%c ERROR()', 'color:orangered');
      console.log('%c error', 'color:orangered', error);
      dispatch({
        type: contributionTypes.CONTRIBUTION_ERROR,
        message: error.response?.data.message,
        status: error.response?.status
      });
    });
};

export const clearContributionsPage = () => (dispatch) => {
  dispatch({
    type: contributionTypes.GET_CONTRIBUTIONS_PAGE,
    payload: null,
  });
};

export const setContributionsPageLoading = () => ({
  type: contributionTypes.CONTRIBUTIONS_PAGE_LOADING
});
