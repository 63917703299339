import React, { useState, useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import mainStyle from '../../styles/mainStyle';
import { Collapse } from '@material-ui/core';
import '../../styles/accordion.scss';

const styles = {
  root: {
    borderTop: '2px solid ' + mainStyle.greenShade1
  },
  titleContainer: {
    fontWeight: 700,
    fontSize: '20px',
    height: '50px',
    paddingTop: '25px'
  },
  title: {
    marginLeft: '15px'
  },
  aiRoot: {
    height: 'auto',
    borderBottom: '1px solid #d6d6d6'
  }
};

export const AccordionItem = ({ title, data }) => {
  // console.log('');
  // console.log('%c AccordionItem()', 'color:yellow');
  // console.log('%c title', 'color:yellow', title);
  // console.log('%c data', 'color:yellow', data);

  const [open, setOpen] = useState(false);
  const [wasOpened, setWasOpened] = useState(false);

  useEffect(() => {
    if (open) {
      setWasOpened(true);
    }
  }, [open]);

  return (
    <div
      style={styles.aiRoot}
      className={open ? 'accordion-root' : 'accordion-btn'}
    >
      <div
        style={styles.titleContainer}
        className="flex accordion-btn"
        onClick={() => setOpen(!open)}
      >
        <div>
          <AddIcon
            className={open ? 'rotate-45' : wasOpened ? 'rotate-back' : ''}
            style={{ color: mainStyle.blueShade1 }}
          />
        </div>
        <div style={styles.title}>{title}</div>
      </div>
      <Collapse in={open}>
        <div className="open-accordion-body">{data}</div>
      </Collapse>
    </div>
  );
};

export default function Accordion({ list }) {
  // console.log('');
  // console.log('%c Accordion()', 'color:gold');
  // console.log('%c list', 'color:gold', list);

  return (
    <div style={styles.root}>
      {
        list?.map((item, idx) => <AccordionItem {...item} key={idx} />)
      }
    </div>
  );
}

Accordion.propTypes = {
  list: PropTypes.array.isRequired
};
