import React, { Fragment } from 'react';
import MonthGridRow from './month-grid-row';
import StateGridRow from './state-grid-row';


function Charts() {
  // console.log('');
  // console.log('%c contributions/Charts()', 'color:lightblue');

  return (
    <Fragment>
      <MonthGridRow />
      <StateGridRow />
    </Fragment>
  );
}

export default Charts;
