import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function useStoreFiles(prefix) {
  // console.log('');
  // console.log('%c useStoreFiles()', 'color:salmon');

  const isLoading = useSelector(state => state.files.loading);
  const storeData = useSelector((state) => state.files.data);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    // console.log('');
    // console.log(`%c useStoreFiles()['${prefix}', storeData]`, 'color:salmon');
    // console.log('%c prefix', 'color:salmon', prefix);
    // console.log('%c storeData', 'color:salmon', storeData);
    // console.log('%c storeData?.filesTree', 'color:salmon', storeData?.filesTree);
    if (!storeData) return;
    if (prefix) {
      setFiles(storeData.filesTree.filterFolders(prefix));
    } else {
      setFiles(storeData.filesTree);
    }
  }, [prefix, storeData]);

  return [ files, isLoading ];
}

export default useStoreFiles;
