import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import SummaryChartInfo from '../../summary/charts/summary-chart-info';
import DistributionChart from '../../summary/charts/distribution-chart';
import PublicSupportChart from '../../summary/charts/public-support-chart';
// import { formatMoney } from '../../../utils/misc';


const DistributionRequirements = ({ title = null, padTitle = false }) => {
  // console.log('');
  // console.log('%c disbursements/distribution-requirements', 'color:orange');
  // console.log('%c padTitle', 'color:orange', padTitle);
  // console.log('%c props', 'color:orange', props);

  const account = useSelector(state => state.client.data);
  // console.log('%c account', 'color:orange', account);
  // console.log('%c account.isPublic', 'color:orange', account.isPublic);
  // console.log('%c account.distributionRequirements', 'color:orange', account.distributionRequirements);


  if (account.isPublic) {
    const currentPercent = Math.floor(account.distributionRequirements.remaining * 100);
    const requiredPercent = Math.floor(account.distributionRequirements.amount * 100);

    return (
    <div className="flex flex-col">
      <SummaryChartInfo
          isAlt
          title={`${account.distributionRequirements.year} Public Support Test (PST)`}
          amountChangeTitle="PST Status for the Current Tax Year"
          paddingTop={padTitle} />

      <PublicSupportChart
          currentPercent={currentPercent}
          requiredPercent={requiredPercent} />
    </div>
    );
  }

  return (
    <Fragment>
        <SummaryChartInfo
          isAlt
          title={`${account.distributionRequirements.year} Minimum Distribution Requirement`}
          totalAmount={Math.max(account.distributionRequirements.remaining, 0)}
          amountChangeTitle={<span>Remaining Distribution Required <span className="orange-s4"><strong>*</strong></span></span>}
          paddingTop={padTitle} />

        <DistributionChart
          currentAmount={account.distributionRequirements.current}
          recommendedAmount={account.distributionRequirements.remaining}
          requiredAmount={account.distributionRequirements.amount} />
    </Fragment>
  );
};

export default DistributionRequirements;
