import { combineReducers } from "redux";
import app from './reducers/app-reducer';
import assets from "./reducers/assets-reducer";
import client from "./reducers/client-reducer";
import contributions from "./reducers/contribution-reducer";
import files from "./reducers/files-reducer";
import disbursements from "./reducers/disbursement-reducer";
import login from "./reducers/login-reducer";

export default combineReducers({
  app,
  assets,
  client,
  contributions,
  files,
  disbursements,
  login,
});
