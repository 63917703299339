import mainStyle from '../../styles/mainStyle';
import { getFilter, getFilteredData, getFilterOptions, fixedNum, createMonthYearMap } from './service-helpers';
import { shortMonths } from '../../../src/utils/constants';


export const parseAssets = (data) => {
  // console.log('');
  // console.log('%c parseAssets()', 'color:fuchsia');
  // console.log('%c data', 'color:fuchsia', data);

  if (data.length === 0) {
    return {
      assets: [],
      summary: {
        asOf: new Date(),
        total: 0,
        cash: 0,
        investments: 0,
        other: 0,
        changeOverMonthTotal: 0,
        changeOverMonthTotalPercent: 0,
        changeOverSixMonthsTotal: 0,
        changeOverSixMonthsTotalPercent: 0,
        changeOverYearTotal: 0,
        changeOverYearTotalPercent: 0,
        year: 0
      },
      values: []
    };
  }

  const currentDate = new Date();
  const ordinalMonth = currentDate.getMonth() + 1;

  const values = data.map(v => {
    const date = (v.year === currentDate.getFullYear() && v.month === ordinalMonth) ? currentDate : new Date(v.year, v.month, 0);
    return {
      account: v.account,
      accountType: v.account_type,
      institution: v.institution,
      amount: +v.amount,
      at: date,
      month: date.getMonth(),
      year: date.getFullYear()
    };
  }).sort((a, b) => b.date - a.date);
  // console.log('%c values', 'color:fuchsia', values);

  const assets = parseAssetsValues(values);
  // console.log('%c assets', 'color:fuchsia', assets);

  const summary = parseSummary(values, assets);
  // console.log('%c summary', 'color:fuchsia', summary);

  // const maxDate = new Date(Math.max(...values.map(v => v.at)));
  // console.log('%c maxDate', 'color:fuchsia', maxDate);

  // const summary = { ...assets[0], asOf: maxDate };
  // console.log('%c summary', 'color:fuchsia', summary);

  return {
    assets,
    summary,
    values
  };
};

const parseAssetsValues = (data) => {
  // console.log('');
  // console.log('%c parseAssetsValues()', 'color:fuchsia');
  // console.log('%c data', 'color:fuchsia', data);

  // data.sort((a, b) => b.month - a.month).sort((a, b) => b.year - a.year);

  const result = createMonthYearMap(data);
  // console.log('%c result:REDUCE', 'color:fuchsia', JSON.parse(JSON.stringify(result)));

  const maxDate = new Date(Math.max(...data.map(v => v.at)));
  // console.log('%c maxDate', 'color:fuchsia', maxDate);

  const minDate = new Date(Math.min(...data.map(v => v.at)));
  // console.log('%c minDate', 'color:fuchsia', minDate);

  const maxDateRangeValues = data.filter(v => v.year === maxDate.getFullYear() && v.month === maxDate.getMonth());
  // console.log('%c maxDateRangeValues', 'color:fuchsia', maxDateRangeValues);

  const minDateRangeValues = data.filter(v => v.year === minDate.getFullYear() && v.month === minDate.getMonth());
  // console.log('%c minDateRangeValues', 'color:fuchsia', minDateRangeValues);

  // sum values
  for (const entry of result) {
    // console.log('');
    // console.log('%c entry.at', 'color:pink', entry.at);

    const rangeValues = data.filter(v => v.year === entry.year && v.month === entry.month);
    // console.log('%c rangeValues', 'color:pink', rangeValues);

    if (rangeValues.length === 0) {
      if (entry.at > maxDate) {
        maxDateRangeValues.forEach(item => {
          rangeValues.push({
            ...item,
            at: entry.at,
            month: entry.month,
            year: entry.year,
          });
        });
      } else {
        minDateRangeValues.forEach(item => {
          rangeValues.push({
            ...item,
            amount: 0,
            at: entry.at,
            month: entry.month,
            year: entry.year,
          });
        });
      }
    }
    // console.log('%c rangeValues', 'color:hotpink', rangeValues);

    entry.cash = fixedNum(rangeValues.filter(v => v.accountType.toLowerCase() === 'cash').reduce((accumulator, item) => accumulator + item.amount, 0));
    entry.investments = fixedNum(rangeValues.filter(v => v.accountType.toLowerCase() === 'investment').reduce((accumulator, item) => accumulator + item.amount, 0));
    entry.other = fixedNum(rangeValues.filter(v => v.accountType.toLowerCase() !== 'cash' && v.accountType.toLowerCase() !== 'investment').reduce((accumulator, item) => accumulator + item.amount, 0));
    entry.total = entry.cash + entry.investments + entry.other;
    entry.values = rangeValues;
  }
  result.sort((a, b) => b.month - a.month).sort((a, b) => b.year - a.year);
  // console.log('%c result:SUM', 'color:fuchsia', JSON.parse(JSON.stringify(result)));

  // calculate offset values
  for (const entry of result) {
    const targetDate = new Date(entry.year, entry.month, 1);

    targetDate.setMonth(targetDate.getMonth() - 1);
    const previousByMonth = result.find(v => v.year === targetDate.getFullYear() && v.month === targetDate.getMonth());

    if (previousByMonth) {
      entry.changeOverMonthTotal = fixedNum(entry.total - previousByMonth.total);
      entry.changeOverMonthTotalPercent = entry.changeOverMonthTotal === 0 ? 0 :fixedNum((Math.abs(entry.changeOverMonthTotal) / previousByMonth.total) * 100);
      if (Math.sign(entry.changeOverMonthTotal) === -1) entry.changeOverMonthTotalPercent = -entry.changeOverMonthTotalPercent;
    }

    targetDate.setMonth(targetDate.getMonth() - 5);
    const previousBySixMonths = result.find(v => v.year === targetDate.getFullYear() && v.month === targetDate.getMonth());

    if (previousBySixMonths) {
      entry.changeOverSixMonthsTotal = fixedNum(entry.total - previousBySixMonths.total);
      entry.changeOverSixMonthsTotalPercent = entry.changeOverSixMonthsTotal === 0 ? 0 : fixedNum((Math.abs(entry.changeOverSixMonthsTotal) / previousBySixMonths.total) * 100);
      if (Math.sign(entry.changeOverSixMonthsTotal) === -1) entry.changeOverSixMonthsTotalPercent = -entry.changeOverSixMonthsTotalPercent;
    }

    const previousByYear = result.find(v => v.year === (entry.year - 1) && v.month === entry.month);

    if (previousByYear) {
      entry.changeOverYearTotal = fixedNum(entry.total - previousByYear.total);
      entry.changeOverYearTotalPercent = entry.changeOverYearTotal === 0 ? 0 :fixedNum((Math.abs(entry.changeOverYearTotal) / previousByYear.total) * 100);
      if (Math.sign(entry.changeOverYearTotal) === -1) entry.changeOverYearTotalPercent = -entry.changeOverYearTotalPercent;
    } else {
      const lastEntry = result[result.length - 1];
      const withinLastYear = (entry.at.getTime() - lastEntry.at.getTime()) < 31536000000;

      if (withinLastYear) {
        entry.changeOverYearTotal = entry.total;
        entry.changeOverYearTotalPercent = 100;
      }
    }
  }

  // result.sort((a, b) => b.month - a.month).sort((a, b) => b.year - a.year);
  // console.log('%c result', 'color:fuchsia', result);

  return result;
};

export const parseChartData = (data, filterValue) => {
  // console.log('');
  // console.log('%c parseChartData()', 'color:fuchsia');
  // console.log('%c data', 'color:fuchsia', data);
  // console.log('%c filterValue', 'color:fuchsia', filterValue);

  if (!data?.length) return;

  const filterOptions = getFilterOptions(data);
  const filter = getFilter(filterValue, filterOptions);
  const filteredData = getFilteredData(data, filter);

  // console.log('%c filterOptions', 'color:orange', filterOptions);
  // console.log('%c filter', 'color:orange', filter);
  // console.log('%c filteredData', 'color:orange', filteredData);

  const labelsCategories = ['Total', 'Investments', 'Cash'];
  const legend = [
    { title: labelsCategories[0], amount: filteredData[0].total },
    { title: labelsCategories[1], amount: filteredData[0].investments },
    { title: labelsCategories[2], amount: filteredData[0].cash }
  ];
  const series = [
    { name: labelsCategories[0], data: filteredData.map(v => ({ x: shortMonths[v.month], y: v.total })).reverse(), color: chartColors[0] },
    { name: labelsCategories[1], data: filteredData.map(v => ({ x: shortMonths[v.month], y: v.investments })).reverse(), color: chartColors[1] },
    { name: labelsCategories[2], data: filteredData.map(v => ({ x: shortMonths[v.month], y: v.cash })).reverse(), color: chartColors[2] }
  ];

  const summary = {
    total: filteredData[0].total,
    change: filter.value === 'months:6' ? filteredData[0].changeOverSixMonthsTotal : filteredData[0].changeOverYearTotal,
    changePercent: filter.value === 'months:6' ? filteredData[0].changeOverSixMonthsTotalPercent : filteredData[0].changeOverYearTotalPercent,
    changeTitle: filter.type === 'year' ? 'YoY Change' : filter.type === 'months' ? `${filter.count} Month Change` : null
  };
  // console.log('%c summary', 'color:orange', summary);

  const portfolio = parsePortfolio(filteredData);
  // console.log('%c portfolio', 'color:orange', portfolio);

  return {
    data: filteredData,
    filter,
    filterOptions,
    legend,
    portfolio,
    series,
    summary
  }
};

const parsePortfolio = (data) => {
  // console.log('');
  // console.log('%c parsePortfolio()', 'color:yellow');
  // console.log('%c data', 'color:yellow', data);
  // console.log('%c data[0].values', 'color:yellow', data[0].values);

  const result = [];

  for (const item of data[0].values) {
    const existing = result.find(v => v.account === item.account && v.accountType === item.accountType && v.institution === item.institution);
    if (existing) {
      existing.total = fixedNum(existing.total + item.amount);
      existing.count += 1;
    } else {
      result.push({
        name: `${item.institution} ${item.account}`.trim(),
        account: item.account,
        accountType: item.accountType,
        institution: item.institution,
        total: item.amount,
        count: 1
      });
    }
  }
  result.sort((a, b) => b.total - a.total);

  const rangeTotal = fixedNum(result.reduce((accumulator, item) => accumulator + item.total, 0));
  for (const entry of result) {
    entry.percent = rangeTotal === 0 ? 0 : fixedNum((entry.total / rangeTotal) * 100);
  }

  // const range = (({month, quarter, year}) => ({month, quarter, year}))(data[0]);
  // console.log('%c range', 'color:yellow', range);

  // const rangeAssets = data.filter(a => a.year === range.year && a.month === range.month);
  // console.log('%c rangeAssets', 'color:yellow', rangeAssets);
  // console.table(rangeAssets);

  // const rangeTotal = fixedNum(rangeAssets.reduce((accumulator, item) => accumulator + item.amount, 0));
  // console.log('%c rangeTotal', 'color:yellow', rangeTotal);

  // const result = rangeAssets.map(asset => ({
  //     name: `${asset.institution} ${asset.account}`.trim(),
  //     amount: asset.amount,
  //     percent: fixedNum((asset.amount / rangeTotal) * 100)
  //   })
  // ).sort(
  //   (a, b) => (a.name > b.name) ? 1 : -1
  // ).sort(
  //   (a, b) => b.amount - a.amount
  // );

  // console.log('%c result', 'color:yellow', result);
  // console.table(result);

  return result;
};

export const parseSummary = (values, assets) => {
  // console.log('');
  // console.log('%c assetsService:parseSummary()', 'color:hotpink');
  // console.log('%c values', 'color:hotpink', values);
  // console.log('%c assets', 'color:hotpink', assets);

  const maxDate = values.length ? new Date(Math.max(...values.map(v => v.at))) : new Date();
  // console.log('%c maxDate', 'color:hotpink', maxDate);

  const currentDate = new Date();
  // console.log('%c currentDate', 'color:hotpink', currentDate);

  const currentData = assets.find(v => v.year === currentDate.getFullYear() && v.month === currentDate.getMonth());
  // console.log('%c currentData', 'color:hotpink', currentData);

  return {
    asOf: maxDate,
    total: currentData ? currentData.total : 0,
    cash: currentData ? currentData.cash : 0,
    investments: currentData ? currentData.investments : 0,
    other: currentData ? currentData.other : 0,
    changeOverMonthTotal: currentData ? currentData.changeOverMonthTotal : 0,
    changeOverMonthTotalPercent: currentData ? currentData.changeOverMonthTotalPercent : 0,
    changeOverSixMonthsTotal: currentData ? currentData.changeOverSixMonthsTotal : 0,
    changeOverSixMonthsTotalPercent: currentData ? currentData.changeOverSixMonthsTotalPercent : 0,
    changeOverYearTotal: currentData ? currentData.changeOverYearTotal : 0,
    changeOverYearTotalPercent: currentData ? currentData.changeOverYearTotalPercent : 0,
    // changeOverYtdTotal: currentData ? currentData.changeOverYtdTotal : 0,
    // changeOverYtdTotalPercent: currentData ? currentData.changeOverYtdTotalPercent : 0,
    year: currentData ? currentData.year : 0,
  };
}

export const chartColors = [mainStyle.greenShade2, mainStyle.blueShade5, mainStyle.blueShade3, mainStyle.orangeShade2];

export { fixedNum, getFilter };
