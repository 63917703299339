import React from "react";
import PropTypes from "prop-types";
import { Divider } from "@material-ui/core";

export default function TitleDivider(props) {
  const { titleStyle, dividerStyle, title } = props;
  return (
    <div>
      <div>
        <h3
          style={{
            fontSize: "18px",
            fontWeight: 400,
            lineHeight: "5px",
            ...titleStyle,
          }}
        >
          <b>{title}</b>
        </h3>
      </div>
      <div>
        <Divider
          style={{
            width: "40px",
            height: "2px",
            backgroundColor: "#b4b9bf",
            marginBottom: "14px",
            ...dividerStyle,
          }}
        />
      </div>
    </div>
  );
}

TitleDivider.propTypes = {
  title: PropTypes.string.isRequired,
  titleStyle: PropTypes.object,
  dividerStyle: PropTypes.object,
};
