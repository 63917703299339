import React from 'react';
import { useSelector } from 'react-redux';
import mainStyle from '../../../styles/mainStyle';
import { formatMoney } from '../../../utils/misc';
import HeaderWrapper from '../../shared/header-wrapper';
import RecentData from '../../shared/recent-data';


const DonationNumbers = (props) => {
  const { amount, donations } = props;
  return (
    <div>
      <div>
        <span
          style={{ fontSize: '18px', fontWeight: 600, color: mainStyle.headerTextColorForDark }}>
          {formatMoney(amount, 0)}
        </span>
        {' '}
        <i style={{ fontSize: '16px', color: '#FFFFFFA5' }}>donation average</i>
      </div>
      <div>
        <span
          style={{ fontSize: '18px', fontWeight: 600, color: mainStyle.headerTextColorForDark }}>
          {donations}
        </span>
        <i style={{ fontSize: '16px', color: '#FFFFFFA5' }}> donations YTD</i>
      </div>
    </div>
  );
};

function Header() {
  // console.log('');
  // console.log('%c contributions/Header()', 'color:lightblue');

  const summary = useSelector(state => state.contributions.data?.summary);
  // console.log('%c summary', 'color:lightblue', summary);

  const data = [
    {
      title: 'Contributions YTD',
      component: (
        <RecentData
          total={summary?.yearTotal}
          date={summary?.asOf}
          amountChange={summary?.changeOverYearTotal}
          amountChangePercent={summary?.changeOverYearTotalPercent}
          changeType="YoY Change" />
      )
    },
    {
      title: 'Contribution Numbers',
      component: (
        <DonationNumbers
          amount={summary?.yearAverage}
          donations={summary?.yearCount} />
      )
    }
  ];

  return (
    <HeaderWrapper
      title="CONTRIBUTIONS"
      date={summary?.asOf}
      data={data} />
  );
}

export default Header;
