import axios from 'axios';
import { getUrl, getConfig } from '../redux-helpers';
import { parseDisbursements } from '../services/disbursements-service';

export const disbursementTypes = {
  GET_DISBURSEMENTS_PAGE: 'GET_DISBURSEMENTS_PAGE',
  DISBURSEMENTS_PAGE_LOADING: 'DISBURSEMENTS_PAGE_LOADING',
  DISBURSEMENT_ERROR: 'DISBURSEMENT_ERROR'
};

export const getDisbursementsPage = () => (dispatch) => {
  dispatch(setDisbursementsPageLoading());

  axios
    .get(getUrl('grants'), getConfig())
    .then((res) => {
      dispatch({
        type: disbursementTypes.GET_DISBURSEMENTS_PAGE,
        payload: parseDisbursements(res.data),
        status: res.status
      });
    })
    .catch((error) => {
      console.log('');
      console.log('%c ERROR()', 'color:orangered');
      console.log('%c error', 'color:orangered', error);
      dispatch({
        type: disbursementTypes.DISBURSEMENT_ERROR,
        message: error.response?.data.message,
        status: error.response?.status
      });
    });
};

export const clearDisbursementsPage = () => (dispatch) => {
  dispatch({
    type: disbursementTypes.GET_DISBURSEMENTS_PAGE,
    payload: null,
  });
};

export const setDisbursementsPageLoading = () => ({
  type: disbursementTypes.DISBURSEMENTS_PAGE_LOADING
});
