import React from 'react';
import { useSelector } from 'react-redux';
import Layout from '../components/layout/layout';
import Charts from '../components/summary/charts/';
import Header from '../components/summary/header/header';

const Summary = () => {
  const account = useSelector((state) => state.client.data);
  const assets = useSelector((state) => state.assets.data);
  const contributions = useSelector((state) => state.contributions.data);
  const disbursements = useSelector((state) => state.disbursements.data);

  const dateAsOf = Math.max(assets?.summary.asOf, contributions?.summary.asOf, disbursements?.summary.asOf);

  return (
    <Layout>
      <Header
        asOf={dateAsOf} />
      <Charts
        account={account}
        assets={assets}
        contributions={contributions}
        disbursements={disbursements} />
    </Layout>
  );
};

export default Summary;
