import React from "react";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

export default function ColorLinearProgress(props) {

  const { backgroundColor, barColor, ...rest } = props;

  const BorderLinearProgress = withStyles(() => ({
    root: {
      height: props.height || 10,
      borderRadius: props.borderRadius || 5,
    },
    colorPrimary: {
      backgroundColor: backgroundColor || "#eee",
    },
    bar: {
      borderRadius: props.borderRadius || 5,
      backgroundColor: barColor || "#1a90ff",
    },
  }))(LinearProgress);

  return (
    <BorderLinearProgress
      variant="determinate"
      value={props.value}
      {...rest}
    />
  );
}
