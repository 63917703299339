import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, Divider, List, ListItem } from '@material-ui/core';
import { Link } from 'react-router-dom';

export function HamburgerToggle({ open, toggle }) {
  return (
    <div
      className={`hamburger-menu ${open ? 'hamburger-menu-open' : ''}`}
      onClick={toggle}
    >
      <div className="hamburger-menu-btn"></div>
    </div>
  );
}
HamburgerToggle.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func.isRequired
};

function DropdownItem({ label, href }) {
  return (
    <ListItem className="hamburger-dropdown-li">
      <Link to={href} className="hamburger-dropdown-link blue-s1">
        {label}
      </Link>
    </ListItem>
  );
}
DropdownItem.propTypes = {
  label: PropTypes.string,
  href: PropTypes.string.isRequired
};

export default function HamburgerMenu({ open, list }) {
  return (
    <Collapse in={open}>
      <List>
        {list.map((item, idx) => {
          if (item.type === 'divider') {
            return (
              <Divider
                key={`divider${idx}`}
                style={{ width: '100%', background: item.color }}
              />
            );
          } else if (item.type === 'component') {
            return (
              <div className="hamburger-dropdown-li" key={`component${idx}`}>{item.component}</div>
            );
          } else {
            return <DropdownItem key={item.label} {...item} />;
          }
        })}
      </List>
    </Collapse>
  );
}
HamburgerMenu.propTypes = {
  list: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired
};
HamburgerMenu.Toggle = HamburgerToggle;
