import { CsvBuilder } from 'filefy';
import mainStyle from '../../styles/mainStyle';
import { shortMonths } from '../../utils/constants';
import stateHash from './json/state-hash.json';
import { getBuckets, getFilter, getFilteredData, getFilteredValues, getFilterOptions, fixedNum, parseAsMonthYearSummaries } from './service-helpers';
import { formatFilename } from '../../utils/misc';

const dateNow = new Date();
// const dateNow = new Date(2021, 11, 23);
// console.log('%c dateNow', 'color:hotpink', dateNow);


export const parseDisbursements = (data) => {
  // console.log('');
  // console.log('%c parseDisbursements()', 'color:fuchsia');
  // console.log('%c data', 'color:fuchsia', data);

  if (data.length === 0) {
    return {
      assets: [],
      summary: {
        asOf: dateNow,
        summaryAt: dateNow,

        changeOverYearCount: 0,
        changeOverYearCountPercent: 0,
        changeOverYearTotal: 0,
        changeOverYearTotalPercent: 0,

        changeOverYtdCount: 0,
        changeOverYtdCountPercent: 0,
        changeOverYtdTotal: 0,
        changeOverYtdTotalPercent: 0,

        year: dateNow.getFullYear(),
        yearAverage: 0,
        yearCount: 0,
        yearTotal: 0,

        ytdAverage: 0,
        ytdCount: 0,
        ytdTotal: 0,
        ytdMaxValue: 0,
        ytdMinValue: 0,
        ytdValues: 0,

        previousYear: (dateNow.getFullYear() - 1),
        previousYearAverage: 0,
        previousYearCount: 0,
        previousYearTotal: 0,

        previousYtdAverage: 0,
        previousYtdCount: 0,
        previousYtdTotal: 0,
      },
      values: []
    };
  }

  const values = data?.map(v => {
    const date = new Date(v.grant_date);
    return {
      // ...v,
      amount: +v.amount,
      category: v.grant_category,
      recipient: {
        name: v.recipient_name,
        poc: v.recipient_poc,
        address: v.recipient_address,
        city: v.recipient_city,
        stateAbbr: stateHash[v.recipient_state] ? v.recipient_state : null,
        stateTitle: stateHash[v.recipient_state] || 'Other',
        zip: v.recipient_zip,
        country: v.recipient_country
      },
      at: date,
      month: date.getMonth(),
      year: date.getFullYear(),
      purpose: v.purpose,
      isOutstanding: v.is_outstanding,
      isReturned: v.is_returned
    };
  }).sort((a, b) => b.at - a.at);
  // console.log('%c values', 'color:fuchsia', values);

  const disbursements = parseAsMonthYearSummaries(values, 'disbursements');
  // console.log('%c disbursements', 'color:fuchsia', disbursements);

  const summary = parseSummary(values, disbursements);
  // console.log('%c summary', 'color:fuchsia', summary);

  return {
    disbursements,
    summary,
    values
  };
};

export const parseChartData = (data, filterValue) => {
  // console.log('');
  // console.log('%c parseChartData()', 'color:hotpink');
  // console.log('%c data', 'color:hotpink', data);
  // console.log('%c filterValue', 'color:hotpink', filterValue);

  if (!data?.length) return;

  const filterOptions = getFilterOptions(data);
  const filter = filterOptions.find(v => v.value === filterValue);
  const filteredData = getFilteredData(data, filter);

  // console.log('%c filterOptions', 'color:hotpink', filterOptions);
  // console.log('%c filter', 'color:hotpink', filter);
  // console.log('%c filteredData', 'color:hotpink', JSON.parse(JSON.stringify(filteredData)));

  const keys = [];

  const result = filteredData.map(v => {
    const item = {
      id: `${shortMonths[v.month]} ${v.year}`,
      label: `${shortMonths[v.month]}`,
      total: v.total,
      count: v.count,
      month: v.month,
      year: v.year
    };

    if (v.values.length) {
      for (const value of v.values) {
        // const keyName = value.recipient.poc ? `${value.recipient.name} / ${value.recipient.poc}` : value.recipient.name;
        const keyName = value.recipient.name;
        if (!keys.includes(keyName)) keys.push(keyName);
        item[keyName] = value.amount;
      }
    }

    return item;
  }).reverse();

  const summary = {
    total: filteredData.reduce((acc, item) => fixedNum(acc + item.total), 0),
    count: filteredData.reduce((acc, item) => fixedNum(acc + item.count), 0),
    change: filter.value === 'months:6' ? filteredData[0].changeOverSixMonthsTotal : filteredData[0].changeOverYearTotal,
    changePercent: filter.value === 'months:6' ? filteredData[0].changeOverSixMonthsTotalPercent : filteredData[0].changeOverYearTotalPercent,
    changeCount: filter.value === 'months:6' ? filteredData[0].changeOverSixMonthsCount : filteredData[0].changeOverYearCount,
    changeCountPercent: filter.value === 'months:6' ? filteredData[0].changeOverSixMonthsCountPercent : filteredData[0].changeOverYearCountPercent,
    changeTitle: filter.value === 'months:6' ? `Versus Previous 6 Months` : filter.type === 'months' ? `${filter.count} Month Change` : filter.type === 'year' ? 'YoY Change' : null
  }
  summary.average = summary.count ? fixedNum(summary.total / summary.count) : 0;

  // console.log('%c result', 'color:hotpink', result);
  // console.log('%c summary', 'color:hotpink', summary);

  const legend = [
    { title: 'Total Grants', amount: summary.count },
    { title: 'Average Grant', amount: summary.average, format: 'money' }
  ];
  const series = [];
  const seriesCategories = [];

  let itemIndex = filteredData.length;
  for (const item of filteredData) {
    itemIndex--;
    // console.log('');
    // console.log('%c item', 'color:deeppink', item);
    // console.log('%c itemIndex', 'color:deeppink', itemIndex);
    seriesCategories.push(`${shortMonths[item.month]}`);
    // console.log('');
    for (const value of item.values) {
      const name = value.recipient.name;
      // console.log('%c name', 'color:deeppink', name);
      // console.log('%c value', 'color:deeppink', value);
      if (!series.find(v => v.name === name)) {
        const initialSeries = {
          name,
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        };
        initialSeries.data.length = filteredData.length;
        series.push(initialSeries);
      }
      const valueIndex = series.findIndex(v => v.name === name);
      series[valueIndex].data[itemIndex] = value.amount;
    }
  }
  seriesCategories.reverse();
  series.sort((a, b) => a.name.localeCompare(b.name));

  // console.log('%c legend', 'color:hotpink', legend);
  // console.log('%c series', 'color:hotpink', series);
  // console.log('%c seriesCategories', 'color:hotpink', seriesCategories);

  const recipients = parseRecipients(filteredData);
  // console.log('%c recipients', 'color:hotpink', recipients);

  const categories = parseCategoryPercentages(filteredData);

  return {
    data: result,
    keys,
    filter,
    filterOptions,
    summary,
    categories,
    recipients,
    legend,
    series,
    seriesCategories
  };
};

export const parseStateChartData = (data, filterValue) => {
  // console.log('');
  // console.log('%c parseStateChartData()', 'color:salmon');
  // console.log('%c data', 'color:salmon', data);
  // console.log('%c filterValue', 'color:salmon', filterValue);

  if (!data?.length) return;

  const filterOptions = getFilterOptions(data);
  const filter = filterOptions.find(v => v.value === filterValue);
  const filteredData = getFilteredData(data, filter);

  // console.log('%c filterOptions', 'color:salmon', filterOptions);
  // console.log('%c filter', 'color:salmon', filter);
  // console.log('%c filteredData', 'color:salmon', filteredData);

  const result = [];

  for (const range of filteredData) {
    for (const item of range.values) {
      const existing = result.find(v => v.stateTitle === item.recipient.stateTitle);
      if (existing) {
        existing.total = fixedNum(existing.total + item.amount);
        existing.count += 1;
      } else {
        result.push({
          stateAbbr: item.recipient.stateAbbr,
          stateTitle: item.recipient.stateTitle,
          total: item.amount,
          count: 1
        });
      }
    }
  }
  // console.log('%c result', 'color:salmon', JSON.parse(JSON.stringify(result)));

  const rangeTotal = fixedNum(result.reduce((accumulator, item) => accumulator + item.total, 0));
  for (const item of result) {
    item.percent = fixedNum((item.total / rangeTotal) * 100);
  }
  result.sort((a, b) => a.stateTitle.localeCompare(b.stateTitle));
  // console.log('%c result', 'color:salmon', result);

  const summary = {
    total: result.reduce((acc, item) => fixedNum(acc + item.total), 0),
    count: result.reduce((acc, item) => fixedNum(acc + item.count), 0),
    change: filter.value === 'months:6' ? filteredData[0].changeOverSixMonthsTotal : filteredData[0].changeOverYearTotal,
    changePercent: filter.value === 'months:6' ? filteredData[0].changeOverSixMonthsTotalPercent : filteredData[0].changeOverYearTotalPercent,
    changeCount: filter.value === 'months:6' ? filteredData[0].changeOverSixMonthsCount : filteredData[0].changeOverYearCount,
    changeCountPercent: filter.value === 'months:6' ? filteredData[0].changeOverSixMonthsCountPercent : filteredData[0].changeOverYearCountPercent,
    changeTitle: filter.value === 'months:6' ? `Versus Previous 6 Months` : filter.type === 'months' ? `${filter.count} Month Change` : filter.type === 'year' ? 'YoY Change' : null
  }
  summary.average = summary.count ? fixedNum(summary.total / summary.count) : 0;
  // console.log('%c summary', 'color:salmon', summary);

  const buckets = getBuckets(result, 'count');
  // console.log('%c buckets', 'color:hotpink', buckets);

  return {
    data: result,
    filter,
    filterOptions,
    summary,
    buckets
  };
};

const parseCategoryPercentages = (data) => {
  // console.log('');
  // console.log('%c parseCategoryPercentages()', 'color:deeppink');
  // console.log('%c data', 'color:deeppink', data);

  const categories = [];

  for (const entry of data) {
    for (const item of entry.values) {
      let matched = categories.find(v => v.name.toLowerCase() === item.category.toLowerCase());
      if (matched) {
        matched.total += item.amount;
        // console.log('%c matched', 'color:yellow', matched);
      } else {
        const newCategory = { name: item.category, total: item.amount };
        // console.log('%c newCategory', 'color:yellow', newCategory);
        categories.push(newCategory);
      }
    }
  };
  // console.log('%c categories', 'color:deeppink', categories);

  const rangeTotal = fixedNum(categories.reduce((accumulator, item) => accumulator + item.total, 0));
  // console.log('%c rangeTotal', 'color:deeppink', rangeTotal);

  for (const item of categories) {
    item.percent = fixedNum((item.total / rangeTotal) * 100);
  }

  categories.sort((a, b) => b.total - a.total);

  return categories;
}

const parseRecipients = (data) => {
  // console.log('');
  // console.log('%c parseRecipients()', 'color:salmon');
  // console.log('%c data', 'color:salmon', data);

  const result = [];

  for (const range of data) {
    for (const item of range.values) {
      const existing = result.find(v => v.name === item.recipient.name && v.stateTitle === item.recipient.stateTitle);
      if (existing) {
        existing.total = fixedNum(existing.total + item.amount);
        existing.count += 1;
      } else {
        result.push({
          name: item.recipient.name,
          stateAbbr: item.recipient.stateAbbr,
          stateTitle: item.recipient.stateTitle,
          total: item.amount,
          count: 1
        });
      }
    }
  }
  result.sort((a, b) => b.total - a.total);
  // console.log('%c result', 'color:salmon', result);

  const rangeTotal = fixedNum(result.reduce((accumulator, item) => accumulator + item.total, 0));
  for (const recipient of result) {
    recipient.percent = fixedNum((recipient.total / rangeTotal) * 100);
  }

  return result;
};

export const parseSummary = (values, disbursements) => {
  // console.log('');
  // console.log('%c disbursementsService:parseSummary()', 'color:hotpink');
  // console.log('%c values', 'color:hotpink', values);
  // console.log('%c disbursements', 'color:hotpink', disbursements);

  const maxDate = new Date(Math.max(...values.map(v => v.at)));
  // console.log('%c maxDate', 'color:hotpink', maxDate);

  const currentDate = dateNow;
  // const currentDate = new Date(2020, 1, 1);
  // console.log('%c currentDate', 'color:hotpink', currentDate);

  const currentData = disbursements.find(v => v.year === currentDate.getFullYear() && v.month === currentDate.getMonth());
  // const currentData = disbursements.find(v => v.year === 2020 && v.month === currentDate.getMonth());
  // console.log('%c currentData', 'color:hotpink', currentData);

  return {
    asOf: maxDate,
    summaryAt: currentDate,

    changeOverYearCount: currentData.changeOverYearCount,
    changeOverYearCountPercent: currentData.changeOverYearCountPercent,
    changeOverYearTotal: currentData.changeOverYearTotal,
    changeOverYearTotalPercent: currentData.changeOverYearTotalPercent,

    changeOverYtdCount: currentData.changeOverYtdCount,
    changeOverYtdCountPercent: currentData.changeOverYtdCountPercent,
    changeOverYtdTotal: currentData.changeOverYtdTotal,
    changeOverYtdTotalPercent: currentData.changeOverYtdTotalPercent,

    year: currentData.year,
    yearAverage: currentData.yearAverage,
    yearCount: currentData.yearCount,
    yearTotal: currentData.yearTotal,

    ytdAverage: currentData.ytdAverage,
    ytdCount: currentData.ytdCount,
    ytdTotal: currentData.ytdTotal,
    ytdMaxValue: currentData.ytdMaxValue,
    ytdMinValue: currentData.ytdMinValue,
    ytdValues: currentData.ytdValues,

    previousYear: currentData.previousYear,
    previousYearAverage: currentData.previousYearAverage,
    previousYearCount: currentData.previousYearCount,
    previousYearTotal: currentData.previousYearTotal,

    previousYtdAverage: currentData.previousYtdAverage,
    previousYtdCount: currentData.previousYtdCount,
    previousYtdTotal: currentData.previousYtdTotal
  };
}

export const downloadCsv = (values, filter, prefix) => {
  // console.log('');
  // console.log('%c downloadCsv()', 'color:orangered');
  // console.log('%c values', 'color:orangered', values);
  // console.log('%c filter', 'color:orangered', filter);
  // console.log('%c prefix', 'color:orangered', prefix);

  const filename = formatFilename(`${prefix}_${filter === 'all' ? 'all' : filter.title}`, 'csv');
  // console.log('%c filename', 'color:orangered', filename);

  const filteredValues = filter === 'all' ? [...values ] : getFilteredValues(values, filter);
  // console.log('%c filteredValues', 'color:orangered', filteredValues);

  const transformedValues = filteredValues.map(v => ({
    amount: v.amount,
    grant_category: v.category,
    grant_date: v.at,
    purpose: v.purpose,
    is_outstanding: v.isOutstanding,
    is_returned: v.isReturned,
    recipient_address: v.recipient.address,
    recipient_city: v.recipient.city,
    recipient_country: v.recipient.country,
    recipient_name: v.recipient.name,
    recipient_poc: v.recipient.poc,
    recipient_state: v.recipient.stateAbbr || v.recipient.stateTitle,
    recipient_zip: v.recipient.zip,
  }));
  // console.log('%c transformedValues', 'color:orangered', transformedValues);

  // const columns = Object.keys(transformedValues[0]);
  const columns = ['amount', 'grant_category', 'grant_date', 'purpose', 'is_outstanding', 'is_returned', 'recipient_address', 'recipient_city', 'recipient_country', 'recipient_name', 'recipient_poc', 'recipient_state', 'recipient_zip'];
  // console.log('%c columns', 'color:orangered', columns);

  const rows = transformedValues.map(v => Object.values(v));
  // console.log('%c rows', 'color:orangered', rows);

  const builder = new CsvBuilder(filename).setColumns(columns).addRows(rows);
  // console.log('%c builder', 'color:orangered', builder);

  builder.exportFile();
};

// export const chartColors = [mainStyle.greenShade1, mainStyle.greenShade2, mainStyle.greenShade3, mainStyle.greenShade4, mainStyle.greenShade5];
export const chartColors = [mainStyle.blueShadeAlt];

export { getFilter };
