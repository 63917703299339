import React from 'react';
import { unstable_createMuiStrictModeTheme as createMuiTheme, ThemeProvider } from '@material-ui/core';
import colors from './styles/mainStyle';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.primaryColor,
      contrastText: colors.primaryColorContrast,
    },
    secondary: {
      main: colors.secondaryColor,
      contrastText: colors.secondaryColorContrast,
    },
  },
});

theme.overrides = {
  MuiButton: {
    root: {
      borderRadius: 0,
      fontSize: '16px',
      fontStyle: 'italic',
      padding: '.5em 1.5em',
    },
    textPrimary: {
      color: colors.headerTextColorForDark,
    },
    contained: {
      backgroundColor: colors.buttonBackgroundColor,
      color: colors.buttonColor,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: colors.buttonColor,
      },
    },
    containedPrimary: {
      '&:hover': {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.secondary.dark,
      },
    },
    containedSecondary: {
      backgroundColor: colors.buttonSecondaryBackgroundColor,
      color: colors.buttonSecondaryColor,
      '&:hover': {
        backgroundColor: colors.buttonSecondaryHoverBackgroundColor,
        color: colors.white,
      },
    },
  },
};

function Theme({children}) {
  // console.log('');
  // console.log('%c Theme()', 'color:white');

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
}

export default Theme;
