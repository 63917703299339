import React from 'react';
import { Box, Grid } from '@material-ui/core';
import useGetFiles from '../hooks/useGetFiles';
import Header from "../components/documents/header/header"
import Layout from '../components/layout/layout';
import FileDirectory from '../components/shared/file-directory';
import { elementStyles } from '../styles/mainStyle';


const Documents = () => {
  const [documents] = useGetFiles('DOCUMENTS_PAGE');

  return (
    <Layout>
      <Header />
      <Grid container direction="row" alignItems="stretch" style={{backgroundColor: 'white'}}>
        {
          documents ? (
            documents?.children.map((directory, index) => (
              <Grid key={index} item md={6} xs={12} style={{ ...elementStyles.column, minHeight: 'auto' }}>
                <FileDirectory name={directory.meta.path}></FileDirectory>
              </Grid>
            ))
          ) : (
            <Box minHeight={450} p={3} style={{ backgroundColor: 'white'}}>
              <em>No Data Available</em>
            </Box>
          )
        }
      </Grid>
    </Layout>
  );
};

export default Documents;
