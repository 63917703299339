import { colorSetMaps } from '../../styles/mainStyle';


export const getFilter = (filterValue, filterOptions) => {
  return filterOptions.find(v => v.value === filterValue);
};

export const getFilterOptions = (data) => {
  const result = [{
    type: 'months',
    title: 'Last 6 Months',
    value: 'months:6',
    count: 6
  }];

  const availYears = [...new Set(data.map(v => v.year))];
  availYears.sort((a, b) => b - a);

  for (const year of availYears) {
    result.push({
      type: 'year',
      title: `${year}`,
      value: `year:${year}`,
      year: year
    });
  }

  return result;
};

export const getFilteredData = (data, filter) => {
  if (['months'].includes(filter.type)) {
    return data.slice(0, filter.count);
  } else if (['year'].includes(filter.type)) {
    return data.filter(v => v.year === filter.year);
  }
};

export const getFilteredValues = (values, filter, dateProperty = 'at') => {
  // console.log('');
  // console.log('%c getFilteredValues()', 'color:magenta');
  // console.log('%c values', 'color:magenta', values);
  // console.log('%c filter', 'color:magenta', filter);

  values.sort((a, b) => b[dateProperty] - a[dateProperty]);

  if (['months'].includes(filter.type)) {

    const now = new Date();
    const rangeBegin = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 1);
    rangeBegin.setMonth(now.getMonth() - (filter.count - 1));
    // console.log('%c rangeBegin', 'color:magenta', rangeBegin);

    return values.filter(v => v[dateProperty] >= rangeBegin);
  } else if (['year'].includes(filter.type)) {
    return values.filter(v => v.year === filter.year);
  }
};

export const parseAsMonthYearSummaries = (data, type) => {
  // if (['contributions'].includes(type)) {
  //   console.log('');
  //   console.log(`%c parseAsMonthYearSummaries()[${type}]`, 'color:lime');
  //   console.log('%c data', 'color:lime', data);
  // }

  const result = createMonthYearMap(data);
  const summarizedYears = [];

  // const result = data.reduce((accumulator, item) => {
  //   return accumulator.find(v => v.year === item.year && v.month === item.month) ? accumulator : [ ...accumulator, { month: item.month, year: item.year, at: new Date(item.year, item.month, 1) } ];
  // }, []);
  // console.log('%c result:REDUCE', 'color:lime', JSON.parse(JSON.stringify(result)));

  // sum values
  for (const entry of result) {
    // current month
    const monthValues = data.filter(v => v.year === entry.year && v.month === entry.month);
    entry.total = monthValues.length ? fixedNum(monthValues.reduce((accumulator, item) => accumulator + item.amount, 0)) : 0;
    entry.count = monthValues.length;
    entry.average = monthValues.length ? fixedNum(entry.total / entry.count) : 0;
    entry.values = monthValues;

    // 6 months (including current)
    const sixMonthsDateBegin = new Date(entry.at.getFullYear(), entry.at.getMonth(), 1);
    sixMonthsDateBegin.setMonth(entry.at.getMonth() - 5);
    const sixMonthsDateEnd = new Date(entry.at.getFullYear(), entry.at.getMonth(), 1);
    sixMonthsDateEnd.setMonth(entry.at.getMonth() + 1);
    const sixMonthsValues = data.filter(v => v.at >= sixMonthsDateBegin && v.at < sixMonthsDateEnd);
    entry.sixMonthsTotal = sixMonthsValues.length ? fixedNum(sixMonthsValues.reduce((accumulator, item) => accumulator + item.amount, 0)) : 0;
    entry.sixMonthsCount = sixMonthsValues.length;
    entry.sixMonthsAverage = sixMonthsValues.length ? fixedNum(entry.sixMonthsTotal / entry.sixMonthsCount) : 0;

    // ytd
    const ytdDateEnd = new Date(entry.at.getFullYear(), entry.at.getMonth() + 1, 1);
    const ytdValues = data.filter(v => v.year === entry.year && v.at < ytdDateEnd);

    entry.ytdTotal = ytdValues.length ? fixedNum(ytdValues.reduce((accumulator, item) => accumulator + item.amount, 0)) : 0;
    entry.ytdCount = ytdValues.length;
    entry.ytdAverage = ytdValues.length ? fixedNum(entry.ytdTotal / entry.ytdCount) : 0;

    entry.ytdMaxValue = ytdValues.length ? Math.max(...ytdValues.map(v => v.amount)) : 0;
    entry.ytdMinValue = ytdValues.length ? Math.min(...ytdValues.map(v => v.amount)) : 0;
    entry.ytdValues = ytdValues;

    // current year
    let summarizedYear = summarizedYears.find(y => y.year === entry.year);
    if (!summarizedYear) {
      const yearValues = data.filter(v => v.year === entry.year);
      summarizedYear = {
        year: entry.year,
        yearTotal: fixedNum(yearValues.reduce((accumulator, item) => accumulator + item.amount, 0)),
        yearCount: yearValues.length
      };
      summarizedYear.yearAverage = summarizedYear.yearCount ? fixedNum(summarizedYear.yearTotal / summarizedYear.yearCount) : 0;
      summarizedYears.push(summarizedYear);
    }
    entry.year = summarizedYear.year;
    entry.yearTotal = summarizedYear.yearTotal;
    entry.yearCount = summarizedYear.yearCount;
    entry.yearAverage = summarizedYear.yearAverage;
  }
  // console.log('%c summarizedYears', 'color:lime', summarizedYears);
  // console.log('%c result:SUM', 'color:lime', JSON.parse(JSON.stringify(result)));

  // calculate offset values
  for (const [index, entry] of result.entries()) {
    const targetDate = new Date(entry.year, entry.month, 1);

    targetDate.setMonth(targetDate.getMonth() - 1);
    const previousByMonth = result.find(v => v.year === targetDate.getFullYear() && v.month === targetDate.getMonth());

    if (previousByMonth) {
      // totals
      entry.changeOverMonthTotal = fixedNum(entry.total - previousByMonth.total);
      entry.changeOverMonthTotalPercent = previousByMonth.total === 0 ? 0 : fixedNum((Math.abs(entry.changeOverMonthTotal) / previousByMonth.total) * 100);
      if (Math.sign(entry.changeOverMonthTotal) === -1) entry.changeOverMonthTotalPercent = -entry.changeOverMonthTotalPercent;
      // counts
      entry.changeOverMonthCount = fixedNum(entry.count - previousByMonth.count);
      entry.changeOverMonthCountPercent = previousByMonth.count === 0 ? 0 : fixedNum((Math.abs(entry.changeOverMonthCount) / previousByMonth.count) * 100);
      if (Math.sign(entry.changeOverMonthCount) === -1) entry.changeOverMonthCountPercent = -entry.changeOverMonthCountPercent;
    }

    if (index === 0) {
      targetDate.setMonth(targetDate.getMonth() - 5);
      const previousBySixMonths = result.find(v => v.year === targetDate.getFullYear() && v.month === targetDate.getMonth());
      // console.log('%c entry', 'color:red', entry);
      // console.log('%c previousBySixMonths', 'color:red', previousBySixMonths);
      if (previousBySixMonths) {
        // totals
        entry.changeOverSixMonthsTotal = fixedNum(entry.sixMonthsTotal - previousBySixMonths.sixMonthsTotal);
        entry.changeOverSixMonthsTotalPercent = previousBySixMonths.sixMonthsTotal === 0 ? 0 : fixedNum((Math.abs(entry.changeOverSixMonthsTotal) / previousBySixMonths.sixMonthsTotal) * 100);
        if (Math.sign(entry.changeOverSixMonthsTotal) === -1) entry.changeOverSixMonthsTotalPercent = -entry.changeOverSixMonthsTotalPercent;
        // counts
        entry.changeOverSixMonthsCount = fixedNum(entry.sixMonthsCount - previousBySixMonths.sixMonthsCount);
        entry.changeOverSixMonthsCountPercent = previousBySixMonths.sixMonthsCount === 0 ? 0 : fixedNum((Math.abs(entry.changeOverSixMonthsCount) / previousBySixMonths.sixMonthsCount) * 100);
        if (Math.sign(entry.changeOverSixMonthsCount) === -1) entry.changeOverSixMonthsCountPercent = -entry.changeOverSixMonthsCountPercent;
      }
    }

    const previousByYear = result.find(v => v.year === (entry.year - 1) && v.month === entry.month);

    // if (['contributions'].includes(type)) {
    //   console.log('');
    //   console.log('%c entry.at', 'color:lime', entry.at);
    //   console.log('%c entry', 'color:lime', entry);
    //   console.log('%c previousByYear', 'color:lime', previousByYear);
    // }

    if (previousByYear) {
      entry.previousYear = previousByYear.year;
      entry.previousYearTotal = previousByYear.yearTotal;
      entry.previousYearCount = previousByYear.yearCount;
      entry.previousYearAverage = previousByYear.yearAverage;
      entry.previousYtdTotal = previousByYear.ytdTotal;
      entry.previousYtdCount = previousByYear.ytdCount;
      entry.previousYtdAverage = previousByYear.ytdAverage;

      // totals
      entry.changeOverYearTotal = fixedNum(entry.yearTotal - previousByYear.yearTotal);
      entry.changeOverYearTotalPercent = previousByYear.yearTotal === 0 ? 0 : fixedNum((Math.abs(entry.changeOverYearTotal) / previousByYear.yearTotal) * 100);
      if (Math.sign(entry.changeOverYearTotal) === -1) entry.changeOverYearTotalPercent = -entry.changeOverYearTotalPercent;
      // ytd:totals
      entry.changeOverYtdTotal = fixedNum(entry.ytdTotal - previousByYear.ytdTotal);
      entry.changeOverYtdTotalPercent = previousByYear.ytdTotal === 0 ? 0 : fixedNum((Math.abs(entry.changeOverYtdTotal) / previousByYear.ytdTotal) * 100);
      if (Math.sign(entry.changeOverYtdTotal) === -1) entry.changeOverYtdTotalPercent = -entry.changeOverYtdTotalPercent;
      // counts
      entry.changeOverYearCount = fixedNum(entry.yearCount - previousByYear.yearCount);
      entry.changeOverYearCountPercent = previousByYear.yearCount === 0 ? 0 : fixedNum((Math.abs(entry.changeOverYearCount) / previousByYear.yearCount) * 100);
      if (Math.sign(entry.changeOverYearCount) === -1) entry.changeOverYearCountPercent = -entry.changeOverYearCountPercent;
      // ytd:counts
      entry.changeOverYtdCount = fixedNum(entry.ytdCount - previousByYear.ytdCount);
      entry.changeOverYtdCountPercent = previousByYear.ytdCount === 0 ? 0 : fixedNum((Math.abs(entry.changeOverYtdCount) / previousByYear.ytdCount) * 100);
      if (Math.sign(entry.changeOverYtdCount) === -1) entry.changeOverYtdCountPercent = -entry.changeOverYtdCountPercent;
    } else {
      const lastEntry = result[result.length - 1];
      const withinLastYear = (entry.at.getTime() - lastEntry.at.getTime()) < 31536000000;

      if (withinLastYear) {
        // totals
        entry.changeOverYearTotal = entry.yearTotal;
        entry.changeOverYearTotalPercent = 100;
        entry.changeOverYtdTotal = entry.ytdTotal;
        entry.changeOverYtdTotalPercent = 100;
        // counts
        entry.changeOverYearCount = entry.yearCount;
        entry.changeOverYearCountPercent = 100;
        entry.changeOverYtdCount = entry.ytdCount;
        entry.changeOverYtdCountPercent = 100;
      }
    }
  }
  // console.log('%c result:OFFSET', 'color:lime', JSON.parse(JSON.stringify(result)));

  result.sort((a, b) => b.month - a.month).sort((a, b) => b.year - a.year);
  // console.log('%c result', 'color:lime', result);

  return result;
};

export const createMonthYearMap = (data, useDataForMax = false) => {
  const result = [];

  const maxYear = useDataForMax ? Math.max(...data.map(v => v.year)) : new Date().getFullYear();
  const maxMonth = useDataForMax ? Math.max(...data.filter(v => v.year === maxYear).map(v => v.month)) : new Date().getMonth();
  const minYear = Math.min(...data.map(v => v.year));

  const indexDate = new Date(maxYear, maxMonth, 1);
  while (indexDate.getFullYear() >= minYear) {
    result.push({
      month: indexDate.getMonth(),
      year: indexDate.getFullYear(),
      at: new Date(indexDate.getFullYear(), indexDate.getMonth(), 1)
    });
    indexDate.setMonth(indexDate.getMonth() - 1);
  }

  return result;
};

export const getBuckets = (data, property) => {
  // console.log('');
  // console.log('%c getBuckets()', 'color:deeppink');
  // console.log('%c data', 'color:deeppink', data);
  // console.log('%c property', 'color:deeppink', property);

  const seedValues = data?.length ? data.filter(v => v[property] > 0).map(v => v[property]) : [1, 4, 7, 10];

  const values = [ ...new Set(seedValues) ];
  values.sort((a, b) => a - b);

  const buckets = [{ begin: 0, end: 0, color: colorSetMaps[0] }];
  const ranges = Math.min(values.length, 4);
  const groupSize = values.length / ranges;

  // console.log('%c values', 'color:deeppink', values);
  // console.log('%c ranges', 'color:deeppink', ranges);
  // console.log('%c groupSize', 'color:deeppink', groupSize);

  for (let index = 0; index < ranges; index++) {
    const valueSlice = values.slice(index * groupSize, (index + 1) * groupSize);
    // console.log('%c valueSlice', 'color:deeppink', valueSlice);
    buckets.push({
      begin: index === 0 ? 1 : valueSlice[0],
      color: colorSetMaps[(index + 1) % colorSetMaps.length]
    });
  }

  for (let index = 0; index < buckets.length; index++) {
    const bucket = buckets[index];
    const nextBucket = buckets[index + 1];
    bucket.end = nextBucket ? nextBucket.begin - 1 : values[values.length - 1];
    // bucket.values = data.filter(v => v[property] >= bucket.begin && v[property] <= bucket.end);
  }
  // console.log('%c buckets', 'color:deeppink', buckets);

  return buckets;
}

export const fixedNum = (number, decimals = 2) => parseFloat((number).toFixed(decimals));
