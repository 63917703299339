import { Auth0Provider } from "@auth0/auth0-react";
import React from 'react';
import Routes from './router';
import { Provider } from 'react-redux';
import store from './store';
import Theme from './theme';
import './styles/index.scss'
import { AuthProvider } from './auth';


const ENV_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;
const ENV_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
const ENV_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const ENV_REDIRECT_URI = process.env.REACT_APP_AUTH0_REDIRECT_URI;

function App() {
  return (
    <Auth0Provider
      domain={ENV_DOMAIN}
      clientId={ENV_CLIENT_ID}
      redirectUri={ENV_REDIRECT_URI}
      audience={ENV_AUDIENCE}>
      <Provider store={store}>
        <AuthProvider audience={ENV_AUDIENCE}>
          <Theme>
            <Routes />
          </Theme>
        </AuthProvider>
      </Provider>
    </Auth0Provider>
  );
}

export default App;
