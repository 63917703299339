import React from 'react';
import HeaderWrapper from '../../shared/header-wrapper';
import TriplePointForm from '../../shared/triple-point-form';
import { portalForms } from '../../../utils/constants';
import RoundButton from '../../shared/round-button';


const data = [
  {
    title: 'Need More Info?',
    component: (
      <TriplePointForm title={portalForms.contactUs.title} url={portalForms.contactUs.url}>
        <RoundButton color="primary">{portalForms.contactUs.title}</RoundButton>
      </TriplePointForm>
    )
  }
];

function Header({ title = 'Policies' }) {
  return <HeaderWrapper title={title} noDate data={data} showAccount={false} />;
}

export default Header;
