export const appTypes = {
  APP_LOADING: 'APP_LOADING',
  APP_LOADED: 'APP_LOADED',
  APP_ERROR: 'APP_ERROR',
};

export const setAppLoading = () => ({
  type: appTypes.APP_LOADING,
});

export const setAppLoaded = () => ({
  type: appTypes.APP_LOADED,
});
