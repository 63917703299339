import React, { Fragment, useState } from 'react';
import { ComposableMap, Geographies, Geography, } from 'react-simple-maps';
import { Box } from './box';
import mainStyle from '../../styles/mainStyle';
import ReactTooltip from 'react-tooltip';
import states10m from './us-map-states-10m';


const styles = {
  legend: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px'
  },
  space: {
    marginLeft: '15px'
  }
};

const assignData = (data, buckets) => {
  const result = {};
  for (const state of data) {
    const count = state.count;
    const fill = buckets.find(v => v.begin <= count && v.end >= count)?.color;
    result[state.stateTitle] = {
      count,
      fill
    }
  }
  return result;
};

const UsMap = ({ data, buckets }) => {
  // console.log('');
  // console.log('%c share/UsMap', 'color:gold');
  // console.log('%c data', 'color:gold', data);
  // console.log('%c buckets', 'color:gold', buckets);

  const stateData = assignData(data, buckets);
  // console.log('%c stateData', 'color:gold', stateData);

  const [content, setTooltipContent] = useState('');

  return (
    <Fragment>
      <ComposableMap data-tip="" projection="geoAlbersUsa">
        <Geographies geography={states10m}>
          {({ geographies }) => (
            <Fragment>
              {geographies.map((geo, idx) => {
              const { name } = geo.properties;
                return (
                  <Geography
                    key={geo.rsmKey}
                    stroke={mainStyle.white}
                    fill={stateData[name]?.fill || mainStyle.mapDefaultGray}
                    geography={geo}
                    onMouseEnter={() => {
                      setTooltipContent(`${name}: ${stateData[name]?.count || 0}`);
                    }}
                    onMouseLeave={() => {
                      setTooltipContent('');
                    }}
                  />
                );
              })}
            </Fragment>
          )}
        </Geographies>
      </ComposableMap>
      <ReactTooltip backgroundColor={mainStyle.blueShade2} arrowColor="transparent">{content}</ReactTooltip>
    </Fragment>
  );
};

export const Map = React.memo(UsMap);

const MapChart = ({ data, buckets, type = 'TYPE' }) => {
  // console.log('');
  // console.log('%c share/MapChart', 'color:yellow');
  // console.log('%c data', 'color:yellow', data);
  // console.log('%c buckets', 'color:yellow', buckets);
  // console.log('%c type', 'color:yellow', type);

  return (
    <div className="flex-c-ac">
      <div style={{ width: '100%', maxWidth: '775px' }}>
        <Map data={data} buckets={buckets} />
      </div>
      <div>
        <div style={{ marginBottom: '20px' }}>
          <b>Number of {type}</b>
        </div>
        {buckets.map((bucket, index) => (
          <div key={index} style={styles.legend}>
            <Box color={bucket.color} />
            <span style={styles.space}>
              {
                index === 0 ? (
                  <em>None</em>
                ) : index === buckets.length - 1 ? (
                  <Fragment>
                    <strong>{bucket.begin} </strong><em> or more</em>
                  </Fragment>
                ) : (
                  <strong>
                    {bucket.begin === bucket.end ? `${bucket.begin}` : `${bucket.begin} - ${bucket.end}`}
                  </strong>
                )
              }
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(MapChart);
