import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { initialLoad } from './redux/actions/initial-load';
import { clearData } from './redux/actions/clear-data';
import Views from './views';
import Four04 from "./views/four04"
import { PrivateRoute, useAuth } from './auth';


const Routes = () => {
  // console.log('');
  // console.log('%c Routes()', 'color:lime');
  // console.log('%c Views', 'color:lime', Views);

  const { clientId, isAuthenticated, isReady } = useAuth();

  const dispatch = useDispatch();
  const hasData = useSelector(state => !!state.assets.data && !!state.client.data && !!state.contributions.data && !!state.disbursements.data && !!state.files.data);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  useEffect(() => {
    // Router is not respecting the value change for shouldRefresh/forceRefresh
    // ...if you start with false, then update to true, it does not reload page (it ignores new value for shouldRefresh)
    // ...if you start with true, then update to false, it does reload page (it ignores new value for shouldRefresh)
    // ...I think this is an issue with the hot module reloading update + react router
    // console.log('%c Router/INITIAL', 'color:orangered');
    const timeoutId = setTimeout(
      () => setShouldRefresh(true), 1000 * 60 * 60 * 6
    );
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    // console.log('');
    // console.log('%c Routes():useEffect()', 'color:lime', '[clientId, dispatch, hasData, isAuthenticated, isReady]');
    // console.log('%c clientId', 'color:lime', clientId);
    // console.log('%c hasData', 'color:lime', hasData);
    // console.log('%c isAuthenticated', 'color:lime', isAuthenticated);
    // console.log('%c isReady', 'color:lime', isReady);

    if (hasData && isAuthenticated && !isReady) dispatch(clearData());
    if (!hasData && isAuthenticated && isReady) dispatch(initialLoad());

  }, [clientId, dispatch, hasData, isAuthenticated, isReady]);


  return (
    <Router forceRefresh={shouldRefresh}>
      {/* <Provider> */}
        <Switch>
          <Route path="/login" render={Views.Login} />
          <Route path="/logout" render={Views.Login} />
          <PrivateRoute exact path="/" component={Views.Summary} />
          <PrivateRoute exact path="/asset-value" component={Views.Assets} />
          <PrivateRoute path="/contact-us" component={Views.ContactForm} />
          <PrivateRoute path="/contributions" component={Views.Contributions} />
          <PrivateRoute path="/grants" component={Views.Disbursements} />
          <PrivateRoute path="/documents" component={Views.Documents} />
          <PrivateRoute path="/disclosures" component={Views.Disclosures} />
          <PrivateRoute path="/forms" component={Views.Forms} />
          <PrivateRoute path="/grant-distribution-form" component={Views.GrantDistributionForm} />
          <PrivateRoute path="/privacy" component={Views.Privacy} />
          <PrivateRoute path="/users" component={Views.Users} />
          <PrivateRoute path="/terms-of-use" component={Views.TermsOfUse} />
          <Route component={Four04} />
        </Switch>
      {/* </Provider> */}
    </Router>
  );
};

// const DashboardMock = () => {
//   const { logout } = useAuth0();
//   const { user } = useAuth(); // accessToken

//   // console.log('');
//   // console.log('%c DashboardMock()', 'color:fuchsia');
//   // console.log('%c accessToken', 'color:fuchsia', accessToken);
//   // console.log('%c clientId', 'color:fuchsia', clientId);
//   // console.log('%c user', 'color:fuchsia', user);

//   // useEffect(() => {
//   //   console.log('');
//   //   console.log('%c DashboardMock().useEffect()', 'color:white', '[accessToken]');
//   //   console.log('%c accessToken', 'color:white', accessToken);
//   // }, [accessToken]);

//   return (

//     <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" bgcolor="white" m={4} p={4}>
//       <strong style={{ marginLeft: '1rem' }}>PRIVATE_ROUTE: DASHBOARD_MOCK</strong>
//       <hr width="100%" />
//       <Box>
//         <img src={user.picture} alt={user.name} />
//         <h2>user: {user.name}</h2>
//         <p>email: {user.email}</p>
//         {/* <pre style={{ maxWidth: '60%' }}>token: {accessToken}</pre> */}
//         <Button color="primary" variant="outlined" onClick={() => logout({ returnTo: 'http://localhost:3000/login' })}>Log Out</Button>
//       </Box>
//     </Box>
//  );
// };

export default Routes;
