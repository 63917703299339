import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthLoading } from './AuthLoading';
import { useAuth } from './AuthContext';


export const PrivateRoute = ({ component, ...rest }) => {
  const { isAuthenticated, isReady } = useAuth();

  // console.log('');
  // console.log('%c PrivateRoute()', 'color:fuchsia');
  // console.log('%c clientId', 'color:fuchsia', clientId);
  // console.log('%c isAuthenticated', 'color:fuchsia', isAuthenticated);
  // console.log('%c isReady', 'color:fuchsia', isReady);

  if (!isReady) return (
    <AuthLoading />
  );

  if (!isAuthenticated) return (
    <Redirect to="/login" />
  );

  return (
    <Route component={component} {...rest} />
  );
};



