import { appTypes } from '../actions/app-actions';

const initialState = {
  message: '',
  loading: true,
};

const AppReducer = (state = initialState, action) => {
  switch (action.type) {

    case appTypes.APP_LOADING:
      return {
        ...state,
        loading: true,
      };

    case appTypes.APP_LOADED:
      return {
        ...state,
        loading: false,
      };

    case appTypes.APP_ERROR:
      return {
        ...state,
        message: action.message,
        loading: false
      };

    default:
      return state;
  }
}

export default AppReducer;
