export const portalForms = {
  contactUs: { title: 'Contact Us', url: 'https://www.sterlingfoundations.com/portal/contact-us' },
  grantDisbursementRequest: { title: 'Grant Disbursement Request', url: 'https://www.sterlingfoundations.com/portal/grant-distribution-form', route: '/grant-distribution-form' },
  passwordAssistance: { title: 'Password Assistance', url: 'https://www.sterlingfoundations.com/portal/password-assistance' },
  sendFileResult: {
    title: 'Send File Result',
    url: 'https://www.sterlingfoundations.com/portal/send-file',
    formId: '0D7C2540-C815-4F27-A7FD-953C9F393683',
    formInstanceId: 'F155ADB8-57C6-4F00-8564-AFBDC9A9FAC4',
    fields: {
      files: 'field_17714620-9D4D-44B2-BD75-5E45CEB7AED6'
    }
  }
};

export const shortMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const states = [
  { id: 'AL', name: 'Alabama', val: '01' },
  { id: 'AK', name: 'Alaska', val: '02' },
  { id: 'AZ', name: 'Arizona', val: '04' },
  { id: 'AR', name: 'Arkansas', val: '05' },
  { id: 'CA', name: 'California', val: '06' },
  { id: 'CO', name: 'Colorado', val: '08' },
  { id: 'CT', name: 'Connecticut', val: '09' },
  { id: 'DE', name: 'Delaware', val: '10' },
  { id: 'DC', name: 'District of Columbia', val: '11' },
  { id: 'FL', name: 'Florida', val: '12' },
  { id: 'GA', name: 'Georgia', val: '13' },
  { id: 'HI', name: 'Hawaii', val: '15' },
  { id: 'ID', name: 'Idaho', val: '16' },
  { id: 'IL', name: 'Illinois', val: '17' },
  { id: 'IN', name: 'Indiana', val: '18' },
  { id: 'IA', name: 'Iowa', val: '19' },
  { id: 'KS', name: 'Kansas', val: '20' },
  { id: 'KY', name: 'Kentucky', val: '21' },
  { id: 'LA', name: 'Louisiana', val: '22' },
  { id: 'ME', name: 'Maine', val: '23' },
  { id: 'MD', name: 'Maryland', val: '24' },
  { id: 'MA', name: 'Massachusetts', val: '25' },
  { id: 'MI', name: 'Michigan', val: '26' },
  { id: 'MN', name: 'Minnesota', val: '27' },
  { id: 'MS', name: 'Mississippi', val: '28' },
  { id: 'MO', name: 'Missouri', val: '29' },
  { id: 'MT', name: 'Montana', val: '30' },
  { id: 'NE', name: 'Nebraska', val: '31' },
  { id: 'NV', name: 'Nevada', val: '32' },
  { id: 'NH', name: 'New Hampshire', val: '33' },
  { id: 'NJ', name: 'New Jersey', val: '34' },
  { id: 'NM', name: 'New Mexico', val: '35' },
  { id: 'NY', name: 'New York', val: '36' },
  { id: 'NC', name: 'North Carolina', val: '37' },
  { id: 'ND', name: 'North Dakota', val: '38' },
  { id: 'OH', name: 'Ohio', val: '39' },
  { id: 'OK', name: 'Oklahoma', val: '40' },
  { id: 'OR', name: 'Oregon', val: '41' },
  { id: 'PA', name: 'Pennsylvania', val: '42' },
  { id: 'RI', name: 'Rhode Island', val: '44' },
  { id: 'SC', name: 'South Carolina', val: '45' },
  { id: 'SD', name: 'South Dakota', val: '46' },
  { id: 'TN', name: 'Tennessee', val: '47' },
  { id: 'TX', name: 'Texas', val: '48' },
  { id: 'UT', name: 'Utah', val: '49' },
  { id: 'VT', name: 'Vermont', val: '50' },
  { id: 'VA', name: 'Virginia', val: '51' },
  { id: 'WA', name: 'Washington', val: '53' },
  { id: 'WV', name: 'West Virginia', val: '54' },
  { id: 'WI', name: 'Wisconsin', val: '55' },
  { id: 'WY', name: 'Wyoming', val: '56' }
];

export const stateAbbrs = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY'
];
