import React from 'react';
import { Divider } from '@material-ui/core';
import { elementStyles } from '../../styles/mainStyle';

export default function SideInfoWrapper(props) {

  const { title, dataTitle, data, downloadButton, noPadding = false } = props;

  const styles = {
    buttonContainer: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'stretch',
      margin: '0 -10px -20px'
    }
  }

  const dataStyles = {
    flexGrow: 1,
    padding: noPadding ? '0px' : '35px 0px'
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
      {title && (
        <div>
          <h3
            className="blackish f-700 rg-text"
            style={{ margin: '0px' }}>
            {title}
          </h3>
        </div>
      )}
      {title && (
        <div>
          <Divider
            style={elementStyles.divider} />
        </div>
      )}
      {dataTitle && (
        <div className="rg-text">
          {dataTitle}
        </div>
      )}
      <div style={dataStyles}>
        {data || props.children}
      </div>
      {downloadButton && (
        <div style={styles.buttonContainer}>
          {downloadButton}
        </div>
      )}
    </div>
  );
}
