import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core';
import ForwardIcon from '@material-ui/icons/PlayArrow';
import HeaderWrapper from '../../shared/header-wrapper';
import RoundButton from '../../shared/round-button';
import TriplePointForm from '../../shared/triple-point-form';
import { portalForms } from '../../../utils/constants';
import { useSelector } from 'react-redux';


const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    fontStyle: 'italic',
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '1.2em',
    padding: '.25rem .5rem',
    textAlign: 'left',
    textTransform: 'none',
    textDecoration: 'none'
  },
});

function Header() {
  // console.log('');
  // console.log('%c formsHeader()', 'color:cyan');

  const classes = useStyles();
  const files = useSelector((state) => state.files.data);
  const headerFiles = files?.disbursementHeaderFiles || [];

  const data = [
    {
      title: 'Check Form Status',
      component: (
        <TriplePointForm title={portalForms.contactUs.title} url={portalForms.contactUs.url}>
          <RoundButton color="primary">{portalForms.contactUs.title}</RoundButton>
        </TriplePointForm>
      ),
    },
  ];

  if (headerFiles.length) {
    data.push({
      title: 'Web Forms',
      component: (
        <Fragment>
          {
            headerFiles.map((file, index) => (
              file.isWebForm ? (
                <TriplePointForm key={index} title={file.title} url={file.url}>
                  {/* eslint-disable-next-line */}
                  <a className={classes.root} style={{ cursor: 'pointer' }}>
                    <ForwardIcon style={{ fill: 'white', height: '1rem', marginRight: '.5rem', width: '1rem', }} />
                    {file.title}
                  </a>
                </TriplePointForm>
              ) : (
                <a key={index} href={file.meta.uri} className={classes.root} target="_blank" rel="noreferrer noopener">
                  <ForwardIcon style={{ fill: 'white', height: '1rem', marginRight: '.5rem', width: '1rem', }} />
                  {file.title}
                </a>
              )
            ))
          }
        </Fragment>
      ),
    });
  }

  return <HeaderWrapper title="Forms" noDate data={data} />;
}

export default Header;
