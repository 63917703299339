import React from "react";
export const Box = ({ color, size, ...rest }) => {
  return (
    <div
      style={{
        width: size || "13px",
        height: size || "13px",
        background: color,
        border: "1px solid black",
      }}
      {...rest}
    ></div>
  );
};
