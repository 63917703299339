import qs from 'qs';
import { tokenStorage } from '../auth';

const url = process.env.REACT_APP_API_URL || 'https://portal-api.sterlingfoundations.com/api/';

export const getConfig = () => {
  const token = tokenStorage.getToken();

  return {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      authorization: `Bearer ${token ?? ''}`
    }
  };
};

export const getUrl = (path, params) => {
  let returnUrl = url + path;
  let delimiter = path.includes('?') ? '&' : '?';

  const client_id = localStorage.getItem('clientId');

  if (client_id !== null) {
    returnUrl += `${delimiter}client_id=${client_id}`;
    delimiter = '&';
  }

  if (params) {
    for (const key in params) {
      returnUrl += delimiter + key + '=' + params[key];
      delimiter = '&';
    }
  }

  return returnUrl;
};

export const getFileUrl = (file_id) => {
  const token = tokenStorage.getToken();
  return getUrl(`files/${file_id}/download?token=${token ?? ''}`);
};

export const getReturnData = (xmlData) =>
  new Promise((res, rej) => {
    var returnData = {};
    if (!xmlData || !xmlData.length) {
      rej('No xml data');
    }
    xmlData.forEach((data, idx) => {
      if (data._) {
        returnData[data.$.name] = data._;
      }
      if (idx === xmlData.length - 1) {
        res(returnData);
      }
    });
  });

export const loginConfig = () => {
  return {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  };
};

export const getBody = (body) => {
  return qs.stringify(body);
}


// export const err = (type) => {
//   console.log('error', error);
//   dispatch({
//     type: type,
//     message: 'error', // error.response.data.message,
//     status: 401 //error.response.status,
//   });
// };
