import React, { Fragment } from 'react';
import { VictoryLabel, VictoryPie, VictoryTooltip } from 'victory';
import SummaryChartInfo from '../../summary/charts/summary-chart-info';
import SideInfoWrapper from '../../shared/side-info-wrapper';
import colors, { colorSetBlues } from '../../../styles/mainStyle';
import { fixedNum } from '../../../redux/services/service-helpers'
import { formatMoney } from '../../../utils/misc';

const CircleColor = ({ color }) => {
  return (
    <div
      style={{
        height: '1em',
        width: '1em',
        borderRadius: '50%',
        backgroundColor: color
      }}></div>
  );
};

const LegendItem = ({ color, title, amount, percent }) => {
  return (
    <li className="flex-ac" style={{ padding: '.25em 0px' }}>
      <span><CircleColor color={color} /></span>
      <span style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1 }}>
        <strong style={{ padding: '0px .5em'}}>{title}:</strong> <em>{formatMoney(amount, 0)}</em>
      </span>
    </li>
  );
};

const Legend = ({ categories }) => {
  return (
    <ul style={{ fontSize: '15px', listStyle: 'none', margin: '0 auto', padding: 0 }}>
      {categories.map((category, index) => (
        <LegendItem
          color={colorSetBlues[index % colorSetBlues.length]}
          title={category.name}
          amount={category.total}
          percent={category.percent}
          key={index} />
      ))}
    </ul>
  );
};

const CustomLabel = (props) => {
  return (
    <g>
      <VictoryLabel {...props}
        text={({ datum }) => fixedNum(datum.y, 0) + '%'} />
      <VictoryTooltip
        {...props}
        style={{ fill: colors.white, fontSize: 16 }}
        flyoutStyle={{ fill: colors.blueShade2, stroke: colors.blueShade2 }}
        flyoutPadding={15}
      />
    </g>
  );
}
CustomLabel.defaultEvents = VictoryTooltip.defaultEvents;

const CategoryPercentagesChart = ({categories, title = `Grants by Categories`}) => {
  // console.log('');
  // console.log('%c disbursements/category-percentages', 'color:orange');
  // console.log('%c categories', 'color:orange', categories);

  const data = categories.map((value, index) => ({
    x: index + 1,
    y: value.percent,
    label: `${value.name} ${fixedNum(value.percent, 0)}%`,
    percent: value.percent,
    title: value.name,
    total: value.total,
  }));

  const dataItems = (
    <div className="flex-col center">
      <SummaryChartInfo
        title={title}
        minHeight={false}
        paddingBottom={false}
        paddingTop={false} />
      {categories?.length ? (
        <Fragment>
          <VictoryPie
            colorScale={colorSetBlues}
            data={data}
            labelRadius={110}
            labelComponent={<CustomLabel />}
            labels={({ datum }) => fixedNum(datum.y, 0) + '%'}
            height={300}
            width={350}
          />
          <Legend categories={categories} />
        </Fragment>
      ) : (
        <span style={{padding: '7.5rem 1.5em', textAlign: 'center'}}>No results to display for the current range.</span>
      )}
    </div>
  );

  return (
    <SideInfoWrapper
      data={dataItems}
      noPadding />
  );
};

export default CategoryPercentagesChart;
