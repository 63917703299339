export const tokenStorage = {
  prefix: '',
  getToken: () => {
    const storageToken = window.localStorage.getItem(`${tokenStorage.prefix}token`);
    return storageToken && JSON.parse(storageToken);
  },
  setToken: (token) => {
    window.localStorage.setItem(`${tokenStorage.prefix}token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`${tokenStorage.prefix}token`);
  },
};
